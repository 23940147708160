var defaultAddress = null;
let address = JSON.parse(localStorage.getItem('defaultAddress'));

var defaultAddressMixin = {

	created() {
		//getLocalStorage();
	},

	methods: {
		setDefaultAddressMixin(newDefaultAddress) {
			defaultAddress = newDefaultAddress;
			setLocalStorage();
			this.$store.commit('defaultAddress/setDefaultAddress', defaultAddress);
		},

		setNewDefaultAddressMixin(newDefaultAddress) {
			defaultAddress = newDefaultAddress;
			this.$store.commit('defaultAddress/setDefaultAddress', defaultAddress);
		},

		getLocalStorageDefaultAddressMixin() {
			if(address){
				defaultAddress = JSON.parse(localStorage.getItem('defaultAddress'));
				return defaultAddress;
			}
		},

		initDefaultAddressMixin() {
			address = JSON.parse(localStorage.getItem('defaultAddress'));
			this.$store.commit('defaultAddress/setDefaultAddress', address);
		},

		// Obtener de forma correcta
		getDefaultAddressMixin() {
			if (this.getLocalStorageDefaultAddressMixin()){
				return this.getLocalStorageDefaultAddressMixin();
			}
			else {
				return defaultAddress;
			}
		},
		/* to call apis Carta-Listing */
		getUbicationMixin(){
			let defaultAddress = this.getDefaultAddressMixin();
			return{
				'lat': defaultAddress.lat,
				'lng': defaultAddress.lng
			};
		},

		resetDefaultAddressMixin() {
			defaultAddress = null;
			address = null;
		}

	}
};

function setLocalStorage() {
	localStorage.setItem('defaultAddress', JSON.stringify(defaultAddress));
}

export default defaultAddressMixin;