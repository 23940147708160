<template>
  <div id="app">
    <!--Main Wrapper-->
    <div class="wrapper">
      <!-- Header Start -->
      <Header @selectCity="optionChange"></Header>
      <!-- Header End -->

      <!-- Views -->
      <router-view :key="keyAddress" />
      <!-- End Views -->

      <!-- Footer Start -->
      <Footer></Footer>
      <!-- Footer End -->
    </div>

    <ModalLogin></ModalLogin>

    <ModalRegisterUser></ModalRegisterUser>

    <!-- Modal Add Delivery Address  -->
    <ModalAddDeliveryAddress></ModalAddDeliveryAddress>
    <!-- Modal Add Delivery Address End  -->

    <ModalShoppingCart></ModalShoppingCart>

    <modal-address></modal-address>

    <ModalProfile></ModalProfile>

    <div v-if="Object.keys(defaultAddress).length > 0">

      <ModalPendingOrders></ModalPendingOrders>

      <ModalHistoricalOrders></ModalHistoricalOrders>

      <ModalStarRating></ModalStarRating>

      <ModalHelp></ModalHelp>
    </div>

  </div>
</template>

<style>
.pac-container {
  color: #332927;
  z-index: 10050 !important;
  min-width: 300px;
  max-width: 700px;
  max-height: 300px;
  background-color: #fff;
  border-radius: 15px;
  border-top: none;
  box-shadow: none;
  position: fixed;
  display: inline-block;
  float: left;
  border: 2px solid #efece9 !important;
}
.pac-container:after {
  content:none !important;
}
.pac-item {
  cursor: pointer;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: row;
  padding: 10px;
  border-bottom: 1px solid #e5edef;
}
.pac-item:hover{
  background-color: rgba(230, 236, 240, 0.9) !important;
}

.swal2-popup {
  font-size: 1.5rem !important;
}

body::-webkit-scrollbar {
  width: 10px;
}
body::-webkit-scrollbar-thumb {
  background: #FE2526;
  border-radius: 10px;
}
body::-webkit-scrollbar-track {
  border-left: 2px solid rgba(230, 236, 240, 0.9);
  background-color: rgb(250, 250, 248);;
}
body::-webkit-scrollbar-corner {
  background-color: #fff;
}

</style>

<script>
import moment from 'moment';
moment.suppressDeprecationWarnings = true;
moment.locale('es');

import { mapState } from 'vuex';
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import ModalLogin from "@/components/modals/ModalLogin.vue";
import ModalRegisterUser from "@/components/modals/ModalRegisterUser.vue";
import ModalAddress from "@/components/modals/ModalAddress.vue";
import ModalShoppingCart from "@/components/modals/ModalShoppingCart.vue";
import ModalPendingOrders from "@/components/modals/ModalPendingOrders.vue";
import ModalHistoricalOrders from "@/components/modals/ModalHistoricalOrders.vue";
import ModalAddDeliveryAddress from "@/components/modals/ModalAddDeliveryAddress.vue";
import ModalStarRating from "@/components/modals/ModalStarRating.vue";
import ModalHelp from "@/components/modals/ModalHelp.vue";
import ModalProfile from "@/components/modals/ModalProfile.vue";


export default {
  components : { Header, Footer, ModalLogin, ModalRegisterUser, ModalAddress, ModalShoppingCart, ModalPendingOrders, ModalHistoricalOrders, ModalAddDeliveryAddress, ModalStarRating, ModalHelp, ModalProfile },

  data() {
    return {
      SelectOption: true,
      url_path: 'http://localhost:8080/'
    }
  },

  created() {
    this.clearCache();
  },

  computed: mapState({
    defaultAddress: state => state.defaultAddress.defaultAddress,
    keyAddress: state => state.defaultAddress.keyAddress,
  }),

  methods: {
    optionChange(){
      this.SelectOption = !this.SelectOption
    },

    clearCache() {
      const date = new Date();
      console.log('date', moment(date).format('dddd'))
      let cache = localStorage.getItem('cache');
      console.log('cache', cache);
      if(moment(date).format('dddd') == 'lunes' && cache == 1) {
        localStorage.setItem('cache', 0);
        window.location.reload(true);
      }
      else if (moment(date).format('dddd') != 'lunes'){
        localStorage.setItem('cache', 1);
      }
    }

  }
}
</script>

<style>
*{
  font-family: 'Poppins', sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Poppins", sans-serif !important;
}
</style>