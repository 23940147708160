var inputStyleMixin = {

	methods: {
		hasTextOnInputMixin(text) {
			if (text != '') {
				return true;
			}
			else {
				return false;
			}
		}
	}
};

export default inputStyleMixin;