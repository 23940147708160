<template>
  <header id="header">
    <div class="main-header">
      <div class="container">
        <div class="row">
          <div v-if="isAuthenticated" class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="position: relative;">
            <div class="logo logo-img">
              <figure>
                <router-link class="light-logo" :to="{ name: 'Home' }">
                  <img
                  :src="require('@/assets/images/logo z rojo.png')"
                  alt="FoodBakery" width="100%"
                  />
                </router-link>
              </figure>
            </div>

            <div class="main-nav">
              <!-- .main-navigation -->
            </div>

            <div class="center-address-fa center-address main-location text-center">
              <ul>
                <li class="location-has-children">
                  <a href="" id="openButtonModalAddress" data-toggle="modal" data-target="#modalAddress" class="list-of-user size-address" :class="$route.name == 'checkout' || $route.name == 'zcourier' ? 'btn disabled' : ''" style="color: #000 !important;">
                    <span style="padding-right: 5px;"><i class="fas fa-map-marker-alt"></i></span>
                    <span v-if="defaultAddress.direccion">{{ defaultAddress.direccion }}</span>
                    <span v-else>Agregar dirección</span>
                  </a>
                </li>
              </ul>
            </div>

            <div class="login size-address">
              <a v-if="$route.name != 'checkout'" href="" class="pull-right cart" data-toggle="modal" data-target="#modal-shopping-cart">
                <strong><span v-show="getCountProducts > 0" class="count-icon">{{ getCountProducts }}</span></strong>
                <i class="fas fa-shopping-cart icon-color-red">
                </i>
              </a>
              <a v-if="!showNameUserAndDropdown" class="cs-color cs-popup-joinus-btn login-popup" data-target="#sign-in" data-toggle="modal" href="#user-register">
                Login / Register
              </a>
              <!-- Split button -->
              <div v-else class="btn-group" id="user_info">
                <a class="btn-group cs-color cs-popup-joinus-btn login-popup" href="" data-toggle="dropdown" aria-haspopup="true">
                  <a class=""> {{ user.fullname }}</a>
                  <span class="caret"></span>
                  <span class="sr-only">Toggle Dropdown</span>
                </a>
                <ul class="dropdown-menu pull-right">
                  <li>
                    <a href="" data-toggle="modal" data-target="#modalProfile" class="list-of-user"><i class="fas fa-user icon-profile"></i> <span style="padding-left: 4px;">Perfil</span></a>
                  </li>
                  <li>
                    <a href="" data-toggle="modal" data-target="#myModalPendingOrders" class="list-of-user"><i class="fas fa-shipping-fast"></i> Pedidos pendientes</a>
                  </li>
                  <li>
                    <a href="" data-toggle="modal" data-target="#myModalHistoricalOrders" class="list-of-user"><i class="fas fa-shopping-cart"></i> <span style="padding-left: 2px;">Mis pedidos</span></a>
                  </li>
                  <li role="separator" class="divider"></li>
                  <li>
                    <a v-on:click.prevent="logout" href="">
                      <i class="fa fa-lock" style="padding-left: 2px;"></i> Cerrar Sesion</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- not auth -->
            <div v-else class="col-sm-12 col-xs-12" style="position: relative;">
              <div class="logo logo-img">
                <figure v-if="defaultAddress">
                  <router-link class="light-logo" :to="{ name: 'Home' }">
                    <img
                    :src="require('@/assets/images/logo z rojo.png')"
                    alt="FoodBakery" width="100%"
                    />
                  </router-link>
                </figure>
                <figure v-else>
                  <router-link class="light-logo" :to="{ name: 'Index' }">
                    <img
                    :src="require('@/assets/images/logo z rojo.png')"
                    alt="FoodBakery" width="100%"
                    />
                  </router-link>
                </figure>
              </div>
              <div class="center-address-fa center-address main-location text-center">
                <ul v-if="defaultAddress.direccion">
                  <li class="location-has-children">
                    <a href="" data-toggle="modal" data-target="#modalAddDeliveryAddress" class="list-of-user size-address" style="color: #000 !important;">
                      <span style="padding-right: 5px;"><i class="fas fa-map-marker-alt"></i></span>
                      <span>{{ defaultAddress.direccion }}</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="login size-address btn-empezar">
                <a class="btn btn-danger btn-lg" data-toggle="modal" data-target="#modalLogin">Empezar</a>
              </div>
            </div>
            <!-- not auth END -->
          </div>

        </div>
      </div>
    </header>
  </template>

  <style scoped>
  .center-address-fa {
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 50%;
    height: 75%;
    margin: auto;
  }
  .center-address {
    bottom: -10px;
  }
  #user_info li{
    list-style:none;
  }
  .cart {
    margin-left: 20px;
  }
  .login {
    float: right;
    text-align: right;
    color: #333;
    font-size: 13px;
    font-weight: 700;
    margin: 0 0 0 15px;
    text-transform: uppercase;
    display: inline-block;
    margin-top: 22px;
  }
  .logo-img {
    padding-top: 20px;
    padding-bottom: 19px;
  }
  .login a {
    outline: none;
    text-decoration: none;
  }
  .size-address {
    font-size: 14px !important;
  }
  .icon-color-red {
    margin-top: 1px;
    color: #FE2526 !important;
    font-size: 19px;
  }
  .count-icon {
    color: #FE2526;
    position: absolute;
    padding-top: 0px;
    margin-top: -13px;
    margin-left: 19px;
    font-size: 16px;
  }
  .list-of-user {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .main-location > ul > li.location-has-children > a:after {
    position: relative;
    right: 0;
    top: auto;
    content: "";
    font-size: 0px;
    line-height: 0px;
  }
  .p-btn {
    margin-top: 10px;
  }
  .btn-empezar {
    margin-top: 10px !important;
  }
  .icon-profile {
    padding-left: 2px;
  }
  @media screen and (max-width: 1200px){
    .center-address-fa {
      width: 40%;
      height: 88%;
    }
  }
  @media screen and (max-width: 1000px){
    .main-header .logo {
      margin-top: -12% !important;
    }
    .center-address-fa {
      width: 60%;
      height: 30% !important;
      padding-bottom: 0px;
    }
    .login {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 60%;
      height: 40%;
      margin-top: 80px;
      padding-bottom: 20px;
      text-align: center;
      margin-right: auto;
      margin-left: auto;
      float: none;
    }
    .center-address {
      height: 50%;
      bottom: 50px;
    }
  }
  @media screen and (max-width: 990px){
    .main-header .logo {
      margin-top: 0px !important;
      position: absolute;
      width: 100%;
      margin-left: 30%;
      text-align: center;
    }
    .center-address-fa {
      margin-top: 65px;
      width: 80%;
    }
    .login {
      margin-top: 130px !important;
      width: 100%;
    }
  }
  @media screen and (max-width: 700px){
    .center-address-fa {
      width: 60%;
    }
  }
  @media screen and (max-width: 590px){
    .cart {
      margin-left: 0px;
    }
    .main-header .logo {
      margin-left: 25%;
    }
  }
  @media screen and (max-width: 560px){
    .cart {
      padding-right: 20px;
    }
    .main-header .logo {
      margin-left: 22%;
    }
  }
  @media screen and (max-width: 450px){
    .main-header .logo {
      margin-left: 20%;
    }
  }
  @media screen and (max-width: 400px){
    .main-header .logo {
      margin-left: 17%;
    }
    .login {
      margin-top: 150px !important;
    }
  }
  @media screen and (max-width: 360px){
    .main-header .logo {
      margin-left: 14%;
    }
  }
  @media screen and (max-width: 300px){
    .main-header .logo {
      margin-left: 7%;
    }
    .login {
      margin-top: 190px !important;
    }
  }
  </style>

  <script>
  import firebase from 'firebase/app';
  import { mapState, mapGetters } from 'vuex';

  export default {
    data(){
      return{
      }
    },

    created(){
      this.$store.commit('search/setSearch', this.$route.query.search);
      if (this.isAuthenticatedMixin) {
        this.initCurrentUserInVuexMixin();
      }
      else if (this.getDefaultAddressMixin()) {
        this.initTokenStaticMixin();
        this.initDefaultAddressMixin();
      }
    },

    computed: mapState({
      defaultAddress: state => state.defaultAddress.defaultAddress,
      user: state => state.user.user,
      showNameUserAndDropdown: state => state.user.showNameUserAndDropdown,

      ...mapGetters('shoppingCart', ['getCountProducts']),
      ...mapGetters('user', ['isAuthenticated'])
    }),

    methods:{
      ChooseCity(){
        this.$emit('selectCity')
      },

      // Logout
      logout() {
        firebase.auth().signOut().then(() => {
          console.log("signup out");
          this.successToastrMixin('Sesión finalizada', 'Vuelva pronto');
          this.clearLocalStorage();
          this.clearVuex();
          this.resetAuthMixin();
          this.resetDefaultAddressMixin();
          if (this.$route.name != 'Index') {
            this.$router.push({ name: 'Index' });
          }
          this.$router.go();
          window.location.reload(true);
        }).catch((error) => {
          console.log(error);
        });
      },

      clearVuex() {
        this.$store.dispatch('user/resetUserState');
        this.$store.dispatch('shoppingCart/resetShoppingCartState');
        this.$store.dispatch('order/resetOrderState');
        this.$store.dispatch('defaultAddress/resetDefaultAddressState');
        this.$store.dispatch('search/resetSearchState');
        this.$store.dispatch('zcourier/resetZCourierState');
        this.$store.dispatch('searchBusiness/resetSearchBusinessState');
      },

      clearLocalStorage() {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('EMPRESA_STORAGE');
        localStorage.removeItem('defaultAddress');
        localStorage.removeItem('CART');
      }

    }
  }
  </script>