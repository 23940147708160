var rangos = [];
var empresa = null;

var distanceGoogle = {

	created() {
		setRangosLocal();
	},

	// public methods
	methods: {
		setEmpresaMixin(newEmpresa) {
			empresa = newEmpresa;
		},

		calcularDistanciaMixin(direccionUser) {
			const service = new window.google.maps.DistanceMatrixService();
			return new Promise((resolve, reject) => {
				service.getDistanceMatrix(
					config(direccionUser),
					(response, status) => {
						if (status !== "OK") reject(0);
						console.log("distancia", getDistanceText(response));
						const distanciaText = getDistanceText(response);
						const distanceKm = getDistanceKm(distanciaText);
						resolve(distanceKm);
					}
					);
			});
		},

		calculateCostoEnvioMixin(distancia) {
			let costo = 3.2;
			for (let i in rangos) {
				if (distancia > rangos[i].distanciaini && distancia <= rangos[i].distanciafin) {
					costo = rangos[i].costo;
					break;
				}
			}
			return costo;
		},

		setRangosMixin(newRangos){
			rangos = newRangos;
		},

	}
};

// private function
function setRangosLocal(){
	rangos = [
	{ distanciaini: 0, distanciafin: 0.5, costo: 2.5 },
	{ distanciaini: 0.5, distanciafin: 1, costo: 3 },
	{ distanciaini: 1, distanciafin: 1.5, costo: 3.5 },
	{ distanciaini: 1.5, distanciafin: 2.5, costo: 4 },
	{ distanciaini: 2.5, distanciafin: 3.2, costo: 4.5 },
	{ distanciaini: 3.2, distanciafin: 3.9, costo: 5 },
	{ distanciaini: 3.9, distanciafin: 4.6, costo: 5.5 },
	{ distanciaini: 4.6, distanciafin: 5.3, costo: 6 },
	{ distanciaini: 5.3, distanciafin: 6, costo: 6.5 },
	{ distanciaini: 6, distanciafin: 6.7, costo: 7 },
	{ distanciaini: 6.7, distanciafin: 7.4, costo: 7.5 },
	{ distanciaini: 7.4, distanciafin: 8.1, costo: 8 },
	{ distanciaini: 8.1, distanciafin: 8.8, costo: 8.5 },
	{ distanciaini: 8.8, distanciafin: 999, costo: 9 }
	];
}

function config(direccionUser) {
	const latLngUser = toLatLng(direccionUser);
	const latLngEmpresa = toLatLng(empresa);
	return {
		origins: [latLngUser],
		destinations: [latLngEmpresa],
		travelMode: "DRIVING",
		unitSystem: window.google.maps.UnitSystem.METRIC,
		avoidHighways: false,
		avoidTolls: false,
	};
}

function getDistanceText(response) {
	const distanciaText = response.rows[0].elements[0].distance.text;
	return distanciaText.replace(",", ".").trim();
}

function getDistanceKm(distanciaText) {
	const arrayDistance = distanciaText.split(" ");
	if (!arrayDistance[1]) {
		return 0;
	}
	return arrayDistance[1] == "m"
	? parseFloat(distanciaText) / 1000
	: parseFloat(distanciaText);
}

function toLatLng(direccion) {
	return new window.google.maps.LatLng(direccion.lat, direccion.lng);
}

export default distanceGoogle;