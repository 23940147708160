<template>
  <div class="modal fade come-from-modal right" id="modalAddDeliveryAddress" tabindex="-1" role="dialog" aria-labelledby="modalAddDeliveryAddress">
    <div class="modal-dialog" role="document" id="modal-AddDeliAddr-size">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" id="closeButtonmodalAddDeliveryAddress" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="fas fa-times-circle"></i></span></button>
        </div>
        <div class="modal-body row">
          <div class="col-xs-12 text-center">
            <img :src="require('@/assets/images/MOTO.png')" class="img-list wp-post-image" alt="avatar-z" width="120px" style="margin-top: -20px;">
            <h4 class="pt-3">Agrega una direccíon de entrega</h4>
            <br>
          </div>
          <div class="col-sm-5 col-xs-12">
            <div class="row">
              <div class="col-xs-12">
                <div class="block-search">
                  <div class="search-icon">
                    <i class="icon-map-marker2"></i>
                  </div>
                  <div class="search-input">
                    <div class="row">
                      <div class="col-12 input-effect" id="showIconClear">
                        <input
                        v-model="form.direccion"
                        v-on:keyup="searchAddress('autocompleteModalAddDeli')"
                        id="autocompleteModalAddDeli"
                        type="text"
                        class="effect-16 input-style input-padding"
                        :class="{'has-error': formErrors.direccion.error, 'has-content' : hasTextOnInputMixin(form.direccion)}"
                        placeholder="">
                        <label class="effect-label">Dirección</label>
                        <span class="focus-border"></span>
                        <span @click="clearInputAddress" class="showIconClear"><i class="fas fa-times showIconClear"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12">
                <span class="text-danger" ole="alert">{{ formErrors.direccion.error_msg }}</span>
              </div>
              <div class="col-xs-12">
                <div v-show="showAfterSearch" class="block-search">
                  <div class="reference-icon">
                    <i class="fas fa-id-badge"></i>
                  </div>
                  <div class="search-input">
                   <div class="row">
                    <div class="col-12 input-effect">
                      <input v-model="form.referencia" type="text" class="effect-16 input-style" :class="{'has-error': formErrors.referencia.error, 'has-content' : hasTextOnInputMixin(form.referencia)}" placeholder="">
                      <label class="effect-label">Referencia ejem, cerca a la plaza</label>
                      <span class="focus-border"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12">
              <span class="text-danger" ole="alert">{{ formErrors.referencia.error_msg }}</span>
            </div>
          </div>
        </div>
        <div class="col-sm-7 col-xs-12">
          <div id="mapModalAddDeli"></div>
        </div>
        <div class="col-xs-12 text-center">
          <button v-show="showAfterSearch" v-on:click.prevent="createAddressNoAuth" class="btn btn-danger btn-md mb-4"><i class="fas fa-check"></i> Confirmar dirección</button>
          <div><strong>¿Ya tienes una dirección guardada?</strong></div>
          <div style="margin-bottom: 7px;">Entra en tu cuenta para seleccionar tus direcciones</div>
          <a v-on:click.prevent="goLogin" href="" class="btn-red-send"><strong>Iniciar sesión o regístrate</strong></a>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped src="@/css/style-inputs.css"></style>
<style scoped src="@/css/style-modal-add-delivery-address.css"></style>
<style scoped>
.showIconClear {
  display:none !important;
  position:absolute !important;
  right: 0px;
  top: 6.5px;
  bottom: 0;
  margin: auto;
  font-size: 14px;
  cursor: pointer;
  color: #ccc;
  z-index: 200;
}
#showIconClear:hover .showIconClear {
  display:block !important;
}
.btn-red-send {
  color: #FE2526 !important;
  text-decoration:none;
  font-size: 16px;
  font-weight: bold;
}
</style>

<script>
import googleMapsMixin from '@/mixins/local/GoogleMaps.js';
import { mapGetters } from 'vuex';

export default {
  name: 'ModalAddDeliveryAddress',

  mixins: [googleMapsMixin],

  created() {
    this.idMap = 'mapModalAddDeli';
  },

  computed: {
    ...mapGetters('user', ['isAuthenticated'])
  },

  methods: {
    goLogin() {
      window.$("#modalAddDeliveryAddress").modal("hide");
      window.$("#modalLogin").modal("show");
    },

    createAddressNoAuth() {
      if (this.isAuthenticated) {
        console.log('createAddress when isAuthenticatedMixin')
        this.createAddress();
      }
      else {
        console.log('createAddress when no auth')
        this.validateForm();
        if (!this.formErrors.direccion.error && !this.formErrors.referencia.error && !this.formErrors.lat.error && !this.formErrors.lng.error) {
          this.createAddressNoAuthMixin(this.form).then(response => {
            console.log(response);
            this.$store.commit('defaultAddress/setKeyAddress', response.data.direccion.coddireccion);
            this.$store.commit('user/setToken', this.getTokenStaticMixin);
            this.setDefaultAddressMixin(response.data.direccion);
            localStorage.setItem('token', this.getTokenStaticMixin);
            this.successToastrMixin('Dirección', 'Dirección creada con exito');
            this.clearFrom();
            this.setNewTokenMixin(localStorage.getItem('token'));
            this.setNewDefaultAddressMixin(response.data.direccion);
            if (this.$route.name != 'Home') {
              this.$router.push({ name: 'Home' });
            }
          })
          .catch(error => {
            console.log(error);
            if (error.response.status == 400) {
              this.formErrors.direccion.error_msg = error.response.data.message;
              this.errorToastrMixin('Error', error.response.data.message);
            }
            else {
              this.errorToastrMixin('Error', 'Ocurrio un error, vuelva a intentar más tarde');
            }
          });
        }
      }
    }
  }
}
</script>