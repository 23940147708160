var toastrMixin = {

	methods: {
		successToastrMixin(title, body) {
			this.$toastr.success(body, title, {
				"closeButton": true,
				"positionClass": "toast-bottom-right",
				"progressBar": true,
			});
		},

		errorToastrMixin(title, body) {
			this.$toastr.error(body, title, {
				"closeButton": true,
				"positionClass": "toast-bottom-right",
				"progressBar": true,
			});
		},

		warningToastrMixin(title, body) {
			this.$toastr.warning(body, title, {
				"closeButton": true,
				"positionClass": "toast-bottom-right",
				"progressBar": true,
			});
		},

	}
};

export default toastrMixin;