const getDefaultState = () => {
	return {
		defaultAddress: {},
		addressUser: [],
		isNullAddress: false,
		keyAddress: 0,
		listModal: 1 // 1 ->defaul, 0 -> list cities, 2 -> form
	}
}

export default {
	namespaced: true,

	state: getDefaultState(),

	mutations: {
		setDefaultAddress(state, defaultAddress) {
			state.defaultAddress = defaultAddress;
		},

		setAddressUser(state, addressUser) {
			state.addressUser = addressUser;
		},

		setKeyAddress(state, keyAddress) {
			state.keyAddress = keyAddress;
		},

		setListModal(state, listModal) {
			state.listModal = listModal;
		},

		changeIsNullAddress(state, newState) {
			state.isNullAddress = newState;
		},

		resetState (state) {
			Object.assign(state, getDefaultState())
		}
	},

	actions: {
		resetDefaultAddressState({ commit }) {
			commit('resetState');
		}
	}
}