<template>
  <div>
    <!-- <section-forms :title="title"></section-forms> -->
    <div class="container container_gobal">
      <div class="container__div">
        <div class="container__contact">
          <h2 class="title_contact">Contacto</h2>
          <p class="contact_email">zpedidos.contact@gmail.com</p>
          <strong class="subtitle_support">Centrales de soporte</strong><br><br>
          <div style="margin-left: 20px;">
            <div v-for="city in cities" :key="city.codciudad">
              <strong class="cities_name">{{ city.nombre }}</strong><br>
              <p class="contact_num">{{ city.telefonos}}</p><br>
            </div>
          </div>
        </div>
        <div class="container__image">
          <img :src="require('@/assets/images/imgsForms/workers.jpg')" alt="imagen de trabajadores" width="100%">
        </div>
      </div>
      <div class="hiddendiv">
      </div>
      <div class="blockquote">
        <i class="fas fa-bullhorn" style="color: #5e2ced;"></i>
        <p class="p__paragraf">“Estamos muy agradecidos de tener un equipo bueno que comparta nuestra visión. Gracias a todos los que forman parte de la familia ZPEDIDOS”.</p>
      </div>
    </div>
  </div>
</template>

<script>
/* import SectionForms from '@/components/SectionForms.vue' */
export default {
  data(){
    return {
      title: 'Contacto Zpedidos',
      cities: []  
    }
  },
  created(){
    this.getSupport()
  },
  /* components: {SectionForms}, */
  mounted(){
    this.goMainSectionMixin();
  },
  methods:{
    getSupport(){
      this.getSupportCitiesMixin().then((response) => {
        this.cities = response.data;
      });
    }
  }
}
</script>
<style scoped>
.container_gobal{
  margin-top: 5rem;
  min-height: 800px;
}
.container__div{
  display: flex;
  flex-direction: column;
}
.container__contact{
  height: 50rem;
  width: 100%;
  margin-left: 5px;
}
.container__image{
  height: 50rem;
  width: 100%;
}
.blockquote{
  background: white;
  display: flex;
  min-height: 20rem;
  border-left: 8px #5e2ced solid;
  border-radius: 6px;
  font-size: 3rem;
  text-align: center;
  align-content: center;
  align-items: center;
}
.hiddendiv{
  position: absolute;
  right: -1000px;
  top: -50px;
  height: 10rem;
  width: 500rem;
  background: #EBEDF3;
  z-index: -1;
}
.title_contact{
  color: #4c5267 !important;
  font-weight: 500 !important;
  font-size: 32px !important;
  letter-spacing: 5px;
}
.contact_num, .contact_email{
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 300;
}
.p__paragraf{letter-spacing: 0.2rem; line-height: 120%;}
.cities_name, .subtitle_support{
  letter-spacing: 2px;
  font-weight: 450;
}
@media(min-width: 800px){
  .container_gobal{
    position: relative;
    z-index: 1;
  }
  .container__div{
    flex-direction: row;
  }
}
</style>