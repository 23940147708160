import configs from '@/utils/configs'

var configMixin = {
	methods: {
		goMainSectionMixin(){
			document.querySelector('#app').scrollIntoView();
		}
	},
	computed: {
		urlImgMixin() {
			return configs.urlImg;
		},
		
		urlImgOffersMixin() {
			return configs.urlImgOffers;
		},

		urlNewZetas(){
			return configs.urlNewZetas;
		},
	}
};

export default configMixin;