<template>
	<!-- Main Section Start -->
	<div class="main-section">
		<div class="page-section nopadding cs-nomargin" style="margin-top: 0px;padding-top: 60px;padding-bottom: 10px;margin-bottom: 0px;">
			<div class="container">
				<!-- list 1-->
				<div v-if="list == 1" class="row">
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<div class="row">
							<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<h1>ZCourier</h1>
								<p>Llevar unas llaves, unos documentos, el almuerzo de un familiar, ¡etc.! ¡Si entra en nuestras mochilas, podemos entregarlo!</p>
								<br>
							</div>
							<!-- letf -->
							<div class="section-sidebar col-lg-8 col-md-8 col-sm-12 col-xs-12">
								<div class="order-sort-results">
									<h5>Ruta</h5>
									<div class="container-address">
										<div class="pull-right">
											<a v-on:click.prevent="openModalAddress(2, true)" href="" class="btn btn-danger btn-sm"><i class="fas fa-pencil-alt"></i> Agregar</a>
										</div>
										<div>
											<span class="letterCircle">A</span>
											<a v-on:click.prevent="openModalAddress(1, true)" class="addressAB" href="">
												<span v-if="!addressA">Dirección A</span>
												<span v-else class="text-dark">{{ addressA.direccion }}</span>
												<span class="caret"></span>
											</a>
										</div>
									</div>
									<span class="text-danger" ole="alert">{{ formOrderErrors.coddireccionA.error_msg }} </span>
									<hr>
									<div class="container-address">
										<div class="pull-right">
											<a v-on:click.prevent="openModalAddress(2, false)" href="" class="btn btn-danger btn-sm"><i class="fas fa-pencil-alt"></i> Agregar</a>
										</div>
										<div>
											<span class="letterCircle">B</span>
											<a v-on:click.prevent="openModalAddress(1, false)" class="addressAB" href="">
												<span v-if="!addressB">Dirección B</span>
												<span v-else class="text-dark">{{ addressB.direccion }}</span>
												<span class="caret"></span>
											</a>
										</div>
									</div>
									<span class="text-danger" ole="alert">{{ formOrderErrors.coddireccion.error_msg }} </span>
									<hr>
									<div class="checkbox-shipping">
										<input v-model="order.idavuelta" type="checkbox" id="terms-conditions">
										<label for="terms-conditions">
											<span class="checkbox-text"> Viaje ida y vuelta</span>
										</label>
									</div>
								</div>

								<div class="order-sort-results">
									<h5>Tu pedido</h5>
									<form class="row input-container">
										<div class="col-xs-12">
											<div class="row">
												<div class="col-12 input-effect">
													<input
													v-model="order.descripcion"
													type="text"
													class="effect-16 input-style"
													:class="{'has-error' : formOrderErrors.descripcion.error, 'has-content' : hasTextOnInputMixin(order.descripcion)}"
													placeholder="">
													<label class="effect-label">¿Qué quieres enviar?</label>
													<span class="focus-border"></span>
												</div>
											</div>
										</div>
										<div class="col-xs-12">
											<span class="text-danger" ole="alert">{{ formOrderErrors.descripcion.error_msg }} </span>
										</div>
									</form>
								</div>
							</div>
							<!-- letf End -->
							<!-- right -->
							<div class="sticky-sidebar col-lg-4 col-md-4 col-sm-12 col-xs-12">
								<div class="user-order-holder">
									<div class="user-order">
										<div class="price-area dev-menu-price-con">
											<h5>Resumen</h5>
											<ul>
												<li>Costo de servicio <span class="price">S/. <em class="dev-menu-subtotal">1.00</em></span></li>
												<li>Costo de envio<span class="price"><span v-show="order.idavuelta" class="x2-span">x2 </span> S/. <em class="dev-menu-vtax">{{ costShipping.toFixed(2) }}</em></span></li>
												<li v-show="rainCharge > 0">Cargo por lluvia<span class="price">S/. <em class="dev-menu-vtax">{{ rainCharge.toFixed(2) }}</em></span></li>
											</ul>
											<p class="total-price">Total del pedido <span class="price">S/. <em class="dev-menu-grtotal"> {{ getTotal.toFixed(2) }}</em></span></p>
										</div>
										<div id="dev-no-menu-orders-list">
											<span class="success-message">*El costo de envió es en base a la distancia {{ distance }} km.</span>
										</div>
										<div class="pay-option dev-order-pay-options">
											<form class="row">
												<div v-for="payment in paymentMethods" :key="payment.codmetodopago" class="col-sm-6">
													<div class="align-payment">
														<img v-if="payment.codmetodopago != 1" :src="urlImgMixin + payment.imagen" class="img-list wp-post-image" alt="avatar-z" width="50px">
														<img v-else="" :src="require('@/assets/images/dinero.jpg')" class="img-list wp-post-image" alt="avatar-z" width="50px">
														<div class="form-inline">
															<input v-model="paymentRadio" :id="payment.codmetodopago" :value="payment.codmetodopago" type="radio">
															<label :for="payment.codmetodopago"> {{ payment.nombre }}</label>
														</div>
													</div>
												</div>
											</form>
											<span class="text-danger" ole="alert">{{ formOrderErrors.metodopago.error_msg }} </span>
										</div>
										<form v-show="paymentRadio == 1">
											<div class="row input-container">
												<div class="col-12 input-effect">
													<input
													v-model="amount"
													type="number"
													class="effect-16 input-style"
													:class="{'has-content' : hasTextOnInputMixin(amount)}"
													placeholder=""
													step="0.1" min="0">
													<label class="effect-label">Monto</label>
													<span class="focus-border"></span>
												</div>
											</div>
										</form>
										<a v-on:click.prevent="confirmOrder" href="" class="btn btn-danger btn-block"><i class="fas fa-shopping-cart"></i> Realizar pedido S/. {{ getTotal.toFixed(2) }}</a>
									</div>
								</div>
							</div>
							<!-- right End -->
						</div>
					</div>
				</div>
				<!-- End list 1-->
				<!-- list 0-->
				<div v-else class="row">
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<h1>Detalles del pedido</h1>
					</div>
					<result-order></result-order>
				</div>
				<!-- End list 0-->
			</div>
		</div>
	</div>
	<!-- Main Section End -->
</template>

<style scoped src="@/css/style-inputs.css"></style>
<style scoped>
.container-address {
	padding-top: 10px;
	padding-bottom: 10px;
}
.letterCircle {
	color: #fff;
	border-radius: 50%;
	background-color: #000;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin: 0px auto;
	padding: 1% 2%;
	box-shadow: 1.5px 1.5px 3px #592A08;
}
.checkbox-shipping {
	padding-left: 10px;
}
.checkbox-text {
	padding-left: 32px;
}
.addressAB {
	padding-left: 15px;
	color: #777;
}
.text-dark {
	color: #000;
}
.x2-span {
	color: #777;
	margin-right: 5px;
}
</style>

<script>
import { mapState } from 'vuex';
import ResultOrder from '@/components/ResultOrder.vue';

export default {
	name: 'ZCourier',

	components: { ResultOrder },

	data() {
		return {
			list: 1,
			paymentMethods: [],
			paymentRadio: '',
			// Summary
			rainCharge: 0.00,
			total: 0.00,
			amount: '0',
			//order
			order: {
				'coddireccionA': '',
				'coddireccion': '',
				'codusuario': null,
				'descripcion': '',
				'descuento': 0,
				'envio': 0,
				'idavuelta': false,
				'empresaCurrier': false,
				'lluvia': 0,
				'subtotal': 0,
				'zcurrier': true,
				'metodopago': '',
				'efectivocliente': '',
				'plataforma': 'web'
			},
			formOrderErrors: {
				coddireccionA: {
					error: false,
					error_msg: '',
				},
				coddireccion: {
					error: false,
					error_msg: '',
				},
				descripcion: {
					error: false,
					error_msg: '',
				},
				metodopago: {
					error: false,
					error_msg: '',
				},
			}
		}
	},

	created() {
		if (this.isAuthenticatedMixin) {
			this.getPaymentMethod();
			this.settingCity();
			this.order.codusuario = this.getCurrentUserMixin.codusuario;
		}
	},

	computed: mapState({
		addressA: state => state.zcourier.addressA,
		addressB: state => state.zcourier.addressB,
		costShipping: state => state.zcourier.costShipping,
		distance: state => state.zcourier.distance,
		getTotal() {
			let total = 0;
			if (this.order.idavuelta) {
				total = this.rainCharge + 1 + 2 * this.costShipping;
			}
			else {
				total = this.rainCharge + 1 + this.costShipping;
			}
			return total;
		}
	}),

	methods: {
		getPaymentMethod() {
			this.getPaymentMethodMixin().then(response => {
				this.paymentMethods = response.data;
			});
		},

		openModalAddress(listModal, isAddressA) {
			this.$store.commit('defaultAddress/setListModal', listModal);
			this.$store.commit('zcourier/setIsAddressA', isAddressA);
			console.log('listModal', listModal, 'isAddressA', isAddressA)
			window.$("#modalAddress").modal("show");
		},

		settingCity() {
			this.settingCityMixin(this.getUbicationMixin()).then(response => {
				console.log('setting', response);
				let settingCityData = response.data.configuracion;
				this.$store.commit('shoppingCart/setDeliverygratis', response.data.configuracion.deliverygratis);
				this.order.lluvia = settingCityData.lluvia;
				if (parseInt(settingCityData.lluvia) != 0) {
					this.rainCharge = 1.00;
				}
			});
		},

		confirmOrder() {
			this.validateOrder();
			if (!this.formOrderErrors.coddireccionA.error &&
				!this.formOrderErrors.coddireccion.error &&
				!this.formOrderErrors.descripcion.error) {
				this.$swal.fire({
					title: '¿Realizar pedido?',
					text: "¿Estás seguro que deseas realizar el pedido?",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Si, realizar!'
				}).then((result) => {
					if (result.isConfirmed) {
						this.createOrder();
					}
				});
			}
			else {
				this.errorToastrMixin('Error', 'LLene los campos faltantes.');
			}
		},

		createOrder() {
			this.order.envio = this.costShipping;
			this.order.subtotal = 1;
			console.log('this.order', this.order);
			this.createOrderMixin(this.order).then(response => {
				this.$swal.fire(
					'Confirmado!',
					'Tu pedido ha sido aceptado.',
					'success'
					);
				console.log('this.resultOrder ', response );
				this.successToastrMixin('Pedido', 'Tu pedido ha sido realizado con éxito');
				this.$store.commit('order/setIsZCurrier', true);
				this.$store.commit('order/setOrder', response.data.pedido[0]);
				this.list = 0;
			}).catch(error => {
				console.log(error);
				this.errorToastrMixin('Error', 'Ocurrio un error, vuelva a intentar más tarde');
			});
		},

		validateOrder() {
			this.order.coddireccionA = this.addressA;
			this.order.coddireccion = this.addressB;
			this.order.metodopago = this.paymentRadio;
			this.order.efectivocliente = this.amount;
			if (this.order.coddireccionA == '') {
				this.formOrderErrors.coddireccionA.error_msg = 'Seleccione la dirección A.';
				this.formOrderErrors.coddireccionA.error = true;
			}
			else {
				this.formOrderErrors.coddireccionA.error_msg = '';
				this.formOrderErrors.coddireccionA.error = false;
			}
			if (this.order.coddireccion == '') {
				this.formOrderErrors.coddireccion.error_msg = 'Seleccione la dirección B.';
				this.formOrderErrors.coddireccion.error = true;
			}
			else {
				this.formOrderErrors.coddireccion.error_msg = '';
				this.formOrderErrors.coddireccion.error = false;
			}
			if (this.order.descripcion == '') {
				this.formOrderErrors.descripcion.error_msg = 'Agregue una descripción a su pedido.';
				this.formOrderErrors.descripcion.error = true;
			}
			else {
				this.formOrderErrors.descripcion.error_msg = '';
				this.formOrderErrors.descripcion.error = false;
			}
			if (this.order.metodopago == '') {
				this.formOrderErrors.metodopago.error_msg = 'Seleccione un método de pago.';
				this.formOrderErrors.metodopago.error = true;
			}
			else {
				this.formOrderErrors.metodopago.error_msg = '';
				this.formOrderErrors.metodopago.error = false;
			}
		}

	}

}
</script>
