import axios from 'axios'
import configs from '@/utils/configs'

var apiProductMixin = {

	computed: {

	},

	methods: {
		getDataProductsCartMixin(id_cart){
			return axios({
				url: configs.apiUrl + `/productos/carta/${id_cart}`,
				method: 'GET',
				headers:{'Authorization': 'bearer ' + this.$store.state.user.token},
			})
		},
		getDataCategoriesMixin(id_cart){
			return axios({
				url: configs.apiUrl + `/productocategorias/carta/${id_cart}`,
				method: 'GET',
				headers:{'Authorization': 'bearer ' + this.$store.state.user.token},
			})
		},
		getProductsbyCategoryMixin(id_category){
			return axios({
				url: configs.apiUrl + `/productos/productocategoria/${ id_category }`,
				method: 'GET',
				headers:{'Authorization': 'bearer ' + this.$store.state.user.token},
			})
		},
		getProductSearcherMixin(codBusiness, string){
			const url = configs.apiUrl + `/productos/search/${ string }`
			return axios.post(url, codBusiness, {
				headers: {'Authorization': 'bearer ' + this.$store.state.user.token}
			})
		}
	}
};

export default apiProductMixin;