import axios from 'axios'
import configs from '@/utils/configs'

var apiShoppingCartMixin = {

	computed: {

	},

	methods: {
		settingCityMixin(form) {
			const apiURL = configs.apiUrl + '/configuracion';
			return axios.post(apiURL, form, {
				headers: {'Authorization': 'bearer ' + this.$store.state.user.token}
			});
		},

		getPaymentMethodMixin() {
			const apiURL = configs.apiUrl + '/metodopagos';
			return axios.get(apiURL, {
				headers: {'Authorization': 'bearer ' + this.$store.state.user.token}
			});
		},

		searchCouponMixin(codeUser_cupon) {
			const apiURL = configs.apiUrl + '/cupones/search';
			return axios.post(apiURL, codeUser_cupon, {
				headers: {'Authorization': 'bearer ' + this.$store.state.user.token}
			});
		},

		getShippingCityMixin() {
			let defaultAddress = this.getDefaultAddressMixin();
			let lat_long = {
				'lat': defaultAddress.lat,
				'lng': defaultAddress.lng
			};
			const apiURL = configs.apiUrl + '/ciudadenvios/latlng';
			return axios.post(apiURL, lat_long, {
				headers: {'Authorization': 'bearer ' + this.$store.state.user.token}
			});
		}
	}
};

export default apiShoppingCartMixin;