<template>
  <div class="modal fade  come-from-modal right" id="ModalHelp" tabindex="-1" role="dialog" aria-labelledby="ModalHelp">
    <div class="modal-dialog" role="document" id="modal-help-size">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" id="closeButtonModalPendingOrders" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="fas fa-times-circle"></i></span></button>
        </div>
        <div class="modal-body container-modal">
          <div class="text-center">
            <img :src="require('@/assets/images/MOTO.png')" class="img-list wp-post-image" alt="avatar-z" width="250px">
          </div>
          <br>
          <h4 class="h4">Hola {{ user.fullname }}</h4>
          <p>Si tienes alguna modificación, reclamo sobre tu pedido, consúltalo a nuestros números de soporte.</p>
          <div class="row input-container">
            <div class="col-12 input-effect">
              <select v-model="citySelectCod" v-on:change.prevent="selectCity" class="effect-16 input-style" :class="{'is-select' : hasTextOnInputMixin(citySelectCod)}">
                <option disabled value="">Selecciona tu ciudad</option>
                <option v-for="city in cities" :key="city.codciudad" :value="city.codciudad">{{ city.nombre }}</option>
              </select>
            </div>
          </div>
          <div class="container-label">
            <span class="label label-success color-green-label">{{ citySelected }}</span>
          </div>
          <ul class="style-li">
            <li v-for="(phone, index) in phones" :key="index">
              <a :href="'https://wa.me/+51' +phone+ '/?text=Hola...!'" target="_blank" rel="noopener"> <img :src="require('@/assets/images/wasap.png')" class="img-list wp-post-image" alt="avatar-z" width="25px" style="margin-right: 7px;">
              </a>
              <span>{{ phone }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped src="@/css/style-inputs.css"></style>
<style scoped>
#modal-help-size {
  width: 50% !important;
}
.container-modal {
  height: 643px;
}
.container-label {
  margin-top: 20px;
  margin-bottom: 10px;
}
.color-green-label {
  background-color: #43ae7b !important;
  font-size: 16px;
}
.style-li li{
  list-style: none;
  padding-bottom: 5px;
  padding-top: 5px;
}
@media screen and (min-width: 100px){
  #modal-help-size {
    width: 93% !important;
  }
}
@media screen and (min-width: 550px){
  #modal-help-size {
    width: 80% !important;
  }
}
@media screen and (min-width: 650px){
  #modal-help-size {
    width: 500px !important;
  }
}
</style>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ModalHelp',

  data() {
    return {
      cities: [],
      citySelectCod: '',
      citySelected: '',
      phones: []
    }
  },

  computed: mapState({
    user: state => state.user.user,
  }),

  created() {
    this.getCities();
  },

  methods: {
    getCities() {
      this.getSupportCitiesMixin().then(response => {
        this.cities = response.data;
        console.log('cities', this.cities)
      });
    },

    selectCity() {
      for (let i = 0; i < this.cities.length; i++) {
        if (this.cities[i].codciudad == this.citySelectCod) {
          this.citySelected = this.cities[i].nombre;
          this.phones = this.cities[i].telefonos.split(',');
        }
      }
    }
  }
}
</script>



