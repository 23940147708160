<template>
  <div>
    <section class="section_field" id="#head">
      <h2 class="form__title">{{ title }}</h2>
    </section>
    <div class="container__image">
      <img class="container__image--img" :src="require('@/assets/images/imgsForms/circulo.png')" alt="circulo">
    </div>
    <!-- <div class="hidden1">
    </div> -->
  </div>
</template>
<script>
export default {
  name: 'sectionforms',
  data(){
    return{

    }
  },
  props: ['title']
}
</script>

<style scoped>
.section_field{
  position: relative;
  display: flex;
  background: -webkit-linear-gradient(180deg, #fe2526 0%, #fd5433 100%); 
  height: 485px;
  justify-content: center;
  align-items: center;
}
.form__title{
  position: relative;
  display: flex;
  color: white !important;
  font-size: 5rem !important;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
}
.container__image--img{
  top: 0;
  right: 0;
  position: absolute;
  z-index: 0;
}
/* .hidden1{
  position: absolute;
  height: 10rem;
  width: 50rem;
  background: black;
  z-index: 0;
  right: 100px;
  top: 550px;
  opacity: 0.5;
} */
</style>