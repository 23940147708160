<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-6 col-xs-12">
        <div class="notfound">
          <h1>Ups...</h1>
          <p>¡Algo salió mal! Siempre estamos en constante mejora de nuestra plataforma, danos un tiempo y arreglaremos esto también.</p>
          <div class="btn-center">
            <router-link :to="'/home'" class="btn btn-danger btn-lg">Regresar al inicio</router-link>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xs-12">
        <div class="text-center">
          <img :src="require('@/assets/images/404.png')" class="img-list wp-post-image" alt="avatar-z" width="90%">
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped src="@/css/style-error-404.css"></style>
<style scoped>
</style>

<script>
export default {
  name: 'Error404'
}
</script>
