<template>
  <div class="modal fade  come-from-modal right" id="ModalStarRating" tabindex="-1" role="dialog" aria-labelledby="ModalStarRating">
    <div class="modal-dialog" role="document" id="modal-star-rating-size">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" id="closeButtonModalPendingOrders" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="fas fa-times-circle"></i></span></button>
          <h4 class="modal-title" id="ModalStarRating">Calificar - Pedido #{{ orderNumber }}</h4>
        </div>
        <div class="modal-body">
          <div class="card-container">
            <h5><i class="fas fa-home"></i> Empresa</h5>
            <div class="center-rating">
              <div class="center-rat">
                <star-rating
                v-model="ratingBusiness"
                :show-rating="false"
                :padding="10"
                :inactive-color="'#fff'"
                :border-width="3"
                :border-color="'#ffa000'"
                :active-border-color="'#43ae7b'"
                :active-color="'#43ae7b'"></star-rating>
                <p class="text-p">Observaciones o sugerencias</p>
                <div class="row input-container">
                  <div class="col-12 input-effect">
                    <input
                    v-model="form.obsempresa"
                    type="text"
                    class="effect-16 input-style"
                    :class="{'has-content' : hasTextOnInputMixin(form.obsempresa)}"
                    placeholder="">
                    <label class="effect-label">Cuentanos tu experiencia</label>
                    <span class="focus-border"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span v-show="false">{{ qualificationObtained }}</span>
          <br>
          <div class="card-container">
            <h5><i class="fas fa-user-circle"></i> Repartidor</h5>
            <div class="center-rating">
              <div class="center-rat">
                <star-rating
                v-model="ratingDelivery"
                :show-rating="false"
                :padding="10"
                :inactive-color="'#fff'"
                :border-width="3"
                :border-color="'#ffa000'"
                :active-border-color="'#43ae7b'"
                :active-color="'#43ae7b'"></star-rating>
                <p class="text-p">Observaciones o sugerencias</p>
                <div class="row input-container">
                  <div class="col-12 input-effect">
                    <input
                    v-model="form.obszeta"
                    type="text"
                    class="effect-16 input-style"
                    :class="{'has-content' : hasTextOnInputMixin(form.obszeta)}"
                    placeholder="">
                    <label class="effect-label">Cuentanos tu experiencia</label>
                    <span class="focus-border"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br>
          <button v-on:click.prevent="qualify" class="btn btn-danger btn-lg btn-block"><i class="fas fa-paper-plane"></i> Calificar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped src="@/css/style-inputs.css"></style>
<style scoped>
#modal-star-rating-size {
  width: 50% !important;
}
.card-container {
  padding: 20px 10px 20px 40px;
  border: 1px solid #999999;
  border-radius: 15px;
  box-shadow: 0px 0px 5px 1px #999999;
  height: 252px;
  background-color: #fff;
}
.center-rating {
  position: relative;
}
.center-rat {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 90%;
  height: 30%;
  margin: auto;
}
.text-p {
  padding-top: 20px;
  color: #43ae7b;
}
.input-container {
  padding-right: 20px;
}
@media screen and (min-width: 100px){
  #modal-star-rating-size {
    width: 93% !important;
  }
}
@media screen and (min-width: 550px){
  #modal-star-rating-size {
    width: 80% !important;
  }
}
@media screen and (min-width: 650px){
  #modal-star-rating-size {
    width: 500px !important;
  }
}
</style>

<script>
import StarRating from 'vue-star-rating';
import { mapState } from 'vuex';

export default {
  name: 'ModalStarRating',

  components: { StarRating },

  data() {
    return {
      ratingBusiness: 3,
      ratingDelivery: 3,
      // From
      form: {
        'codpedido': null,
        'calificacionEmpresa': null,
        'obsempresa': '',
        'calificacionZeta': null,
        'obszeta': ''
      }
    }
  },

  computed: mapState({
    orderNumber: state => state.order.orderNumber,
    codpedido: state => state.order.codpedido,
    getQualify: state => state.order.qualify,
    qualificationObtained() {
      if (this.getQualify) {
        this.form.obsempresa = this.getQualify.calificacionempresa.obsempresa;
        this.ratingBusiness = this.getQualify.calificacionempresa.calificacion;
        this.form.obszeta = this.getQualify.calificacionzeta.obszeta;
        this.ratingDelivery = this.getQualify.calificacionzeta.calificacion;
        return this.getQualify.calificacionempresa.codcalificacionempresa;
      }
      else {
        this.form.obsempresa = '';
        this.ratingBusiness = 3;
        this.form.obszeta = '';
        this.ratingDelivery = 3;
        return 1;
      }
    }
  }),

  methods: {
    qualify() {
      this.form.calificacionEmpresa = this.ratingBusiness;
      this.form.calificacionZeta = this.ratingDelivery;
      this.form.codpedido = this.codpedido;
      this.qualifyOrderMixin(this.form).then(response => {
        this.successToastrMixin('Calificación', 'La calificación se envio correctamente');
        console.log('responde qualify', response);
        window.$('#ModalStarRating').modal('hide');
      }).catch((error) => {
        console.log(error);
        this.errorToastrMixin('Error', 'Ocurrió un error, vuelva a intentar más tarde');
      });
    }
  }
}
</script>



