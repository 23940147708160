import axios from 'axios'
import configs from '@/utils/configs'

var apiAddresMixin = {

	computed: {

	},

	methods: {
		getAddressUserMixin() {
			const apiURL = configs.apiUrl + '/direccion/usuario/' + this.getCurrentUserMixin.codusuario;
			return axios.get(apiURL, {
				headers: {'Authorization': 'bearer ' + this.$store.state.user.token}
			});
		},

		updateStateAddressMixin(codeAddress) {
			const apiURL = configs.apiUrl + `/direccion/usuario/${this.getCurrentUserMixin.codusuario}/estado`;
			return axios.put(apiURL, {'coddireccion': codeAddress}, {
				headers: {'Authorization': 'bearer ' + this.$store.state.user.token}
			});
		},

		createAddressMixin(form) {
			const apiURL = configs.apiUrl + '/direccion/createweb';
			return axios.post(apiURL, form, {
				headers: {'Authorization': 'bearer ' + this.$store.state.user.token}
			});
		},

		createAddressNoAuthMixin(form) {
			const apiURL = configs.apiUrl + '/direccion/createweb';
			return axios.post(apiURL, form, {
				headers: {'Authorization': 'bearer ' + this.getTokenStaticMixin}
			});
		}

	}
};

export default apiAddresMixin;