<template>
	<!-- Main Section Start -->
	<div class="main-section">
		<!-- Home Pages Elements Strat -->

		<!-- Main Search and Categories Strat -->
		<div class="page-section  nopadding cs-nomargin" style="position:relative;margin-top: 0px; padding-top: 50px; padding-bottom: 0px; margin-bottom: -60px; background: #FE2526;">
			<!-- Container Start -->
			<div class="container">
				<div class="row">
					<div class="banner" style="margin-bottom: -80px;">
						<img alt="" class="img-1" :src="require('@/assets/images/PIZZA ICONO.png')"/>
						<img alt="" class="img-2" :src="require('@/assets/images/BROASTER ICONO.png')"/>
					</div>
					<div class="section-fullwidth col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<div class="row">
							<div class="listing-main-search">
								<div class="element-title">
									<h1 style="color:#ffffff !important; font-size: 35px !important; letter-spacing: 1px !important; line-height: 54px !important; text-align:center!important; font-weight:600!important; text-transform: none !important;">
									¡Pedir COMIDA y más, nunca fue tan fácil!</h1>
									<br>
								</div>
							</div>
							<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
								<!--Element Section Start-->
								<!--Foodbakery Element Start-->
								<div class="row">
									<!-- Column Start -->
									<div class="listing-main-search">
										<div class="text-holder">
											<strong></strong>
											<span></span>
										</div>
										<div class="block-search">
											<a href="" data-toggle="modal" data-target="#modalAddDeliveryAddress" class="search-icon">
												<i class="icon-map-marker2"></i>
											</a>
											<a href="" data-toggle="modal" data-target="#modalAddDeliveryAddress" class="search-input">
												<input type="text" class=" " placeholder="Buscar tu dirección">
											</a>
											<input class="btn-search" value="Buscar" type="submit" data-toggle="modal" data-target="#modalAddDeliveryAddress">
										</div>
									</div>
									<!-- Column End -->
								</div>
								<!--Foodbakery Element End-->
							</div>
						</div>
						<!-- end section row -->
					</div>
					<div class="banner" style="margin-top: -20px;">
						<img alt="" class="img-3" :src="require('@/assets/images/PAPAS ICONO.png')"/>
						<img alt="" class="img-5" :src="require('@/assets/images/BURGER ICONO.png')" />
					</div>
					<div class="banner" style="margin-top: -20px;">
						<img alt="" class="img-4" :src="require('@/assets/images/BURRITO ICONO.png')"/>
					</div>
				</div>
			</div>
			<div>
				<img data-v-2fc35b3b="" :src="require('@/assets/svg/curve.svg')" alt="Glovo" class="landing__jumbotron__curve" style="
				">
			</div>
			<!-- End Container Start -->
		</div>
		<!-- Main Search and Categories End -->

		<!-- Colunm Text Section Start -->
		<div class="container-links page-section nopadding cs-nomargin" style="margin-top: 0px;padding-top: 0px;padding-bottom: 0px;margin-bottom: 0px;background: #ffffff;">
			<div class="container">
				<div class="row">
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<div class="main-post promo-box" style="display:flex;align-items:center;justify-content: center;">
							<div class="block-store">
								<h1 style="color:#000000 !important;margin-bottom:30px; text-transform: none !important;">Disfruta una mejor experiencia descargando la App
								</h1>
								<div class="store-btn">
									<a class="app-btn" href="#"><img :src="require('@/assets/extra-images/google.png')" alt=""></a>
									<a class="app-btn" href=""><img :src="require('@/assets/extra-images/appstore.png')" alt=""></a>
								</div>
							</div>
							<div class="img-frame">
								<figure><img style="width:550px" :src="require('@/assets/images/comida.png')" alt="#">
								</figure>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Colunm Text Section End -->

		<!-- Popular Localities Section End -->

		<!-- Choose From Most Popular Section Start -->
		<ad></ad>
		<!-- Choose From Most Popular Section End -->

		<!-- Home Pages Elements End  -->

	</div>
	<!-- Main Section End -->
</template>

<style scoped>
.banner{
	display: flex;
	justify-content:space-between;
}
.listing-main-search {
	width: 70% !important;
}
.block-search{
	display:flex;
}
.search-icon{
	font-size: 50px;
	background-color:#fff;
	padding:5px 20px;
	border-radius: 10px 0px 0px 10px;
	margin-right: 2px;
	color:#FE2526;
}
.search-input{
	width: 100%;
	display: inline-block;
	vertical-align: top;
	background-color:#fff;
	border-radius: 0px 10px 10px 0px;
	margin-right: 10px;
}
.search-input input[type="text"]{
	border-radius: 10px;
	font-size: 18px;
	height: 60px;
	line-height: 60px;
}
.btn-search{
	background-color:#00F0B6;
	font-size: 18px;
	color: #fff;
	border-radius: 10px;
	border: 0;
	font-weight: bold;
	padding:0px 30px;
}
@keyframes floating {
	0% {
		transform: translateY(-5%) rotate(
			3deg
			);
	}
	65% {
		transform: translateY(5%) rotate(
			-3deg
			);
	}
	100% {
		transform: translateY(-5%) rotate(
			3deg
			);
	}
}
.banner img{
	width: 150px;
	animation: floating 8s ease-in-out infinite;
	will-change: transform;
}
.img-4 {
	margin-top: -40px;
	margin-right: auto;
	margin-left: auto;
}
.store-btn{
	display:flex;
	flex-wrap: wrap;
}
.store-btn img{
	width: 250px;
	margin-bottom: 10px;
	margin-right: 20px;
}
@media screen and (max-width: 1000px){
	.container-links {
		padding-top: 50px !important;
		padding-bottom: 50px !important;
	}
}
@media screen and (max-width: 760px){
	.container-links {
		padding-top: 80px !important;
		padding-bottom: 50px !important;
	}
	.listing-main-search {
		width: 90% !important;
	}
}
@media screen and (max-width: 560px){
	.listing-main-search {
		width: 100% !important;
	}
	.btn-search{
		font-size: 18px;
		border-radius: 10px;
		padding:0px 13px;
	}
}
</style>

<script>
// @ is an alias to /src
import Ad from "@/components/Ad.vue";

export default {
	name: 'Index',

	components: {  Ad },

	data(){
		return{

		}
	},

	methods: {

	}

}
</script>