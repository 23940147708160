<template>
  <div>
    <section-forms :title="title"></section-forms>
    <div class="hiddendiv">

    </div>
    <div class="container conditions">
<center><em style="text-decoration: underline;">Términos y Condiciones – ZPEDIDOS</em> </center><br/>
Los presentes Términos y Condiciones de servicio regulan la relación contractual entre los usuarios (en adelante, “Usuarios”), con ZPEDIDOS. El servicio se encuentra dirigido exclusivamente a residentes en la República de Perú. Los Usuarios se encontrarán sujetos a los Términos y Condiciones Generales respectivos, junto con todas las demás políticas y principios que rigen ZPEDIDOS y que son incorporados al presente por referencia. <br><br>

EL USUARIO DECLARA HABER LEÍDO Y ENTENDIDO TODAS LAS CONDICIONES ESTABLECIDAS EN LAS POLÍTICAS DE PRIVACIDAD Y LOS PRESENTES TÉRMINOS Y CONDICIONES GENERALES, Y MANIFIESTA SU CONFORMIDAD Y ACEPTACIÓN AL MOMENTO DE REGISTRARSE Y/O HACER USO DEL APLICATIVO “ZPEDIDOS”. CUALQUIER PERSONA QUE NO ACEPTE O SE ENCUENTRE EN DESACUERDO CON ESTOS TÉRMINOS Y CONDICIONES GENERALES, LOS CUALES TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR EL SITIO Y/O EL APLICATIVO.<br><br>

La plataforma de ZPEDIDOS es una herramienta tecnológica que, haciendo uso de internet, facilita la intermediación entre repartidores independientes (en adelante los, “Zetas”) y los Usuarios que requieren del servicio de reparto mediante el uso de una plataforma tecnológica y móvil de ZPEDIDOS(en adelante, la “Aplicación”) entendiéndose dicha operación a los efectos de los presentes Términos y Condiciones como el  “Servicio de Reparto”, el cual es ejecutado a través de un contrato de mandato, entre el Zeta y el usuario . ZPEDIDOS actúa en todo momento como intermediario entre Zetas y Usuarios.  Bajo ninguna circunstancia los Zetas serán considerados empleados por ZPEDIDOS ni por ninguno de sus afiliados. Los ZETAS prestan el Servicio por cuenta y riesgo propio y liberan a los Usuarios de cualquier responsabilidad que pudiera surgir durante la prestación del Servicio de Reparto.<br><br>

El Registro<br><br>
El acceso a la Aplicación es gratuito, salvo en lo relativo al costo de la conexión a través de la red de telecomunicaciones suministrada por el proveedor de acceso contratado (ISP) por el Usuario, que estará a su exclusivo cargo. El Usuario únicamente podrá acceder a la Aplicación a través de los medios autorizados.<br><br>

Para el acceso a los contenidos de la Aplicación será necesario el registro del Usuario. Por ello, para acceder a los servicios de reparto prestados por los Zetas, el Usuario deberá contar con un Smartphone con sistema operativo IOS o Android y completar todos los campos del formulario de inscripción correspondiente a los Usuarios con datos válidos (en adelante, el “Usuario Registrado” o en plural “Usuarios Registrados“). Quien aspire a convertirse en Usuario Registrado deberá verificar que la información que pone a disposición de RAPPI sea exacta, precisa y verdadera (en adelante, los “Datos Personales“). Asimismo, el Usuario Registrado asumirá el compromiso de actualizar sus Datos Personales cada vez que los mismos sufran modificaciones. ZPEDIDOS podrá utilizar diversos medios para identificar a los Usuarios Registrados, pero ZPEDIDOS no se responsabiliza por la certeza de los Datos Personales que sus Usuarios Registrados pongan a su disposición. Los Usuarios Registrados garantizan y responden, en cualquier caso, por la veracidad, exactitud, vigencia y autenticidad de los Datos Personales puestos a disposición de ZPEDIDOS.<br><br>

La Cuenta es personal, única e intransferible, y está prohibido que un mismo Usuario Registrado registre o posea más de una Cuenta. En caso que RAPPI detecte distintas Cuentas que contengan datos coincidentes o relacionados, podrá cancelar, suspender o inhabilitarlas. El Usuario será el único responsable por el uso de su Cuenta.<br><br>

Los Datos Personales introducidos por el Usuario Registrado deberán ser exactos, actuales y veraces en todo momento. ZPEDIDOS se reserva el derecho de solicitar algún comprobante y/o dato adicional a efectos de corroborar los Datos Personales, y de suspender temporal y/o definitivamente a aquel Usuario Registrado cuyos datos no hayan podido ser confirmados.<br><br>

Condiciones Generales del Uso del Servicio de Reparto<br><br>
 El Servicio de Reparto que brindarán los Zetas se encuentra dirigido exclusivamente para el uso de los Usuarios Registrados.<br><br>

 La solicitud de un Servicio de Reparto, sea en forma inmediata o bajo reserva a través de la Aplicación podrá abonarse mediante pago efectivo, transferencias interbancarias, yape, lukita, tunki.<br><br>

El Usuario Registrado podrá encontrar en la Aplicación un catálogo de productos ofrecidos por distintos comercios (en adelante, los “Comercios Aliados”), quienes se desempeñan como proveedores independientes y utilizan la Aplicación como medio para ofrecer sus productos.<br><br>

Sin perjuicio de ello, el Usuario Registrado podrá solicitar a un Zeta el envío de un producto ofrecido por cualquier otro comercio diferente a los Comercios Aliados, siempre y cuando dicho producto respete los presentes términos y condiciones. En este supuesto, el Usuario Registrado deberá abonar adicionalmente al precio del producto, el valor del Servicio de Reparto y una comisión sobre el valor del producto por el uso de la plataforma virtual el cual deberá aceptar el Usuario Registrado al momento de realizar la orden.<br><br>

ZPEDIDOS se reserva la posibilidad de bloquear al Usuario Registrado, por cuestiones de seguridad ante posibilidades de fraude, estafa, uso de datos ajenos, o algún otro supuesto que se desprenda como infracción de los presentes términos y condiciones de uso de la Aplicación. Dicha situación será debidamente comunicada al Usuario Registrado.<br><br>

ZPEDIDOS podrá rechazar y/o frenar cualquier pedido de los Usuarios Registrados, en caso de identificar una infracción al presente Términos y Condiciones.<br><br>

Cualquier cambio en el Servicio de Reparto será informado al Usuario Registrado al teléfono de contacto informado.<br><br>

El Zeta cumplirá el Servicio de Reparto entregando la mercadería a la persona que surge de los Detalles de Entrega, siendo el Usuario Registrado el único responsable por la veracidad de los datos allí introducidos.<br><br>

Se encuentra totalmente prohibido utilizar el Servicio de Reparto, para transportar mercadería con fines ilícitos, ilegales, contrarios a lo establecido en los presentes Términos y Condiciones, a la buena fe y al orden público, lesivos de los derechos e intereses de terceros incluyendo, sin limitación, el transporte de material ilegal, que constituya un peligro para la salud o integridad de las personas, o con fines fraudulentos.<br><br>

Condiciones Particulares del Uso del Servicio de Reparto<br><br>
Una vez que el Usuario Registrado haya incorporado en la Aplicación todos los datos necesarios para efectuar la búsqueda y/o solicitud de un Servicio de Reparto, y declarado haber aceptado expresamente estos Términos y Condiciones, ZPEDIDOS informará el resumen del Servicio de Reparto en donde constará el precio total a abonar por el Usuario Registrado.<br><br>

Efectuada la solicitud y/o reserva de Servicio de Reparto antedicha, inmediatamente quedará confirmada la misma.<br><br>

Condiciones de Pago y Facturación<br><br>
Las tarifas aplicables al Servicio de Reparto las fijará ZPEDIDOS conforme a la demanda de servicios que se encuentre activa en ese momento al momento en que el Usuario Registrado haga uso de la Aplicación. El Usuario registrado acepta que sea ZPEDIDOS quien fije estas tarifas. Estas tarifas serán cobradas directamente por el Zeta de forma automática, sin que exista retención alguna por parte de ZPEDIDOS, mediante transferencias o, en su defecto, personalmente cuando el pago se realice en dinero en efectivo. Las tarifas cobradas por el Servicio de Reparto no serán reembolsables.<br><br>

Para los casos en los que el Servicio de Reparto sea pagado por el Consumidor al Zeta en efectivo y el monto entregado por el Consumidor sea mayor a la tarifa cobrada por el mencionado servicio, el Zeta deberá cumplir con entregarle al Consumidor aquel monto sobrante existente entre el monto entregado como pago y la tarifa establecida por ZPEDIDOS.<br><br>

En el caso de los Comercios no Aliados, ZPEDIDOS se reserva el derecho de exhibir un precio de los productos por encima del valor real encontrado en el establecimiento y el Usuario Registrado acepta pagar los productos con este valor adicional, por el uso de la plataforma virtual, teniendo en cuenta que el precio exhibido en la plataforma será el precio final a ser cobrado.<br><br>

En ese mismo sentido, ZPEDIDOS se reserva el derecho de incrementar una comisión, los precios de los productos exhibidos en las tiendas físicas de los Comercios Aliados y de los Comercios no Aliados. El Usuario Registrado reconoce y acepta la anterior condición e igualmente tampoco realizará ningún tipo de reclamación frente a ZPEDIDOS por cobros adicionales a los precios que se vean reflejados en facturas de venta. Los valores adicionales serán cobrados a título de uso de la plataforma virtual.<br><br>

Finalizado el Servicio de Reparto, ZPEDIDOS enviará una notificación al Usuario Registrado con el resumen del trayecto realizado.<br><br>

Uso de la Aplicación<br><br>
 ZPEDIDOS tendrá las facultades para denegar o restringir el uso de la Aplicación a cualquier Usuario Registrado en caso de incumplimiento de los presentes Términos y Condiciones, sin que ello genere perjuicio alguno al Usuario Registrado. ZPEDIDOS no será responsable si el Usuario Registrado no cuenta con un teléfono celular inteligente compatible con el uso de la Aplicación. El Usuario Registrado se compromete a hacer un uso adecuado y lícito de la Aplicación de conformidad con la legislación aplicable, los presentes Términos y Condiciones, la moral y buenas costumbres generalmente aceptadas y el orden público. Al utilizar la Aplicación o el Servicio, el Usuario Registrado acuerda que:<br><br>

 Solo utilizará el Servicio para su uso personal y no tendrá facultades para revender su Cuenta a un tercero.<br><br>

No autorizará a terceros a usar su Cuenta.<br><br>

No cederá ni transferirá de otro modo su Cuenta a ninguna otra persona o entidad legal.<br><br>

No utilizará una cuenta que esté sujeta a cualquier derecho de una persona que no sea ella sin la autorización adecuada.<br><br>

No solicitará el Servicio con fines ilícitos, ilegales, contrarios a lo establecido en los presentes Términos y Condiciones, a la buena fe y al orden público, lesivos de los derechos e intereses de terceros incluyendo, sin limitación, el transporte de material ilegal o con fines fraudulentos.<br><br>

No tratará de dañar el Servicio o la Aplicación de ningún modo, ni accederá a recursos restringidos en la Aplicación.<br><br>

Guardará de forma segura y confidencial la contraseña de su Cuenta y cualquier identificación facilitada para permitirle acceder al Servicio y la Aplicación.<br><br>

No utilizará el Servicio o la Aplicación con un dispositivo incompatible o no autorizado.<br><br>

No intentará acceder, utilizar y/o manipular los datos de ZPEDIDOS, Zetas y otros Usuarios.<br><br>

No introducirá ni difundirá virus informáticos o cualesquiera otros sistemas físicos o lógicos que sean susceptibles de provocar daños en la Aplicación.<br><br>

Devolución de productos
En caso de que el Usuario Registrado desee proceder a la devolución de un producto o desee realizar una reclamación acerca de la realización de un servicio, sin perjuicio de que ZPEDIDOS, como intermediario, asuma la gestión de la misma, el responsable final siempre será el Comercio Aliado en el cual se realizó la compra. En caso de reembolso del importe de la compra al Usuario Registrado, será el Comercio Aliado el que tendrá potestad para decidir el método de devolución (tarjeta de crédito, vale de compra…) con independencia de que sea ZPEDIDOS el que por cuenta del Comercio Aliado local proceda a la devolución. Por lo tanto, la devolución de un producto adquirido por el Zeta en ejecución del mandato conferido por el Usuario Registrado quedará sujeta en todo caso a la política de devoluciones del Comercio Aliado.<br><br>

En estos supuestos, el Usuario Registrado deberá contactar con el servicio de atención al cliente de ZPEDIDOS, a través de los canales presentes en la Aplicación, con la finalidad de obtener la ayuda y soporte necesarios.<br><br>

En el caso que el Usuario Registrado quiera tramitar la devolución de algún producto por no adecuarse a lo que ha solicitado a través de la Aplicación, el Usuario Registrado deberá aportar una fotografía del pedido total junto con un listado de los productos incorrectos o que no se hayan entregado, así como otras evidencias que demuestren la no adecuación del producto solicitado.<br><br>

El Usuario Registrado deberá comprobar los productos que el Zeta le entregue en la dirección de entrega.<br><br>

En todo caso, será potestad del Comercio Local determinar en cada caso la procedencia de la devolución por lo que, en caso de disputa, el Usuario Registrado deberá ponerlo en conocimiento de ZPEDIDOS a través de los medios facilitados por ZPEDIDOS a tal efecto.<br><br>

Cancelaciones y Penalidades
Una vez emitida la Reserva, el Usuario Registrado podrá cancelarla (en adelante, la “Cancelación”) sin penalidad alguna siempre que realice la Cancelación del Servicio de Reparto a través de la Aplicación, dentro de los primeros 5 minutos posteriores a la aceptación del servicio por parte de ZPEDIDOS.<br><br>

De no efectuarse la Cancelación en el tiempo estipulado en el punto precedente, el Usuario estará obligado a abonar el 100% del monto correspondiente al pago del Servicio de Reparto, sin perjuicio de que luego el Comercio Aliado y/o el comercio correspondiente esté obligado a restituir el precio abonado por el Usuario Registrado por el producto, según sea el caso.<br><br>

El Usuario Registrado si no realiza la cancelación, ZPEDIDOS podra bloquearlo y no podra realizar otro pedido hasta la cancelación de la deuda pendiente.<br><br>

El Usuario en ningún caso podrá alegar falta de conocimiento de las limitaciones, restricciones y penalidades, dado que las mismas son informadas en forma previa a realizar la solicitud del Servicio de Reparto a través de la Aplicación.<br><br>

Compra de bebidas alcohólicas
Los Usuarios Registrados que realicen un pedido que incluya la adquisición y/o entrega de bebidas alcohólicas a través de la Aplicación deben ser mayores de edad, habiendo cumplido los 18 años. Al realizar un pedido que incluya bebidas alcohólicas, el Usuario Registrado confirma que tiene al menos 18 años de edad. ZPEDIDOS se reserva el derecho de negarse a permitir el pedido de compra y/o entrega de alcohol a cualquier persona que no pueda probar tener al menos 18 años de edad.<br><br>

La presente cláusula será de idéntica aplicación a cualquier otro producto y/o servicio análogo reservado a la mayoría de edad según legislación vigente y que sea solicitado por un Usuario Registrado a través de la Aplicación.<br><br>

Responsabilidad
El Usuario Registrado conoce y acepta que ZPEDIDOS pone a disposición del Zeta sólo un espacio virtual que, en calidad de intermediador, les permite ponerse en comunicación mediante la Aplicación para que el Zeta pueda brindar los Servicios de Reparto a favor de los Usuarios Registrados. ZPEDIDOS no interviene en el perfeccionamiento de las operaciones, actividades o servicios realizados por el Zeta y a favor del Usuario Registrado. Por ello, el Zeta o el Comercio (Aliado o no Aliado) serán responsables respecto de la calidad, cantidad, estado, integridad o legitimidad de la mercadería transportada por el Zeta. Además, el Zeta será responsable de de su capacidad para contratar y la veracidad de los Datos Personales que haya ingresado<br><br>

El Usuario Registrado conoce y acepta que al realizar operaciones con el Zeta lo hace bajo su propio riesgo. ZPEDIDOS no será responsable por lucro cesante, o por cualquier otro daño y/o perjuicio que haya podido sufrir el Usuario Registrado o el Zeta, debido al Servicio de Reparto prestado por el Zeta, que no esté bajo la esfera de control de ZPEDIDOS o respecto de daños y/o perjuicios atribuibles a un tercero.<br><br>

ZPEDIDOS recomienda actuar con prudencia y sentido común al momento de contratar el Servicio de Reparto. En caso que uno o más Usuarios Registrados o algún tercero inicien un reclamo o acción legal contra un Zeta por actos no atribuibles a ZPEDIDOS conforme a las reglas establecidas en estos Términos y Condiciones, todos y cada uno de los involucrados en dichos reclamos o acciones eximen de responsabilidad a ZPEDIDOS y a sus directores, gerentes, empleados, agentes, operarios, representantes y apoderados.<br><br>

ZPEDIDOS no verificará los productos que puedan recogerse o enviarse a través de los pedidos realizados a través de la Aplicación. Por esta razón, tanto el Usuario Registrado como el Zeta eximen a ZPEDIDOS de cualquier responsabilidad que pudiera derivarse de la puesta a disposición y/o transporte de productos que requieran permisos o licencias específicos, así como productos prohibidos por cualquier normativa de aplicación. En el supuesto que ZPEDIDOS tenga conocimiento que el Usuario Registrado y/o el Zeta utilizan la APP para el transporte de productos prohibidos, ZPEDIDOS podrá iniciar una investigación interna para esclarecer los hechos, interponer las medidas legales necesarias y/o bloquear al Usuario Registrado o Zeta correspondiente hasta la finalización de la posible investigación. Entre las medidas a disposición de ZPEDIDOS se encuentra el poner a disposición de las autoridades los datos e informaciones de los Usuarios Registrado y Zetas.<br><br>

Uso y Garantía de la Aplicación
ZPEDIDOS informa a sus Usuarios Registrados que la Aplicación podría presentar limitaciones de disponibilidad y continuidad en su funcionamiento, por razones de mantenimiento o acciones que escapen al control y manejo de ZPEDIDOS. En estos casos, ZPEDIDOS hará todo lo que se encuentre razonablemente a su alcance para retomar el adecuado funcionamiento de la Aplicación. Sin embargo, hace de conocimiento de los Usuarios Registrados para que tomen las precauciones correspondientes, siendo que ZPEDIDOS no será responsable por eventuales daños y/o perjuicios que puedan derivarse de: (i) la falta de disponibilidad o accesibilidad a la Aplicación por las razones antes expuestas; (ii) la interrupción en el funcionamiento de la Aplicación o fallos informáticos ajenos al control de ZPEDIDOS, averías telefónicas, desconexiones, retrasos o bloqueos causados por deficiencias o sobrecargas en las líneas telefónicas, centros de datos, en el sistema de Internet o en otros sistemas electrónicos, producidos en el curso de su funcionamiento; y, (iii) otros daños que puedan ser causados por terceros mediante intromisiones no autorizadas ajenas al control de ZPEDIDOS.<br><br>

ZPEDIDOS adopta las medidas de seguridad necesarias y razonables para el funcionamiento de la Aplicación. Sin embargo, estas medidas no necesariamente aseguran la ausencia de virus ni de otros elementos en la Aplicación, introducidos por terceros, ajenos al control de ZPEDIDOS, que puedan producir alteraciones en los sistemas físicos o lógicos del Usuario Registrado o en los documentos electrónicos y ficheros almacenados en sus sistemas. En consecuencia, ZPEDIDOS no será responsable de los daños y perjuicios que pudieran derivarse de la presencia de virus u otros elementos, ajenos al control de ZPEDIDOS, que puedan producir alteraciones en los sistemas físicos o lógicos, documentos electrónicos o ficheros del Usuario Registrado.8.3. ZPEDIDOS adopta diversas medidas de protección para proteger la Aplicación y los contenidos contra ataques informáticos de terceros. No obstante, ZPEDIDOS no garantiza que terceros no autorizados (utilizando ilegales o fraudulentos) no puedan conocer las condiciones, características y circunstancias en las cuales el Usuario Registrado accede a la Aplicación. En consecuencia, ZPEDIDOS no será en ningún caso responsable de los daños y perjuicios que pudieran derivarse de dicho acceso no autorizado.<br><br>

Con la suscripción de las presentes Términos y Condiciones, el Usuario que suscribe el presente documento declara que mantendrá indemne frente a cualquier reclamación a ZPEDIDOS, su sociedad matriz, directores, socios, empleados, agentes derivadas de: (i) el incumplimiento por parte del Usuario de cualquier disposición contenida las presentes en los Términos y Condiciones o de cualquier ley o regulación aplicable a las mismas; (ii) el incumplimiento o violación de los derechos de terceros incluyendo, a título meramente enunciativo, otros Usuarios, peatones; y, (iii) el incumplimiento del uso permitido de la Aplicación, siendo estas condiciones meramente enunciativas y no taxativas, por lo que el Usuario mantendrá indemne a ZPEDIDOS por cualquier otra violación normativa o daño a terceros que pueda producirse como consecuencia de la utilización del Servicio de Reparto por parte del Usuario.<br><br>

Derechos de Propiedad Intelectual e Industrial
El Usuario Registrado reconoce y acepta que todos los derechos de propiedad intelectual e industrial sobre los contenidos y/o cualesquiera otros elementos insertados en la Aplicación (incluyendo, sin limitación, marcas, logotipos, nombres comerciales, lemas comerciales textos, imágenes, gráficos, diseños, sonidos, bases de datos, software, diagramas de flujo, presentación, audio y vídeo y/o cualquier otro derecho de propiedad intelectual e industrial de cualquier naturaleza que éstos sean), pertenecen y son de propiedad exclusiva de ZPEDIDOS.<br><br>

ZPEDIDOS autoriza al Usuario a utilizar, visualizar, imprimir, descargar y almacenar los contenidos y/o los elementos insertados en la Aplicación exclusivamente para su uso personal, privado y no lucrativo, absteniéndose de realizar sobre los mismos cualquier acto de descompilación, ingeniería inversa, modificación, divulgación o suministro. Cualquier otro uso o explotación de cualesquiera contenidos y/u otros elementos insertados en la Aplicación distinto de los aquí expresamente previstos estará sujeto a la autorización previa de ZPEDIDOS.<br><br>

Bajo ningún concepto se entenderá que el acceso a la Aplicación y/o la aceptación de los Términos y Condiciones generar algún derecho de cesión a favor de los Usuarios Registrados ni de cualquier tercero.<br><br>

Protección de Datos
Los datos personales que los Usuarios Registrados proporcionen en el Registro, serán tratados según lo dispone la Ley N° 29733, Ley de Protección de Datos Personales y su Reglamento, aprobado mediante Decreto Supremo N° 003-2013-JUS y demás normas conexas. En ese sentido, ZPEDIDOS se obliga al cumplimiento estricto de las normas anteriormente mencionadas, así como a mantener los estándares máximos de seguridad, protección, resguardo, conservación y confidencialidad de la información recibida o enviada.<br><br>

Los Usuarios Registrados declaran que los datos personales han sido entregados de forma absolutamente libre y voluntaria, sin ningún tipo de presión, obligación o condición de por medio.<br><br>

Notificaciones
El Usuario Registrado declara y acepta que ZPEDIDOS podrá realizar las notificaciones oportunas al Usuario Registrado a través de la Aplicación, mensajes de texto, o la dirección de correo electrónico facilitada por el Usuario Registrado en su Cuenta, incluyendo el envío de mensajes con fines promocionales o publicitarios. El Usuario Registrado podrá notificar a ZPEDIDOS y solicitar el cese de la actividad promocional o publicitaria mediante una solicitud a través de la sección de Soporte disponible en la plataforma virtual.<br><br>

Ley Aplicable y Jurisdicción
Las presentes Términos y Condiciones, así como la relación entre ZPEDIDOS y el Usuario, se regirán e interpretarán con arreglo a la legislación vigente en la República del Perú.<br><br>

Compra de bebidas alcohólicas
Los Usuarios que realicen un pedido que incluya la adquisición y/o entrega de bebidas alcohólicas a través de la plataforma deben ser mayores de edad, habiendo cumplido los 18 años. Al realizar un pedido que incluya bebidas alcohólicas, el Usuario confirma que tiene al menos 18 años de edad. ZPEDIDOS se reserva el derecho de negarse a permitir el pedido de compra y/o entrega de alcohol a cualquier persona que no pueda probar tener al menos 18 años de edad.<br><br>

La presente cláusula será de idéntica aplicación a cualquier otro producto y/o servicio análogo reservado a la mayoría de edad según legislación vigente y que sea solicitado por un Usuario a través de la Plataforma.<br><br>
    </div>
  </div>
</template>
<script>
import SectionForms from '@/components/SectionForms.vue'
export default {
  data(){
    return{
      title: "Terminos y Condiciones"
    }
  },
  components: {SectionForms},
  mounted(){
    this.goMainSectionMixin();
  },
}
</script>
<style scoped>
  .conditions{
    position: relative;
    padding-top: 3rem;
    z-index: 1;
  }
  .hiddendiv{
    position: absolute;
    right: 0;
    background: #ebedf3;
    height: 100px;
    width: 100rem;
  }
</style>