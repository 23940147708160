<template>
  <!-- Main Section Start -->
  <div class="main-section">
    <div class="page-section nopadding cs-nomargin" style="margin-top: 0px;padding-top: 60px;padding-bottom: 10px;margin-bottom: 0px;">
      <div class="container">
        <div v-if="orderVuex" class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <h1>Detalles del pedido pendiente</h1>
          </div>

          <result-order></result-order>

        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>

<script>
import ResultOrder from '@/components/ResultOrder.vue';
import { mapState } from 'vuex';

export default {
  name: 'PendingOrders',

  props: ['resultOrder'],

  components: { ResultOrder },

  created() {
    if (!this.orderVuex) {
      this.$router.push({ name: 'Index' });
    }
    this.closePendingOrders();
  },

  computed: mapState({
    orderVuex: state => state.order.orderVuex,
  }),

  methods: {
    closePendingOrders() {
      window.$("#myModalPendingOrders").modal("hide");
    },
  }

}
</script>

