
var generateSlug = {
  methods:{
    generateSlug: function(slug=""){
      slug = slug.replace(/[`~!@#$%^&*()_\-+=[\]{};:'"\\|/,.<>?\s]/g, ' ');
      slug = slug.replace(/^\s+|\s+$/gm, '');
      slug = slug.replace(/\s+/g, '-');

      return slug;
    }
  }
}

export default generateSlug;