<template>
  <div class="modal-mask" @click="close()">
    <div class="modal-wrapper">
      <div class="modal-container" style="min-height: 50rem; max-height: 90rem; width: 450px; overflow-y: auto;" @click="makenone()">
        <h3>{{ title }} - Cartas</h3>
          <div><slot name="body"></slot></div>
          <footer></footer>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'modalCarts',
  props: ["title"],
  emits: ["close"],
  methods: {
    close(){
      this.$emit("close")
    },
    makenone(){
      console.log("none")
    }
  }
}
</script>
<style scoped>
  #app {
    padding: 20px;
    text-align: center;
  }

  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: 400px;
    /* height: 400px; */
    margin: 0px auto;
    padding: 20px 30px;
    text-align: left;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
  }

  footer {
    text-align: right;
  }

  button{
    background: #c33332;
    color: white;
    border-radius: 5px;
    border-style: none; 
    margin-top: 10px;
    height: 35px;
    width: 60px;
  }
  button:hover{
    color: cornsilk;
  }
  .modal-container::-webkit-scrollbar {
     /* Tamaño del scroll en horizontal */
    display: none;  /* Ocultar scroll */
  }
</style>