<template>
  <div>
    <div class="page-section nopadding cs-nomargin" style="margin-top: 0px;padding-top: 20px;padding-bottom: 54px;margin-bottom: 0px;background: #ebedf3;">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="element-title align-center">
              <h2>¡Hagamos un cambio JUNTOS!</h2>
              <br>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div class="col-lg-4 col-sm-6 col-xs-12">
                <div class="order-sort-results">
                  <div class="text-center pd-car">
                    <br>
                    <img :src="require('@/assets/images/repartidor.png')" alt="" class="img-rounded img-hight">
                    <h2 class="h2-lg">Repartidor</h2>
                    <hr>
                    <p class="p-content">Sé tu propio jefe. Flexibilidad de horarios, ingresos muy buenos, encuentra amigos y la oportunidad de conocer tu ciudad repartiendo al aire libre. Apúntate y en menos de 24h colabora con nosotros.</p>
                    <br>
                    <router-link :to="{ name: 'repartidores'}" replace>
                    <button class="btn btn-primary btn-lg">Reparte con Nosotros</button>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-6 col-xs-12">
                <div class="order-sort-results">
                  <div class="text-center pd-car">
                    <br>
                    <img :src="require('@/assets/images/rest.png')" alt="" class="img-rounded img-hight">
                    <h2 class="h2-lg">Restaurantes</h2>
                    <hr>
                    <p class="p-content">Unete a nosotros e impulsa tu negocio, beneficiate de nuestra tecnología y nuestra base de clientes para tener la ciudad entera a tus pies, ahora solo te centraras en dar un buen servicio.</p>
                    <br>
                    <router-link :to="{ name: 'restaurantes'}">
                    <button class="btn btn-primary btn-lg">Registra tu Local</button>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-6 col-xs-12">
                <div class="order-sort-results">
                  <div class="text-center pd-car">
                    <br>
                    <img :src="require('@/assets/images/empleo.png')" alt="" class="img-rounded img-hight">
                    <h2 class="h2-lg">Empleo</h2>
                    <hr>
                    <p class="p-content">Creamos un nuevo estilo en un ámbito muy competitivo, necesitamos gente como tú. Esto requiere una gran iniciativa, pasión, mucho corazón y trabajo equipo. ¿Listo para triunfar?</p>
                    <br>
                    <router-link :to="{ name: 'empleos'}">
                    <button class="btn btn-primary btn-lg">Únete al Equipo</button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.p-content{
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}
.pd-car {
  padding: 15px;
}
.h2-lg {
  font-size: 35px;
  padding-top: 20px;
}
.img-hight {
  width: 200px;
  height: 200px;
}
</style>

<script>
export default {
  name: 'Ad',

}
</script>
