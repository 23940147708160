<template>
  <div>
    <!-- <section-forms :title="title"></section-forms> -->
    <div class="form__container">
      <div class="form__container--div">
        <div class="formData">
          <h4 class="h4__title" for="">¡Gana dinero entregando pedidos!</h4><br>
          <div class="col-12 input-effect">
            <input v-model="form.nombres" class="input__field effect-16 input-style" :class="{'has-error' : formError.nombres.error, 'has-content' : hasTextOnInputMixin(form.nombres)}" type="text" placeholder=""
              maxlength="100" required>
            <label class="effect-label">Nombre</label>
            <span class="focus-border"></span>
          </div>
          <div class="col-xs-12" v-if="formError.nombres.error">
						<span class="text-danger" ole="alert">Ingrese su nombre Correctamente</span>
					</div>
          <div class="col-12 input-effect">
            <input v-model="form.apellidos" class="input__field effect-16 input-style" :class="{'has-error' : formError.apellidos.error, 'has-content' : hasTextOnInputMixin(form.apellidos)}" type="text" placeholder=""
             maxlength="100" required>
            <label class="effect-label">Apellidos</label>
            <span class="focus-border"></span>
          </div>
          <div class="col-xs-12" v-if="formError.apellidos.error">
						<span class="text-danger" ole="alert">Ingrese sus Apellidos Correctamente</span>
					</div>
          <div class="col-12 input-effect">
            <input v-model="form.correo" class="input__field effect-16 input-style" :class="{'has-error' : formError.correo.error, 'has-content' : hasTextOnInputMixin(form.correo)}" type="email" placeholder=""
            maxlength="150" required>
            <label class="effect-label">correo</label>
            <span class="focus-border"></span>
          </div>
          <div class="col-xs-12" v-if="formError.correo.error">
						<span class="text-danger" ole="alert">Ingrese su correo Correctamente</span>
					</div>
          <div class="col-12 input-effect">
            <input v-model="form.telefono" class="input__field effect-16 input-style" :class="{'has-error' : formError.telefono.error, 'has-content' : hasTextOnInputMixin(form.telefono)}" type="number" placeholder=""
            maxlength="30" required>
            <label class="effect-label">Numero de telefono</label>
            <span class="focus-border"></span>
          </div>
          <div class="col-xs-12" v-if="formError.telefono.error">
						<span class="text-danger" ole="alert">Ingrese su número Correctamente</span>
					</div>
          <select v-model="form.codciudad" class="input__field effect-16" :class="{'select_cities' : sendFormD}" name="Ubication" id="Ubication" @click="applystyle()">
            <option :value="city.codciudad" v-for="city in Cities" :key="city.codciudad">{{ city.nombre }}</option>
          </select>
          <div class="conditions__container">
            <input v-model="form.terminosycondiciones" class="checkbox__conditions" :class="{'has-error' : formError.terminosycondiciones.error}" type="checkbox" required :checked="isAcept">
            <span class="span__text--terminos" @click="aceptContitions()">He leído y acepto los <router-link :to="{ name: 'terminosycondiciones'}"><span style="color: black;">Términos y condiciones</span></router-link></span><br>
          </div>
          <div class="col-xs-12" v-if="formError.terminosycondiciones.error">
						<span class="text-danger" ole="alert">acepte los terminos y condiciones</span>
					</div>
          <div class="container__send">
            <button class="btn btn-success btn__send--data" type="submit" @click="sendData()">Enviar</button>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="container2">
        <div class="container_text-1">
          <h2 class="title__h2">Recibe buenos ingresos</h2>
          <p>Gana dinero con cada entrega que hagas, no tienes límite de ganancias. Mientras mas pedidos lleves, mayor será tu ganancia. Obtén los ingresos que necesitas para cumplir todas tus metas.</p>
        </div>
        <div class="container_text-2">
          <h2 class="title__h2">Pasión orgullo y voluntad</h2>
          <p>Nada nos detiene, somos muy apasionados por lo que hacemos y queremos que todos compartan esta misma energía. Somos parte importante de la sociedad más aún ahora, entre todos estamos construyendo un mejor Perú.</p>
        </div>
      </div>
    </div>
  </div>

</template>
<style scoped src="@/css/style-inputs.css"></style>
<script>
/* import SectionForms from '@/components/SectionForms.vue' */
export default {
  data(){
    return{
      title: 'Repartidores',
      form: {
        nombres: '',
        apellidos: '',
        correo: '',
        telefono: '',
        terminosycondiciones: false,
        codciudad:'1',
      },
      isAcept: false,
      Cities: [],
      formError: {
        nombres: {
          error: false
        },
        apellidos: {
          error: false
        },
        correo: {
          error: false
        },
        telefono: {
          error: false
        },
        terminosycondiciones: {
          error: false
        },
      },
      sendFormD: false,
    }
  },
  /* components: { SectionForms }, */

  created() {
    this.getCities();
  },

  mounted(){
    this.goMainSectionMixin();
  },

  methods: {
    applystyle(){
      this.sendFormD = true;
    },
    sendData () {
      if(this.validateForm()){
        this.$swal.fire({
              title: '¿Enviar Formulario?',
              text: "¡asegurate que tus datos esten correctos!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Si, Enviar!'
            }).then((result) => {
              if (result.isConfirmed) {
                this.datasend();
              }
            });
      }
    },

    datasend(){
      this.sendFormNewZtasMixin(this.form).then(res => {
          if(res.status ==201){
            this.resetFields()
            this.$swal.fire(
            'Enviado!',
            'Su solicitud ha sido enviado.',
            'success'
            );
            this.successToastrMixin("Solicitud Exitosa", "pronto se comunicaran con usted...")
          }else{
            this.errorToastrMixin("fallo el envio", "intentelo de nuevo")
            console.log(res)
          }
        })
    },

    getCities(){
      this.getCitiesMixin().then(res =>{
        this.Cities = res.data
      })
    },

    resetFields(){
      this.form = {
        nombres: '',
        apellidos: '',
        correo: '',
        telefono: '',
        codciudad:'1',
        terminosycondiciones:false
      }
      this.sendFormD = false;
    },

    aceptContitions(){
      this.isAcept = !this.isAcept;
      this.form.terminosycondiciones = !this.form.terminosycondiciones
    },

    validateForm(){
      this.form.nombres == '' ? this.formError.nombres.error= true : this.formError.nombres.error= false;
      this.form.apellidos == '' ? this.formError.apellidos.error= true : this.formError.apellidos.error= false;
      this.form.correo == '' || !this.form.correo.includes('@') && !this.form.correo.includes('.') ? this.formError.correo.error= true : this.formError.correo.error= false;
      this.form.telefono == '' ? this.formError.telefono.error= true : this.formError.telefono.error= false;
      this.form.terminosycondiciones ? this.formError.terminosycondiciones.error = false: this.formError.terminosycondiciones.error = true;
      if(!this.formError.nombres.error && !this.formError.apellidos.error && !this.formError.correo.error && !this.formError.telefono.error && !this.formError.terminosycondiciones.error){
        return true;
      }else{
        return false;
      }
    }
  }

}
</script>
<style scoped>
.form__container{
  min-height: 500px;
  width: 100%;
  z-index: 1;
}
.form__container--div{
  display: flex;
  width: 100%;
  background: white;
}
.formData{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.input__field{
  /* width: 450px; */
  width: 96%;
  height: 60px;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 2%;
}
.h4__title{
  color: #4c5267 !important;
  padding-left: 2%;
  padding-top: 2%;
  text-align: center;
  font-weight: 500 !important;
  /* margin: 10px; */
}
.conditions__container{
  display: flex;

}
.checkbox__conditions{
  margin-left: 2%;
}
.span__text--terminos{
  color: #4c5267 !important;
  margin-left: 5px;
}
.container__send{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
.btn__send--data{
  width: 150px;
  justify-content: center;
  align-items: center;
}
.container2{
  display: flex;
  flex-direction: column;
  color: #4c5267 !important;
  margin-bottom: 1rem;
}
.container_text-1{
  text-align: left;
  padding: 10px;
  color: #4c5267 !important;
  height: 20rem;
  width: 100%;
}
.container_text-2{
  text-align: left;
  padding: 10px;
  color: #4c5267 !important;
  height: 20rem;
  width: 100%;
  margin-bottom: 1rem;
}
.title__h2{
  color: #4c5267 !important;
  font-weight: 1%;
}
.select_cities{
  border-bottom: 1px solid;
  border-bottom-color: #3399ff;
}
@media(min-width: 700px){
  .form__container--div{
    display: flex;
    width: 50%;
    background: white;
    margin-left: 2%;
    border-radius: 10px;
    margin-top: 50px;
  }
  .form__container{
    position: relative;
    min-height: 800px;
    padding-top: 2%;
    background: url('../../assets/images/imgsForms/zdelivery.jpg') no-repeat;
    background-size: cover;
    z-index: 1;
  }
}
@media(min-width: 1300px){
  .form__container--div{
    width: 33%;
    margin-left: 2%;
    border: 2px solid whitesmoke;
  }
  .container2 {
    flex-direction: row;
    margin-top: 3rem;
  }
}

</style>