import axios from 'axios'
import configs from '@/utils/configs'

var apiOrderMixin = {

	computed: {

	},

	methods: {
		createOrderMixin(formOrder) {
			const apiURL = configs.apiUrl + '/pedidos/version/2';
			return axios.post(apiURL, formOrder, {
				headers: {'Authorization': 'bearer ' + this.$store.state.user.token}
			});
		},

		getUserPendingOrdersMixin(codusuario) {
			const apiURL = configs.apiUrl + '/pedidos/pendientesusuario/' + codusuario;
			return axios.get(apiURL, {
				headers: {'Authorization': 'bearer ' + this.$store.state.user.token}
			});
		},

		getLast5OrdersMixin(codusuario) {
			const apiURL = configs.apiUrl + '/pedidos/historialusuario/' + codusuario;
			return axios.get(apiURL, {
				headers: {'Authorization': 'bearer ' + this.$store.state.user.token}
			});
		},

		qualifyOrderMixin(form) {
			const apiURL = configs.apiUrl + '/calificaciones';
			return axios.post(apiURL, form, {
				headers: {'Authorization': 'bearer ' + this.$store.state.user.token}
			});
		}
	}
};

export default apiOrderMixin;