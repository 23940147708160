const getDefaultState = () => {
	return {
		products: [],
		costShipping: 0.00,
		distance: 0,
		rainCharge: 0.00,
		discountAmount: 0.00,
		costProducts: 0.00,
		total: 0.00,
		countProducts: 0,
		// payment
		paymentMethod: '',
		customerCash: '0',
		deliverygratis: ''
	}
}

export default {
	namespaced: true,

	state: getDefaultState(),

	getters: {
		getCostProducts(state) {
			state.costProducts = 0.00;
			for (let i = 0; i < state.products.length; i++) {
				state.costProducts = state.costProducts + (state.products[i].precio * state.products[i].count);
			}
			return state.costProducts;
		},

		getTotal(state) {
			state.total = state.costProducts + state.costShipping + state.rainCharge - state.discountAmount;
			return state.total;
		},

		getCountProducts(state) {
			return state.products.length;
		}
	},

	mutations: {
		setProducts(state, products) {
			state.products = products;
		},

		calculateCostProducts(state) {
			state.costProducts = 0.00;
			for (let i = 0; i < state.products.length; i++) {
				state.costProducts = state.costProducts + (state.products[i].precio * state.products[i].count);
			}
		},

		setDistance(state, distance) {
			state.distance = distance;
		},

		setCostShipping(state, costShipping) {
			state.costShipping = costShipping;
		},

		clearDistanceAndCostShipping(state) {
			state.distance = 0;
			state.costShipping = 0.00;
		},

		setRainCharge(state, rain) {
			if (rain === 1) {
				state.rainCharge = 1.00;
			}
		},

		setDiscountAmount(state, discount) {
			state.discountAmount = discount;
		},

		setPaymentMethod(state, paymentMethod) {
			state.paymentMethod = paymentMethod;
		},

		setCustomerCash(state, customerCash) {
			state.customerCash = customerCash;
		},

		setDeliverygratis(state, deliverygratis) {
			state.deliverygratis = deliverygratis;
		},

		resetState (state) {
			Object.assign(state, getDefaultState())
		}
	},

	actions: {
		resetShoppingCartState({ commit }) {
			commit('resetState');
		}
	}
}