<template>
  <div id="divcontentproducts">
    <h5 v-if="products.length>0">Tus productos</h5>

    <div v-for="(product, p) in products" :key="p" class="container-product">
      <div class="pull-right">
        <span class="category-price">S/. {{ parseFloat(product.precio).toFixed(2) }}</span>
      </div>

      <div class="align-div">
        <div class="pull-left" style="width: 25px;">
          <p>
            <span>{{ product.count }}</span><span v-show="false">{{ getTotal }}</span> x
          </p>
        </div>
        <div class="body-text-center">
          <span><strong>{{ product.titulo }}</strong></span><br>
          <span v-if="product.descripcion" v-snip="3">{{ product.descripcion }}</span>
          <span class="text-color-red" v-if="product.nota">Nota: {{ product.nota }}</span>
        </div>
      </div>

      <div>
        <div class="pull-right">
          <a v-on:click.prevent="addProduct(product)" href="">
            <span class="dot">
              <p class="icon-size">
                <i class="fas fa-plus-circle" style="color: #FE2526;" data-toggle="modal" :data-target="verifyChange ? '#alertModal' : ''"></i>
              </p>
            </span>
          </a>
        </div>
        <div>
          <a v-on:click.prevent="subtractProducts(product, p)" href="">
            <span class="dot">
              <p class="icon-size">
                <i class="fas fa-minus-circle" style="color: #FE2526;" data-toggle="modal" :data-target="verifyChange ? '#alertModal' : ''"></i>
              </p>
            </span>
          </a>
        </div>
      </div>
    </div>

    <div class="modal fade" id="alertModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" id="myModalLabel">Ha cambiado de empresa</h4>
          </div>
          <div class="modal-body">
            ¿Desea continuar aqui?<br>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal" data-toggle="modal" data-target="#extras-0-1" @click="aceptClearBusiness()">Aceptar</button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="products.length == 0" class="text-center" style="padding: 20px 0px 20px 0px;">
      <i class="fas fa-shopping-cart icon-shopping"></i>
      <p><strong>Tu canasta está vacía</strong></p>
      <p class="p-message-empty">Te invitamos a volver a nuestras tiendas y agregar productos a tu canasta</p>
    </div>
  </div>
</template>

<style scoped>
.text-color-red {
  color: #FE2526;
}
.p-message-empty{
  font-family: "Poppins", sans-serif;
}
/*new list*/
.icon-size {
  font-size:  20px;
}
.container-product {
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.2);
  background-color: #fff;
  margin-bottom: 15px;
  padding: 15px 12px;
}
.align-div {
  display:flex;
  align-items: center;
}
.body-text-center {
  margin-left: 10px;
  width: 100%;
  margin-right: 10px;
}
/*End new list*/
.dot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
}
.center {
  margin: auto;
  width: 50%;
}
.icon-shopping {
  color: #c33332 !important;
  font-size: 30px;
}
/* shopping cart*/
.mr-7 {
  margin-right: 72px !important;
}
.operationsAdd {
  color: white !important;
  margin-top: 0px;
  margin-left: 4px;
  font-size: 20px;
}
.operationsSubt {
  color: white !important;
  margin-top: 0px;
  margin-left: 8px;
  font-size: 20px;
}
.add_count {
  font-size: 15px;
  padding-left: 7px;
}
/* shopping cart End*/
/* checkout*/
.mr-7-checkout {
  margin-right: 80px !important;
}
.operationsAdd-checkout {
  color: white !important;
  margin-top: 0px;
  margin-left: 4px;
  font-size: 25px;
}
.operationsSubt-checkout {
  color: white !important;
  margin-top: 0px;
  margin-left: 8px;
  font-size: 25px;
}
.add_count-checkout {
  font-size: 16px;
  padding-left: 12px;
}
.categories-order {
  min-height: 100px;
  max-height: 400px !important;
  overflow-y: auto !important;
}
</style>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ListProducts',
  data (){
    return{
      verifyChange: false,
      anyObject: null,
      movecartlist:false,
    }
  },
  props: ['isCheckout', 'isShoppingCart'],

  created() {
    if (this.getProductMixin().length > 0) {
      this.$store.commit('shoppingCart/setProducts', this.getProductMixin());
    }
  },

  computed: mapState('shoppingCart', {
    // state
    products: state => state.products,
    ...mapGetters('shoppingCart', ['getTotal'])
  }),

  methods: {
    // mutations
    ...mapMutations('shoppingCart', [
      'calculateCostProducts'
      ]),

    addProduct(product) {
        this.addProductMixin(product);
        this.calculateCostProducts();
    },

    subtractProducts(product, j) {
        this.getProductMixin()[j].count -= 1;
        if(this.getProductMixin()[j].count < 1){
          this.getProductMixin().splice(j, 1);
          localStorage.setItem('CART', JSON.stringify(this.products));
        }
        this.calculateCostProducts();
        this.setToCartMixin(this.getProductMixin());
      setTimeout(()=>{
        let cartsize = document.querySelector('#divcontentproducts').clientHeight
        console.log("sizeproducts",cartsize)
        if((cartsize+407-300)>window.innerHeight){
          this.movecartlist = false;
        }else{
          this.movecartlist = true;
        }
        this.$emit('movecartlist', this.movecartlist)
      }, 500)
    },
    aceptClearBusiness() { /*method in carta.vue CAN BE in a MIXIN */
      this.clearBussinesLocalMixin();
      this.clearShoppingCartMixin();
      this.clearDistanceAndCostShipping();
      this.verifyChange = false;
    },

  }

}
</script>