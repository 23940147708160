<template>
  <div class="main-section">
    <div class="page-section nopadding cs-nomargin" style="margin-top: 0px;padding-top: 60px;padding-bottom: 10px;margin-bottom: 0px;">
      <div class="container">

        <div class="container-search">
          <Search></Search>
        </div>

        <CoolLightBox
        :items="items"
        :index="index"
        @close="index = null, items = []">
      </CoolLightBox>

      <div class="row">
        <div class="section-fullwidth col-lg-12 col-md-12 col-sm-12 col-xs-12">

          <ContentPlaceHoldersSearch v-if="loading"></ContentPlaceHoldersSearch>

          <div v-else class="row">
            <span v-for="(business, n) in businessAndProducts" :key="business.codempresa" >
              <div v-if="n < showNbusiness" class="col-lg-3 col-md-4 col-sm-6 col-xs-12 card-v-fot">
                <div class="card">
                  <div class="block-img">
                    <img :src="urlImgMixin + business.imagen" alt="Avatar" >
                  </div>
                  <div class="container-card">
                    <div class="text-center" style="height: 20px;">
                      <a href="" v-on:click.prevent="goBussines(business.categoria.codcategoria, business.products[0].codcarta ,business.nombre)" class="h4">
                        <b v-snip="1">{{ business.nombre }}</b>
                      </a>
                    </div>

                    <hr class="br-style">
                    <div class="container-carousel">
                      <VueSlickCarousel v-bind="settings">
                        <div v-for="product in business.products.slice(0, 3)" :key="product.codproducto">
                          <a href="" v-on:click.self="goProduct(business.categoria.codcategoria, product.codcarta ,business.nombre, product.codproducto)">
                            <div class="title-product">
                              <strong class="price bg-danger"><span v-snip="2">{{ product.titulo }}</span></strong>
                            </div>
                            <div class="containar-img pull-right">
                              <div v-on:click.prevent="openImage(product), index = 0"  class="container-icon-more"><i class="fas fa-plus icon-more"></i></div>
                              <img v-on:click.prevent="openImage(product), index = 0"  :src="urlImgMixin + product.foto" class="img-list wp-post-image img-style zoom-in" alt="avatar-z" width="100%">
                            </div>
                            <a href="" v-on:click.prevent="goProduct(business.categoria.codcategoria, product.codcarta ,business.nombre, product.codproducto)">
                              <div class="container-text">
                                <div class="description">
                                  <p v-snip="3">{{ product.descripcion }}</p>
                                </div>
                                <p class="price">
                                  <span>S/. {{ parseFloat(product.precio).toFixed(2) }}</span>
                                </p>
                              </div>
                            </a>
                          </a>
                        </div>
                      </VueSlickCarousel>
                    </div>
                    <div class="text-center">
                      <a href="" v-on:click.prevent="goBussines(business.categoria.codcategoria, business.products[0].codcarta ,business.nombre)" class="btn btn-success">Ver todo ({{ business.products.length }})</a>
                    </div>
                  </div>
                </div>
              </div>
            </span>

            <div v-if="businessAndProducts.length == 0">
              <div class="text-center" style="height: 600px; padding-top: 50px;">
                <h4>No se encontraron resultados</h4>
              </div>
            </div>
            <div v-if="!loading && businessAndProducts.length > 0 && showBtnAllBusiness" class="text-center">
              <a v-on:click.prevent="showAllBusiness" href="" class="btn btn-link btn-show-all">{{ btnShowAllText }}</a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style>
.slick-prev:before, .slick-next:before {
  color: #0cc665;
}
.slick-prev, .slick-next {
  top: 100px;
}
.cool-lightbox-caption h6, .cool-lightbox-caption p{
  color: #fff !important;
}
</style>
<style scoped>
.container-search {
  margin-bottom: 50px;
}
.card-v-fot {
  margin-bottom: 25px;
}
.card {
  box-shadow: 0 6px 10px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
}
.card:hover {
  transform: scale(1.05);
}
.container-card {
  padding: 16px;
}
.br-style {
  border-top: 1px solid #cacaca;
}
.container-text {
  margin-left: 5px;
  margin-right: 95px;
}
.title-product {
  width: 100% !important;
  height: 50px !important;
}
.description {
  height: 75px;
}
.price {
  margin-top: 15px;
  font-size: 16px;
}
.containar-img {
  margin-top: 25px;
  margin-right: 5px;
  width: 80px;
  height: 80px;
  position: relative;
}
.img-style {
  display:block;
  margin:auto;
}
.container-icon-more {
  right: -4px;
  top: 65px;
  position: absolute;
  line-height: 0;
  width: 20px;
  height: 20px;
  padding: 0;
  color: #fff;
  border-radius: 50%;
  background: #000;
  cursor: zoom-in;
}
.icon-more {
  padding-left: 5px;
  padding-top: 3px;
}
.zoom-in {
  cursor: zoom-in;
}
.container-carousel {
  padding-left: 10px;
  padding-right: 10px;
  height: 180px;
}
.img-header {
  padding: 10px;
  border-radius: 25px;
  width:100%;
  height: 200px;
}
.block-img {
  padding: 5px;
  height: 150px !important;
  width: 100%  !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  border-radius: 25px;
}
.block-img img{
  max-width: 100% ;
  max-height: 100% ;
  border-radius: 15px;
}
.btn-show-all {
  color: #000 !important;
  text-decoration: none;
  margin-bottom: 25px !important;
  margin-top: 10px !important;
}
@media  (max-width: 1200px){
  .block-img{
    height: 150px !important;
  }
}
@media  (max-width: 992px){
  .block-img{
    height: 180px !important;
  }
}
@media  (max-width: 767px){
  .block-img{
    height: 300px !important;
  }
}
@media  (max-width: 650px){
  .block-img{
    height: 270px !important;
  }
}
@media  (max-width: 550px){
  .block-img{
    height: 240px !important;
  }
}
@media  (max-width: 450px){
  .block-img{
    height: 210px !important;
  }
}
</style>

<script>
import ContentPlaceHoldersSearch from '@/components/ContentPlaceHoldersSearch.vue'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import VueSlickCarousel from 'vue-slick-carousel'
import { mapState } from 'vuex';
import Search from "@/components/Search.vue";

export default {
  name: 'SearchResult',

  components: { ContentPlaceHoldersSearch, VueSlickCarousel, Search },

  data() {
    return {
      index: null,
      items: [],
      auxImage: '',
      settings: {
       "dots": false,
       "dotsClass": "slick-dots custom-dot-class",
       "infinite": true,
       "autoplay": true,
       "autoplaySpeed": 5000,
       "slidesToShow": 1,
       "slidesToScroll": 1,
       "pauseOnDotsHover": true,
       "pauseOnFocus": true,
       "pauseOnHover": true
     }
   }
 },

 computed: mapState({
  businessAndProducts: state => state.search.businessAndProducts,
  loading: state => state.search.loading,
  showNbusiness: state => state.search.showNbusiness,
  btnShowAllText: state => state.search.btnShowAllText,
  showBtnAllBusiness() {
    if (this.businessAndProducts.length > 8 && this.showNbusiness == 8) {
      return true;
    }
    else {
      return false;
    }
  }
}),

 created() {
  this.searchBusiness();
},

methods: {
  searchBusiness() {
    let search = this.$route.query.search;
    if (search == '' || search == null) {
      this.$store.commit('search/setSearch', search);
      if (this.$route.name != 'Home') {
        this.$router.push({ name: 'Home' });
      }
    }
    else if (search.length < 3) {
      this.warningToastrMixin('Buscador', 'Digite una palabra.');
    }
    else {
      this.$store.commit('search/setSearch', search);
      this.$store.commit('search/changeLoading', true);
      let defaultAddress = this.getDefaultAddressMixin();
      let lat_long = {
        'lat': defaultAddress.lat,
        'lng': defaultAddress.lng
      };
      this.$store.dispatch('search/searchProductByLatLng', {
        'search': search,
        'lat_long': lat_long,
        'token': this.$store.state.user.token
      });
    }
  },

  showAllBusiness() {
    this.$store.commit('search/setBtnShowAllText', 'Cargando ...');
    this.$store.commit('search/setShowNbusiness', this.businessAndProducts.length);
  },

  openImage(product) {
    let img = {
      title: product.titulo,
      description: product.descripcion,
      src: this.urlImgMixin + product.foto
    };
    this.items.push(img);
    console.log('image', this.items)
  },

  goBussines(category_id, cart_id, business_name) {
    console.log(category_id, cart_id, business_name)
    this.$router.push({ name: "carta", params: { idCat: category_id, idCart: cart_id, empresa: business_name } });
  },

  goProduct(category_id, cart_id, business_name, codProduct) {
    console.log("codproduct",codProduct)
    this.$router.push({ name: "carta", params: { idCat: category_id, idCart: cart_id, empresa: business_name, codProductSelect: codProduct } });
  }
}

}
</script>

