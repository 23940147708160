<template>
	<div class="container">
		<div class="row">
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
        <h2 class="title">Recomendaciones</h2>
        <span v-for="(busines, t) in businessFiltered" :key="t" >
          <router-link :is="busines.estado ? 'router-link' : 'span'" :to="{ name: 'carta', params: { idCat: busines.codcategoria, idCart: busines.primeracodcarta, empresa: busines.nombre } }" v-if="t < show6" class="block-business col-lg-4 col-md-4 col-sm-6 col-xs-6">
            <div class="porcentaje" v-if="busines.porcentajepromocion">{{busines.porcentajepromocion}}%</div>
            <div class="block-body" >
              <div :class="busines.estado ? 'opacity' : 'gray'" >
                <img v-if="busines.imagen"  :src="urlImgMixin+ busines.imagen">
              </div>
              <div class="block-body-title">{{busines.nombre}}</div>
              <div class="block-body-subtitle">{{busines.descripcion}}</div>
              <div v-if="$store.state.shoppingCart.deliverygratis == 1|| busines.codtiporeserva == 7 || busines.codtiporeserva == 8" class="free-delivery">
								Delivery gratis
							</div>
              <div class="block-body-footer">
                <div>
                  <i class="icon-alarm2"></i>
                  {{busines.horario}}
                </div>
                <div>
                  <i class="fa fa-clock"></i>
                  {{busines.tiempo}}
                </div>
              </div>
              <div v-if="!busines.estado" class="cerrado">
                <span>Cerrado</span>
              </div>
            </div>
          </router-link>
        </span>
        <div v-if="!showAllBussines" class="text-center btn-container">
          <router-link :to="{ name: 'topempresas' }"  href="" class="btn btn-success">Ver todo</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.btn-container {
  padding-bottom: 30px;
}

.title{
  color:#000 !important;
  padding:20px
}
.block-business{
  padding: 0px 20px 0 20px;
  margin-bottom: 20px;
  transition: transform .3s;
}

.block-body {
  position: relative;
  text-align: center;
  width: 100%;
  height: 50%;
  margin: auto;
  margin-bottom: 10px;
  border-radius: 10px;
  text-shadow: 1px 0.5px #000;
}
.block-body img{
  width: 100%;
  margin: auto;
  border-radius: 10px;
}
.porcentaje{
  font-size: 1.7rem !important;
  background: #FFC245;
  color: #372D11;
  padding: 0 12px 2px 12px;
  position: absolute;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 1;
  right: 30px;
}
.opacity {
  max-width: 100%;
  margin: auto;
}
.opacity::after {
  display: block;
  position: relative;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #000 100%);
  margin-top: -150px;
  height: 150px;
  width: 100%;
  content: '';
  border-radius: 10px;
}
.gray {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}
.gray::after {
  display: block;
  position: relative;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #000 100%);
  margin-top: -150px;
  height: 150px;
  width: 100%;
  content: '';
  border-radius: 10px;
}
.block-body-title {
  position: absolute;
  top: 20%;
  font-size: 18px;
  width: 100%;
  font-weight: bold;
  color: #fff;
}

.block-body-subtitle {
  font-size: 12px;
  position: absolute;
  top: 45%;
  width: 100%;
  color: #fff;
  padding: 0 20px 0 20px;
}
.block-body-footer{
  font-size: 0.9em !important;
  position: absolute;
  top: 80%;
  width: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 10px;
}
.block-body-footer button {
  margin: 4px;
}
.cerrado{
  position: absolute;
  top: 5px;
  left: 5px;
  color: #fff;
  background-color: #cc1f33;
  border-radius: 5px;
  font-size:12px;
  padding: 0 5px 0 5px
}
.block-business:hover {
  transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
.free-delivery {
  position: absolute;
  top: 8rem;
  left: 9rem;
  font-size: 10px;
  color: #fff;
  background-color: rgba(164, 39, 199, 0.92);
  padding: 1px 6px;
  border-radius: 5px;
  cursor: pointer;
}

@media  (min-width: 385px){
  .free-delivery {
    position: absolute;
    top: 12rem;
    left: 11rem;
    font-size: 10px;
    padding: 1px 6px;
    border-radius: 5px;
  }
}
@media  (min-width: 450px){
  .free-delivery {
    position: absolute;
    top: 14rem;
    left: 13rem;
    font-size: 10px;
    padding: 1px 6px;
    border-radius: 5px;
  }
}
@media  (min-width: 500px){
  .free-delivery {
    position: absolute;
    top: 17rem;
    left: 18rem;
    font-size: 10px;
    padding: 1px 6px;
    border-radius: 5px;
  }
}
@media  (min-width: 650px){
  .free-delivery {
    position: absolute;
    top: 10rem;
    left: 10rem;
    font-size: 9px;
    padding: 1px;
    border-radius: 5px;
  }
}
@media  (min-width: 735px){
  .free-delivery {
    position: absolute;
    top: 12rem;
    left: 12rem;
    font-size: 9px;
    padding: 1px;
    border-radius: 5px;
  }
}
@media  (min-width: 991px){
  .free-delivery {
    position: absolute;
    top: 11rem;
    left: 11rem;
    font-size: 9px;
    padding: 1px;
    border-radius: 5px;
  }
}
@media  (min-width: 1200px){
  .free-delivery {
    position: absolute;
    top: 13rem;
    left: 13rem;
    font-size: 10px;
    padding: 1px 6px;
    border-radius: 5px;
  }
}
@media  (max-width: 650px){
  .block-business{
    width: 100%;
  }
}
</style>

<script>
import { mapState } from 'vuex';

export default {
	name: 'TopBusiness',

  props: {
    showAllBussines: {
      type: Boolean,
      default: () => false
    }
  },

  data() {
    return {
    }
  },

  created() {
    console.log('showAllBussines', this.showAllBussines)
    console.log("entro top empresa");
    this.getTopBusiness();
  },

  computed: mapState({
    business: state => state.searchBusiness.business,
    businessFiltered: state => state.searchBusiness.businessFiltered,
    show6() {
      if (this.showAllBussines) {
        return this.business.length;
      }
      else {
        return 6;
      }
    }
  }),

  methods: {
    getTopBusiness() {
      let defaultAddress = this.getDefaultAddressMixin();
      let lat_long = {
        'lat': defaultAddress.lat,
        'lng': defaultAddress.lng
      };
      this.getTopBusinessMixin(lat_long).then(response => {
        console.log('EN TOP EMPRESA category', response.data);
        this.$store.commit('searchBusiness/setBusiness', response.data.empresas);
        this.$store.commit('searchBusiness/setBusinessFiltered', response.data.empresas);
      });
    }

  }
}
</script>