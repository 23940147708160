import configs from '@/utils/configs'

let user = JSON.parse(localStorage.getItem('user'));
let token = localStorage.getItem('token');

var authMixin = {
	computed: {
		isAuthenticatedMixin(){
			return !! user;
		},

		getCurrentUserMixin() {
			return user;
		},

		getTokenMixin() {
			return token;
		},

		getTokenStaticMixin() {
			return configs.staticToken;
		},
	},

	methods: {
		setNewTokenMixin(newToken) {
			token = newToken;
		},

		initCurrentUserInVuexMixin() {
			this.$store.commit('user/setCurrentUser', user);
			this.$store.commit('user/setToken', token);
		},

		initTokenStaticMixin() {
			this.$store.commit('user/setToken', token);
		},

		setNewUserMixin(newUser) {
			user = newUser;
		},

		resetAuthMixin() {
			user = null;
			token = null;
		}
	}
};

export default authMixin;