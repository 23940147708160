import axios from 'axios'
import configs from '@/utils/configs'

const getDefaultState = () => {
	return {
		orderVuex: '', // es pedidos pendiente o resultado de crear una orden
		ordersVuex: [],
		historicalOrder: '',
		historicalOrders: [],
		// ZCurrier
		isZCurrier: false,
		loading: false,
		// calificar
		orderNumber: '',
		codpedido: null,
		qualify: ''
	}
}

export default {
	namespaced: true,

	state: getDefaultState(),

	getters: {
		getOrder(state) {
			if (state.orderVuex) {
				return state.orderVuex
			}
			else {
				return state.historicalOrder;
			}
		}
	},

	mutations: {
		setOrder(state, order) {
			state.orderVuex = order;
		},

		setOrders(state, Orders) {
			state.ordersVuex = Orders;
		},

		setHistoricalOrder(state, order) {
			state.historicalOrder = order;
		},

		setHistoricalOrders(state, Orders) {
			state.historicalOrders = Orders;
		},

		setIsZCurrier(state, isZCurrier) {
			state.isZCurrier = isZCurrier;
		},

		setLoading(state, loading) {
			state.loading = loading;
		},

		setOrderNumber(state, orderNumber) {
			state.orderNumber = orderNumber;
		},

		setCodpedido(state, codpedido) {
			state.codpedido = codpedido;
		},

		setQualify(state, qualify) {
			state.qualify = qualify;
		},

		resetState (state) {
			Object.assign(state, getDefaultState())
		}
	},

	actions: {
		resetOrderState({ commit }) {
			commit('resetState');
		},

		getQualifyOrder({ commit }, { codpedido, token }) {
			const apiURL = configs.apiUrl + '/calificaciones/' + codpedido;
			axios.get(apiURL, {
				headers: {'Authorization': 'bearer ' + token}
			}).then(response => {
				console.log('get qualify', response.data)
				commit('setQualify', response.data);
			}).catch((error) => {
				commit('setQualify', '');
				console.log(error);
			});
		}
	}
}