const getDefaultState = () => {
	return {
		business: [],
		businessFiltered: []
	}
}

export default {
	namespaced: true,

	state: getDefaultState(),

	mutations: {
		setBusiness (state, business) {
			state.business = business;
		},

		setBusinessFiltered (state, businessFiltered) {
			state.businessFiltered = businessFiltered;
		},

		resetState (state) {
			Object.assign(state, getDefaultState())
		}
	},

	actions: {
		resetSearchBusinessState({ commit }) {
			commit('resetState');
		}
	}
}