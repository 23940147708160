import axios from 'axios'
import configs from '@/utils/configs'

var apiOthersMixin = {

	methods: {
		getCitiesMixin() {
			const apiURL = configs.apiUrl + '/ciudades';
			return axios.get(apiURL);
		},

		getSupportCitiesMixin() {
			const apiURL = configs.apiUrl + '/ciudades/soporte';
			return axios.get(apiURL);
		}

	}
};

export default apiOthersMixin;