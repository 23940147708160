<template>
  <!-- Main Section Start -->
	<div class="main-section" style="background:#FAFAF8;  ">
		<div class="page-section nopadding cs-nomargin carousel_section" style="background: #FAFAF8; height: 180px; padding-top: 20px;padding-bottom: 20px;margin-bottom: 20px;">
			<!-- Container Start -->
			<div class="container ">
				<div class="row">
					<div class="section-fullwidth col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<div class="row">
							<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
								<!--Element Section Start-->
								<div id="carousel-offers" class="carousel slide" data-ride="carousel" data-pause="false">
									<!-- slider offers -->
									<content-loader-carousel :shownow="isLoadingCarousel" :many="sizewidthfunc"/>
									<div class="carousel-inner" role="listbox" v-if="sizewidthfunc == 3">
										<div class="item" :class="i==1 ? 'active' : ''" v-for="i in getNumSliders()" :key="i">
											<div class="row">
												<div class="col-sm-12 col-md-6 col-lg-4" v-for="(offer, index) in offers" :key="index">
													<div class="block-img_car" v-show="index<(i)*3 && index>=(i-1)*3" @click="goProductsoffer(offer.codcarta.codcarta, offer.codcarta.codempresa)" :style="i>1 ? 'margin-top: -25px' : ''">
														<img class="imgoffer" :src="urlImgOffersMixin + offer.imagen" :alt="offer.titulo" height="150px" width="100%">
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="carousel-inner" role="listbox" v-if="sizewidthfunc == 2">
										<div class="item" :class="i==1 ? 'active' : ''" v-for="i in getNumSliders2()" :key="i">
											<div class="row">
												<div class="col-sm-12 col-md-6 col-lg-4" v-for="(offer, index) in offers" :key="index">
													<div class="block-img_car" v-show="index<(i)*2 && index>=(i-1)*2" @click="goProductsoffer(offer.codcarta.codcarta, offer.codcarta.codempresa)" :style="i>1 ? 'margin-top: -25px' : ''">
														<img class="imgoffer" :src="urlImgOffersMixin + offer.imagen" :alt="offer.titulo" height="150px" width="100%">
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="carousel-inner" role="listbox" v-if="sizewidthfunc == 1">
										<div class="item" :class="i==1 ? 'active' : ''" v-for="i in offers.length" :key="i">
											<div class="row" style="display:flex; align-items:center; justify-content: center;">
												<div :class="{'col-sm-12 col-md-6 col-lg-4': index<(i)*1 && index>=(i-1)*1}" v-for="(offer, index) in offers" :key="index" style="display:flex;align-items:center; justify-content: center;">
													<div class="block-img_car" v-if="index<(i)*1 && index>=(i-1)*1" @click="goProductsoffer(offer.codcarta.codcarta, offer.codcarta.codempresa)" >
														<img class="imgoffer" :src="urlImgOffersMixin + offer.imagen" :alt="offer.titulo" height="150px" width="100%">
													</div>
												</div>
											</div>
										</div>
									</div>

									<!-- Controls -->
									<div>
										<a class="left" href="#carousel-offers" role="button" data-slide="prev" style="position: absolute; top: 50px; left:-25px;">
											<i class="fas fa-arrow-circle-left" style="font-size: 30px"></i>
										</a>
										<a class="right" href="#carousel-offers" role="button" data-slide="next" style="position: absolute; top: 50px; right:-25px;">
											<i class="fas fa-arrow-circle-right" style="font-size: 30px"></i>
										</a>
									</div>

								</div>
							</div>
						</div><!-- end section row -->
					</div>
				</div>
			</div> <!-- End Container Start -->
		</div>
		<div class="page-section nopadding cs-nomargin display-sm" style="background: #FAFAF8; margin-top: 0px;padding-top: 8px;padding-bottom: 6px;margin-bottom: 0px;">
			<!-- Container Start -->
			<div class="container ">
				<div class="row">
					<div class="section-fullwidth col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<div class="row">
							<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
								<!--Element Section Start-->
								<div id="carousel-example-generic-2" class="carousel slide" data-ride="carousel" data-pause="false">
									<!-- slider categories -->
									<div v-if="windowWidth>1200" class="carousel-inner" style="height: 110px;" role="listbox">

										<div class="item active">
											<div class="row">
												<div :class="'col-sm-12 col-md-6 col-lg-1'" style="margin-left: 0px;" v-for="(cat, index) in getCatsbyisActive" :key="index">
													<div class="carousel__cotainer--image" v-if="index < 12">
														<router-link :to="{ name: 'categoria', params: { nombre: generateSlug(cat.nombrecat), id: cat.codcategoria} }">
															<div class="block-img">
																<img 
																:src="urlImgMixin+cat.imagencat"
																:alt="cat.nombrecat" height="80px"  @click="sendActive(cat)" width="100%" text="aqui">
															</div>
														</router-link>
														<div style="text-align:center;">
															<span style="text-align:center; margin-left: 0px; font-size: 12px;">{{ cat.nombrecat }}</span>
														</div>
													</div>
												</div>
												<div :class="'col-sm-12 col-md-6 col-lg-1'" style="margin-left: 0px;" v-for="(cat, indexi) in getCatsbyisActive" :key="indexi+1000">
													<div class="carousel__cotainer--image" v-if="indexi < 12-getCatsbyisActive.length && getCatsbyisActive.length<12">
														<router-link :to="{ name: 'categoria', params: { nombre: generateSlug(cat.nombrecat), id: cat.codcategoria} }">
															<div class="block-img">
																<img
																:src="urlImgMixin+cat.imagencat"
																:alt="cat.nombrecat" height="80px"  @click="sendActive(cat)" width="100%" text="aqui">
															</div>
														</router-link>
														<div style="text-align:center;">
															<span style="text-align:center; margin-left: 0px; font-size: 12px;">{{ cat.nombrecat }}</span>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div class="item" v-if="getCatsbyisActive.length>12">
											<div class="row">
												<div :class="index>=getCatsbyisActive.length-12 ? 'col-sm-12 col-md-6 col-lg-1' : ''" style="margin-top: 0px; margin-left: 0px;" v-for="(cat, index) in getCatsbyisActive" :key="index">
													<div class="carousel__cotainer--image" v-if="index >= getCatsbyisActive.length-12 && index <= 24">
														<router-link :to="{ name: 'categoria', params: { nombre: generateSlug(cat.nombrecat), id: cat.codcategoria} }">
															<div class="block-img">
																<img
																:src="urlImgMixin+cat.imagencat"
																:alt="cat.nombrecat" @click="sendActive(cat)" height="80px" width="100%">
															</div>
														</router-link>
														<div style="text-align:center;">
															<span style="text-align:center; margin-left: 0px; font-size: 12px;">{{ cat.nombrecat }}</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div v-if="windowWidth<1200" class="carousel-inner" style="height: 110px;" role="listbox">
										<div class="item active">
											<div class="carousel_md">
												<div class="carousel_item" style="margin-left: 0px;" v-for="(cat, index) in getCatsbyisActive" :key="index">
													<div class="carousel__cotainer--image" v-if="index < 6">
														<router-link :to="{ name: 'categoria', params: { nombre: generateSlug(cat.nombrecat), id: cat.codcategoria} }">
															<div class="block-img">
																<img 
																:src="urlImgMixin+cat.imagencat"
																:alt="cat.nombrecat" height="80px"  @click="sendActive(cat)" width="100%" text="aqui">
															</div>
														</router-link>
														<div style="text-align:center;">
															<span style="text-align:center; margin-left: 0px; font-size: 12px;">{{ cat.nombrecat }}</span>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="item">
											<div class="carousel_md" style="margin-left: -8rem;">
												<div :class="{'carousel_item':index>=6}" v-for="(cat, index) in getCatsbyisActive" :key="index+100">
													<div class="carousel__cotainer--image" v-if="index >= 6 && index < 12">
														<router-link :to="{ name: 'categoria', params: { nombre: generateSlug(cat.nombrecat), id: cat.codcategoria} }">
															<div class="block-img">
																<img 
																:src="urlImgMixin+cat.imagencat"
																:alt="cat.nombrecat" height="80px"  @click="sendActive(cat)" width="100%" text="aqui">
															</div>
														</router-link>
														<div style="text-align:center;">
															<span style="text-align:center; margin-left: 0px; font-size: 12px;">{{ cat.nombrecat }}</span>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="item">
											<div class="carousel_md" style="margin-left: -50rem;">
												<div :class="{'carousel_item':index>=12}" v-for="(cat, index) in getCatsbyisActive" :key="index+100">
													<div class="carousel__cotainer--image" v-if="index >= 10">
														<router-link :to="{ name: 'categoria', params: { nombre: generateSlug(cat.nombrecat), id: cat.codcategoria} }">
															<div class="block-img">
																<img 
																:src="urlImgMixin+cat.imagencat"
																:alt="cat.nombrecat" height="80px"  @click="sendActive(cat)" width="100%" text="aqui">
															</div>
														</router-link>
														<div style="text-align:center;">
															<span style="text-align:center; margin-left: 0px; font-size: 12px;">{{ cat.nombrecat }}</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<!-- Controls -->
									<a class="carousel-control arrow_left" style=" width:50px;   margin-top: -30px;" href="#carousel-example-generic-2" role="button" data-slide="prev">
										<i class="fas fa-arrow-left glyphicon glyphicon-chevron-left "></i>
									</a>
									<a class="carousel-control arrow_right" style=" width:50px;  margin-top: -30px;" href="#carousel-example-generic-2" role="button" data-slide="next">
										<i class="fas fa-arrow-right glyphicon glyphicon-chevron-right"></i>
									</a>
								</div>
							</div>
						</div><!-- end section row -->
					</div>
				</div>
			</div> <!-- End Container Start -->
		</div>

		<div class="page-section nopadding cs-nomargin" style="background: #FAFAF8; margin-top: 0px;padding-top: 20px;padding-bottom: 60px;margin-bottom: 0px; min-height: 600px;">
			<div class="container">
				<div class="row">
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<div class="row">
							<aside class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
								<div class="filter-wrapper">
									<div class="foodbakery-filters">
										<div class="filter-holder panel-default">
											<div class="filter-heading">
												<h6><i class="icon-food"></i>Categorias</h6>
											</div>
											<div class="">
												<div class="">
													<ul style="cursor: pointer;">
														<li v-if="isLoadingCats">
															<contentplaceholders/>
														</li>
														<li style="list-style: none;" class="sidebar__categories--list" v-for="category in listCats" :key="category.nombrecat">
															<div class="barrita__lateral--cat" :style=" isActive.nombrecat == category.nombrecat ? 'background: #fe2526;': ''"></div>
															<router-link :to="{ name: 'categoria', params: { nombre: generateSlug(category.nombrecat), id: category.codcategoria} }">
																<a style="margin-left: 18px;" @click="sendActive(category)">{{ category.nombrecat }}</a>
															</router-link>
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</aside>
							<div class="col-lg-10 col-md-10 col-sm-12 col-xs-12">

								<div class="listing grid-listing three-cols" >
									<div class="col-md-4 col-xs-6 grid-listing-col card__container" v-if="false">
										<contentplaceholders/>
									</div>
									<!-- loop -->
									<div v-if="isLoading" style="position: absolute;">
										<content-loader/>
									</div>
									<div v-if="!isLoading">
										<div class="col-md-4 col-xs-6 grid-listing-col card__container" v-for="(empresa, index) in data" :key="index">
											<div @click="verifyModal(empresa) ? toggleModal(empresa) : goProductsCart(empresa) ">
												<div class="img-holder card__container--image">
													<figure>
														<img class="card__container--img"
															:style="empresa.estado == 0 ? 'filter: grayscale(100%)' : ''"
															:src="urlImgMixin+empresa.imagen"
															:alt="empresa.nombre">
													</figure>
												</div>
												<div class="card__container--name">{{ empresa.nombre }}</div>
												<div v-if="empresa.estado == 0" class="cerrado">
													<span>Cerrado</span>
												</div>
											</div>
											<div class="properties_container">
												<div>
													<i class="fas fa-star"></i>
													<span class="span__star--value">3.5</span>
												</div>
												<div>
													<span class="span__time--value">
														<i class="far fa-clock"></i>{{ empresa.tiempo }}
													</span>
												</div>
											</div>
											<div v-if="$store.state.shoppingCart.deliverygratis == 1|| empresa.codtiporeserva == 7 || empresa.codtiporeserva == 8" class="free-delivery" @click="verifyModal(empresa) ? toggleModal(empresa) : goProductsCart(empresa) ">
												Delivery gratis
											</div>
											<div v-if="empresa.porcentajepromocion" class="discountWidget">
												<span>{{ empresa.porcentajepromocion }}%</span>
											</div>
										</div>
									</div>
									<!-- /loop -->
									<div>
										<modalCarts v-if="showModal" @close="showModal = !showModal" :title="title_modal">
												<template v-slot:body>
													<div style="margin-left: 100px"><hash-loader :loading="isLoadingSpinner" :color="'#0cc665'" :size="150"/></div>
													<div v-if="!isLoadingSpinner && cartBusinessCheck()">
														<div v-for="cart in cartBusiness" :key="cart.codcarta" class="div__cart--img">
															<router-link :to="{name:'carta', params:{ idCat:$route.params.id , idCart: cart.codcarta, empresa: title_modal}}">
																<div :style="styleimg(cart.fotohorizontal)">
																		<div class="cart__cont--name">
																			{{ cart.nombrecarta }}
																		</div>
																		<div v-if="cart.porcentajepromocion" class="discountWidgetCartsmall">
																			<span>{{cart.porcentajepromocion}}%</span>
																		</div>
																</div>
															</router-link>
														</div>
													</div>
												</template>
										</modalCarts> <!-- Show Modal Carts -->
									</div>
									<div>
										<modal-horizontal-carts v-if="showhorizontalModal" @close="showhorizontalModal = !showhorizontalModal" :title="title_modal">
											<template v-slot:body>
												<div class="container">
													<div class="div__carts--box">
														<div class="div__carts--container" v-for="cart in cartBusiness" :key="cart.codcarta">
															<router-link :to="{name:'carta', params:{ idCat:$route.params.id , idCart: cart.codcarta, empresa: title_modal}}">
																<div :style="styleimgHorizontal(cart.fotohorizontal)">
																		<div class="div__horizontal--name">
																			{{ cart.nombrecarta }}
																		</div>
																		<div v-if="cart.porcentajepromocion" class="discountWidgetCartlarge">
																			<span>{{cart.porcentajepromocion}}%</span>
																		</div>
																</div>
															</router-link>
														</div>
													</div>
												</div>
											</template>
										</modal-horizontal-carts>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Main Section End -->
</template>

<script>
import modalCarts from "@/components/modals/modalCarts";
import contentplaceholders from "@/components/contentplaceholders";
import ContentLoader from "@/components/ContentLoader";
import ModalHorizontalCarts from "@/components/modals/ModalHorizontalCarts.vue";
import ContentLoaderCarousel from "@/components/ContentLoaderCarousel.vue";

export default {
  data() {
    return {
      data: [],
      showModal: false,
      showhorizontalModal: false,
      title_modal: "",
      cartBusiness: [],
      DataCategories: [],
      isLoading: false,
      isLoadingCats: false,
      isLoadingSpinner: false,
      isActive: "",
      offers: [],
      sizewidth: null,
      windowWidth: window.innerWidth,
      isLoadingCarousel: false,
    };
  },
  components: {
    modalCarts,
    contentplaceholders,
    ContentLoader,
    ModalHorizontalCarts,
    ContentLoaderCarousel,
  },
  created() {
    this.getCategoryBussines();
    this.getDataCategories();
    this.getoffersApi();
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
		this.goMainSectionMixin();
  },
  watch: {
    $route() {
      this.getCategoryBussines();
    },
  },
  methods: {
    /* api */
    getCategoryBussines() {
      this.isLoading = true; /* start loading the pluying */

      this.getCategoryBusinessMixin(
        this.$route.params.id,
        this.getUbicationMixin()
      )
        .then((response) => {
          this.data = response.data.empresas;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getDataCategories() {
      this.isLoadingCats = true;
      this.getCategoriesMixin(this.getUbicationMixin())
        .then((response) => {
          this.DataCategories = response.data.categorias;
          this.selectActive();
        })
        .finally(() => {
          this.isLoadingCats = false;
        });
    },

    getDataCarts(id_business) {
      this.isLoadingSpinner = true;
      this.getDataCartsBusinessMixin(id_business)
        .then((response) => {
          this.cartBusiness = response.data.cartas;
        })
        .finally(() => {
          this.isLoadingSpinner = false;
        });
    },

    getoffersApi() {
      this.isLoadingCarousel = true;
      this.getoffersMixin(this.getUbicationMixin())
        .then((response) => {
          this.offers = response.data.ofertas;
        })
        .finally(() => {
          this.isLoadingCarousel = false;
        });
    },
    /* end-api */

    selectActive() {
      for (let i = 0; i < this.DataCategories.length; i++) {
        if (
          this.$route.params.nombre.toLowerCase() ==
          this.DataCategories[i].nombrecat.toLowerCase().replace(/ /g, "-")
        ) {
          this.isActive = this.DataCategories[i];
        }
      }
    },

    toggleModal(business) {
      if (business.estado == 1) {
        this.showModal = !this.showModal;
        this.title_modal = business.nombre;
        this.getDataCarts(business.codempresa);
      }
    },

    goProducts(id_carta, empresa_name) {
      this.$router.push({
        name: "carta",
        params: {
          idCat: this.$route.params.id,
          idCart: id_carta,
          empresa: empresa_name,
        },
      });
    },

    goProductsoffer(id_carta, empresa) {
      this.$router.push({
        name: "carta",
        params: {
          idCat: empresa.categoria.codcategoria,
          idCart: id_carta,
          empresa: empresa.nombre,
        },
      });
    },

    verifyModal(empresa) {
      if (!empresa.carta) {
        if (empresa.carta === null) {
          return false;
        }
        return true; /* show modal*/
      }
      return false;
    },

    goProductsCart(empresa) {
		if (empresa.estado==1) {	
			if (!empresa.carta) {
				if (empresa.carta === null) {
				this.goProducts(empresa.codcarta, empresa.nombre);
				} else {
				this.goProducts(empresa.carta.codcarta, empresa.nombre);
				}
			} else {
				this.goProducts(empresa.carta.codcarta, empresa.nombre);
			}
		}
    },

    styleimg(img) {
      return `height:25rem; width:44.6rem; margin-left: -28px; margin-top: 5px;
				background: linear-gradient(to bottom, transparent, black), url('${
          this.urlImgMixin + img.replace(new RegExp(" ", "g"), "%20")
        }'); background-size: cover; display:block; border-radius: 10px; filter: grayscale(10%);`;
    },

    styleimgHorizontal(img) {
      return `height:15rem; width:350px; margin-top: 5px;
				background: linear-gradient(to bottom, transparent, black), url('${
          this.urlImgMixin + img.replace(new RegExp(" ", "g"), "%20")
        }'); background-size: cover; display:block; border-radius: 10px; filter: grayscale(10%);`;
    },

    sendActive(cat) {
      this.isActive = cat;
    },

    cartBusinessCheck() {
      if (this.cartBusiness.length < 3) {
        return true;
      } else {
        this.showModal = false;
        this.showhorizontalModal = true;
        return false;
      }
    },
    /* get int to show sliders */
    getNumSliders() {
      if (this.offers.length) {
        if (this.offers.length % 3 == 0) {
          return this.offers.length / 3;
        } else {
          return parseInt(this.offers.length / 3) + 1;
        }
      }
    },

    getNumSliders2() {
      if (this.offers.length) {
        if (this.offers.length % 2 == 0) {
          return this.offers.length / 2;
        } else {
          return parseInt(this.offers.length / 2) + 1;
        }
      }
    },
  },

  computed: {
    getCatsbyisActive() {
      var CatsbyActive = [];
      for (let i = 0; i < this.DataCategories.length; i++) {
        if (this.DataCategories[i].bloquev2 == this.isActive.bloquev2) {
          CatsbyActive.push(this.DataCategories[i]);
        }
      }
      return CatsbyActive;
    },
    listCats() {
      var cats = [];
      for (let i = 0; i < this.DataCategories.length; i++) {
        if (this.isActive.bloquev2 == this.DataCategories[i].bloquev2) {
          cats.push(this.DataCategories[i]);
        }
      }
      return cats;
    },
    sizewidthfunc() {
      if (this.windowWidth < 1200) {
        if (this.windowWidth < 990) {
          return 1;
        }
        return 2;
      }
      return 3;
    },
  },
};
</script>
<style scoped src="@/css/Listing.css">
</style>