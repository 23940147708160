<template>
  <!-- Main Section Start -->
  <div class="main-section" style="background: #fafaf8">
    <div
      class="page-section nopadding cs-nomargin"
      style="'margin-top: 0px;padding-top: 0px;padding-bottom: 10px;margin-bottom: 0px; '"
      :style="getStyles"
    ></div>
    <!-- Container Start -->
    <div class="container">
      <!-- <div class="container img_business--container" :style="getStylescenter">
        </div> -->
      <div class="row containerBusiness">
        <div style="color: white">
          <h2 style="color: white !important">
            {{ currentlyBusiness.nombre }}
          </h2>
          <strong>Horario:</strong> {{ currentlyBusiness.horario
          }}<strong> Tiempo: </strong>{{ currentlyBusiness.tiempo }}
        </div>
        <div>
          <search-product
            :currentlyB="currentlyBusiness"
            @results="resultsFromSearcher($event)"
            @searchInit="startLoading($event)"
          />
        </div>
      </div>
    </div>
    <!-- End Container Start -->
    <div
      class="page-section nopadding cs-nomargin"
      id="productsContent"
      style="
        background: #fafaf8;
        margin-right: auto;
        margin-left: auto;
        margin-top: 0px;
        padding-top: 40px;
        padding-bottom: 10px;
        margin-bottom: 0px;
        min-height: 600px;
      "
    >
      <div class="container">
        <div class="row" ref="infoBox">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row" style="margin-right: auto; margin-left: auto">
              <aside class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                <div class="filter-wrapper">
                  <div class="foodbakery-filters listing-filter">
                    <div class="filter-holder panel-default">
                      <div class="filter-heading">
                        <h6><i class="icon-food"></i>Categorias</h6>
                      </div>
                      <div class="select-categories">
                        <div class="filter-list cs-checkbox-list">
                          <ul
                            style="cursor: pointer"
                            v-for="category in product_categories"
                            :key="category.codproductocategoria"
                          >
                            <div
                              class="barrita__lateral--cat"
                              :style="
                                isActive.codproductocategoria ==
                                category.codproductocategoria
                                  ? 'background: #fe2526;'
                                  : ''
                              "
                            ></div>
                            <li
                              class="sidebar__categories--list"
                              @click="getProductsbyCat(category)"
                            >
                              {{ category.nombreproductocategoria }}
                            </li>
                          </ul>
                          <div
                            class="barrita__lateral--cat"
                            :style="
                              isActive == 'all' ? 'background: #fe2526;' : ''
                            "
                          ></div>
                          <li
                            class="expand"
                            style="color: #515151 !important"
                            @click="changetoshowall()"
                          >
                            ver todas
                          </li>
                        </div>
                      </div>
                    </div>
                    <div
                      class="filter-holder panel-default"
                      v-if="CartsBusiness.length > 1"
                    >
                      <div class="filter-heading">
                        <h6>
                          <i class="fas fa-clipboard-list"></i>Otras Cartas
                        </h6>
                      </div>
                      <div class="select-categories">
                        <div class="filter-list cs-checkbox-list">
                          <ul
                            style="cursor: pointer"
                            v-for="cart in CartsBusiness"
                            :key="cart.nombrecarta"
                          >
                            <div
                              class="barrita__lateral--cat"
                              :style="
                                isActiveCart.codcarta == cart.codcarta
                                  ? 'background: #fe2526;'
                                  : ''
                              "
                            ></div>
                            <li
                              class="sidebar__categories--list"
                              @click="getDataAnotherCart(cart)"
                            >
                              {{ cart.nombrecarta }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </aside>

              <div class="col-lg-7 col-md-6 col-sm-12 col-xs-12">
                <div class="container2" v-if="isloading">
                  <div class="row" style="margin-left: 20px">
                    <div class="col-sm-12 col-md-6 col-lg-6">
                      <!-- calling pluying before call apis -->
                      <contentplaceholders />
                    </div>
                    <div class="col-sm-12 col-md-8 col-lg-4">
                      <!-- calling pluying before call apis -->
                      <contentplaceholders />
                    </div>
                  </div>
                </div>
                <CoolLightBox
                  :items="[currShow]"
                  :index="index"
                  @close="(index = null), (currShow = null)"
                ></CoolLightBox>
                <!-- HERE -->
                <div class="container2" v-if="!isloading">
                  <div class="row">
                    <input
                      placeholder="Buscar Producto"
                      onClick="this.select();"
                      name="searcher"
                      value=""
                      type="text"
                      v-model="filter"
                      class="searcher_products_local"
                      autocomplete="off"
                    />
                    <div
                      class="
                        col-sm-12 col-md-6 col-lg-6
                        box-product
                        margin-md margin-sm
                      "
                      style="
                        margin-top: 4px;
                        margin-bottom: 4px;
                        padding-left: 10px;
                      "
                      v-for="product in showCart_or_Category()"
                      :key="product.codproducto"
                    >
                      <div
                        class="container__product"
                        :id="product.codproducto"
                        :ref="product.codproducto"
                      >
                        <div class="img__product">
                          <img
                            @click="(index = 0), toshowimg(product)"
                            class="img__product--image zoom-in"
                            :src="urlImgMixin + product.foto"
                            :alt="product.descripcion"
                            height="96px"
                            width="96px"
                          />
                          <span
                            class="span__offert--percent"
                            v-if="product.preciopromocion != 0"
                          >
                            hasta -{{
                              product.precio == product.preciopromocion
                                ? parseInt(
                                    100 -
                                      (product.preciopromocion /
                                        product.precioTachado) *
                                        100
                                  )
                                : parseInt(
                                    100 -
                                      (product.preciopromocion /
                                        product.precio) *
                                        100
                                  )
                            }}%</span
                          >
                        </div>
                        <div class="container__product--detail">
                          <div class="div_title_product">
                            <span
                              class="title_product"
                              @click="hasPersonalizado(product)"
                              >{{ product.titulo }}</span
                            >
                          </div>
                          <div class="description__product">
                            <p class="description__product--text">
                              {{
                                !product.descripcionAll
                                  ? tosubString(product.descripcion)
                                  : product.descripcion
                              }}
                              <a
                                v-if="
                                  product.descripcion &&
                                  product.descripcion.length > 170
                                "
                                @click="seealldescription(product)"
                                style="color: #0cc665"
                              >
                                {{
                                  !product.descripcionAll
                                    ? "...leer más"
                                    : "...leer menos"
                                }}
                              </a>
                            </p>
                          </div>
                          <div class="foot__product">
                            <span class="foot__product--precio"
                              >S/.{{
                                product.preciopromocion != 0
                                  ? product.preciopromocion
                                  : product.precio
                              }}</span
                            >
                            <span
                              class="post-categories__price"
                              style="text-decoration: line-through"
                              v-if="product.preciopromocion != 0"
                            >
                              S/.{{
                                product.precio == product.preciopromocion
                                  ? product.precioTachado
                                  : product.precio
                              }}
                            </span>
                            <button
                              :id="product.titulo"
                              class="
                                primary-button-filled
                                foot__product--button
                              "
                              :style="
                                product.preciopromocion != 0
                                  ? 'margin-left: 45px;'
                                  : ''
                              "
                              data-toggle="modal"
                              :data-target="
                                verifyChange ? '#alertModal' : '#extras-0-1'
                              "
                              v-if="!showDetails(product)"
                              @click="sendOptions(product)"
                            >
                              Agregar
                            </button>
                            <!-- detail + - -->
                            <div
                              class=""
                              v-for="(prod, index) in products"
                              :key="index"
                            >
                              <div
                                class="col-sm-12 col-md-4"
                                style="display: flex; margin-left: 80px"
                                v-if="
                                  showDetails(product) &&
                                  prod.codproducto == product.codproducto &&
                                  product.personalizado == 0
                                "
                              >
                                <!-- add & subtrack -->
                                <div style="display: flex">
                                  <a
                                    @click="addProductToMixin(prod, product)"
                                    data-toggle="modal"
                                    :data-target="
                                      verifyChange ? '#alertModal' : ''
                                    "
                                    ><span class="dot"
                                      ><p class="operationsAdd">
                                        <i
                                          class="fas fa-plus-circle"
                                          style="font-size: 18px"
                                        ></i></p></span
                                  ></a>
                                  <span class="add_count">{{ prod.count }}</span
                                  ><span v-show="false">{{
                                    getCostProducts
                                  }}</span>
                                  <a
                                    @click="
                                      subtractProductsToMixin(
                                        prod,
                                        index,
                                        product
                                      )
                                    "
                                    data-toggle="modal"
                                    :data-target="
                                      verifyChange ? '#alertModal' : ''
                                    "
                                    ><span class="dot"
                                      ><p class="operationsSubt">
                                        <i
                                          class="fas fa-minus-circle"
                                          style="font-size: 18px"
                                        ></i></p></span
                                  ></a>
                                </div>

                                <div style="display: flex; margin-left: 110px">
                                  <span
                                    style="cursor: pointer"
                                    @click="checkedOption(prod)"
                                    class="label__title--afteradd"
                                    data-toggle="modal"
                                    data-target="#addCommentModal"
                                    >¿Quieres aclarar algo?</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /HERE -->

                <!-- modal extra -->
                <modal-extra
                  v-if="showoption"
                  data-backdrop="static"
                  data-keyboard="false"
                  :options="currently_temp_select.opciones"
                  :details="retDetails()"
                  :selected="sonproductData"
                  :prodSelect="currently_temp_select"
                  @acept="closeModalAcept($event)"
                  @close="justclose()"
                ></modal-extra>
                <!-- Modal change business-->
                <div
                  class="modal fade"
                  id="alertModal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="myModalLabel"
                >
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <h4 class="modal-title" id="myModalLabel">
                          Ha cambiado de empresa
                        </h4>
                      </div>
                      <div class="modal-body">¿Desea continuar aqui?<br /></div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-success"
                          data-dismiss="modal"
                          data-toggle="modal"
                          data-target="#extras-0-1"
                          @click="aceptClearBusiness()"
                        >
                          Aceptar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end modal change business -->
                <!-- Modal add comment-->
                <div
                  class="modal fade"
                  id="addCommentModal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="myModalLabel"
                >
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <h4 class="modal-title" id="myModalLabel">
                          ¿Quieres Aclarar Algo?
                        </h4>
                      </div>
                      <div class="modal-body" style="height: 76px">
                        <div>
                          <div class="col-12 input-effect">
                            <input
                              style="font-size: 12px"
                              type="text"
                              v-model="commentModal"
                              class="effect-16 input-style"
                              :class="{
                                'has-content':
                                  hasTextOnInputMixin(commentModal),
                              }"
                              placeholder=""
                              maxlength="90"
                              autocomplete="off"
                              @input="updateNotemodal($event)"
                              ref="commentInput"
                            />
                            <label class="effect-label" style="font-weight: 300"
                              >Notas al producto</label
                            >
                            <span class="focus-border"></span>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-success"
                          data-dismiss="modal"
                          data-toggle="modal"
                          data-target="#extras-0-1"
                          @click="aceptmodalcomment()"
                        >
                          Aceptar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end modal add comment -->
              </div>

              <!-- yef part -->
              <a
                href=""
                data-toggle="modal"
                data-target="#modal-shopping-cart"
                class="btn-wsp"
                target="_blank"
                title="ir al carrito"
              >
                <i class="fas fa-shopping-cart"></i
                ><span class="count_product">{{ products.length }}</span>
              </a>

              <div class="sticky-sidebar col-lg-3 col-md-4 col-sm-12 col-xs-12">
                <div
                  :class="{
                    car__container: scrollPosition,
                    toTopCar: toTopCar,
                  }"
                  style="max-width: 350px; margin-button: 500px"
                >
                  <div class="">
                    <div class="" id="divcart">
                      <h4 class="text-center">
                        <i class="icon-shopping-basket"></i>Detalle del pedido
                      </h4>
                      <shopping-cart
                        :showDetailCart="false"
                        @movecartlistshop="verifymovecart($event)"
                      ></shopping-cart>

                      <button
                        v-on:click.prevent="goPay"
                        class="btn btn-danger btn-md btn-block"
                      >
                        <i class="fas fa-shopping-cart"></i> Continuar S/.
                        {{ getCostProducts.toFixed(2) }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Main Section End -->
</template>
<script>
import ModalExtra from "@/components/modals/ModalExtra.vue";
import contentplaceholders from "@/components/contentplaceholders";
import ShoppingCart from "@/components/ShoppingCart.vue";
import SearchProduct from "@/components/SearchProduct.vue";
/* import swal from 'sweetalert'; */
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      /* router */
      id_category: this.$route.params.idCat,
      id_cart: this.$route.params.idCart,
      business_name: this.$route.params.empresa,

      allbusiness: [],
      currentlyBusiness: {},

      product_categories: [],
      product_cart: [],
      productsByCategory: [],
      showProductsByCategory: false,
      isloading: false,
      addProduct: false,
      /* for carrito */
      currently_product_select: null /* este es el objeto */,
      currently_temp_select: null /* objeto temporal */,
      producto: [],
      currentselect: false,
      currProd: null,
      sonproductData: [],
      showoption: false,
      idprod: null,
      filter: "",
      /* categories */
      CatSelected: null,
      CartsBusiness: [],
      /* note */
      Aditionalnote: "",
      checkedOptionnote: false,

      anyObject: null,
      scrollPosition: true,
      sizebox: null,
      sizetoCart: null,
      index: null,
      currShow: {},
      isActive: "all",
      isActiveCart: null,
      verifyChange: false,
      datafromsearcher: [],
      tocartintoscroll: true,
      toTopCar: false,
      commentModal: "",
    };
  },
  components: { ModalExtra, contentplaceholders, ShoppingCart, SearchProduct },
  watch: {
    $route() {
      this.getDataCategories(this.$route.params.idCart);
      this.getDataProductsCart(this.$route.params.idCart);
    },
  },
  created() {
    this.isloading = true;
    this.getDataToSeekBusines();
    this.getDataCategories(this.id_cart);
    this.getDataProductsCart(this.id_cart);
  },
  mounted() {
    this.goMainSectionMixin();
    window.addEventListener("scroll", this.updateScroll);
    this.verifytomoveornotcart();
  },

  updated() {
    if (!this.scrollPosition) {
      this.toTopCar = false;
    }
    if (window.innerWidth < 970) {
      this.scrollPosition = false;
    }
  },

  destroyed() {
    window.removeEventListener("scroll", this.updateScroll);
  },
  methods: {
    /* get data from api */
    getDataCategories(id_cart) {
      this.getDataCategoriesMixin(id_cart).then((response) => {
        this.product_categories = response.data.productoCategoria;
      });
    },

    getDataProductsCart(id_cart) {
      this.isloading = true;
      this.getDataProductsCartMixin(id_cart)
        .then((response) => {
          this.product_cart = response.data.productos;
        })
        .finally(() => {
          this.isloading = false;
          if (this.$route.params.codProductSelect) {
            setTimeout(() => {
              this.scrollMeTo(this.$route.params.codProductSelect);
            }, 500);
          }
          this.resizetoCart();
        });
    },

    getDataAnotherCart(cart) {
      if (this.isActiveCart.codcarta == cart.codcarta) {
        return;
      }
      this.isActiveCart = cart;
      this.$router.push({
        name: "carta",
        params: {
          idCat: this.id_category,
          idCart: cart.codcarta,
          empresa: this.currentlyBusiness.nombre,
        },
      });
    },

    getDataToSeekBusines() {
      this.getCategoryBusinessMixin(
        this.id_category,
        this.getUbicationMixin()
      ).then((response) => {
        this.allbusiness = response.data.empresas;
        this.searchCurrentlyBusiness();
      });
    },

    searchCurrentlyBusiness() {
      for (let i = 0; i < this.allbusiness.length; i++) {
        if (this.allbusiness[i].nombre == this.business_name) {
          this.currentlyBusiness = this.allbusiness[i];
          this.setcodEmpresaTemporaryMixin(this.currentlyBusiness.codempresa);
          this.getDataCarts(this.currentlyBusiness.codempresa);
          console.log(this.currentlyBusiness);
          /* if(this.currentlyBusiness.estado == 0){ //to enable
            this.$router.push({
              name: "Home"
            });
          } */
        }
      }
    },

    /* get products by category */
    getProductsbyCat(category) {
      this.showProductsByCategory = true;
      this.getProductsbyCategory(category.codproductocategoria);
      this.isActive = category;
      const searcher = document.querySelector("#input__searcher");
      searcher.value = "";
      this.datafromsearcher = [];
    },

    getProductsbyCategory(id_category) {
      this.isloading = true;
      this.getProductsbyCategoryMixin(id_category)
        .then((response) => {
          this.productsByCategory = response.data.productos;
        })
        .finally(() => {
          this.isloading = false;
          this.resizetoCart();
        });
    },
    /* get othercarts */
    getDataCarts(id_business) {
      this.getDataCartsBusinessMixin(id_business).then((response) => {
        this.CartsBusiness = response.data.cartas;
        this.seekCart();
      });
    },

    seekCart() {
      for (let i = 0; i < this.CartsBusiness.length; i++) {
        if (this.id_cart == this.CartsBusiness[i].codcarta) {
          this.isActiveCart = this.CartsBusiness[i];
        }
      }
    },
    /* add product features */
    sendOptions(product) {
      if (this.verifyChangeEmpresaMixin()) {
        this.anyObject = product;
        this.verifyChange = true;
      } else {
        this.verifyChange = false;
        this.sendOptionsCorrect(product);
      }
    },

    aceptClearBusiness() {
      this.clearBussinesLocalMixin();
      this.clearShoppingCartMixin();
      this.clearDistanceAndCostShipping();
      this.verifyChange = false;
      const element = document.getElementById(this.anyObject.titulo);
      element.click();
      document.body.style.padding = "0px";
      /* this.sendOptions(this.anyObject); */
      /* this.showoption = true */
      this.anyObject = null;
    },

    sendOptionsCorrect(product) {
      this.calculateCostProducts();
      this.currently_temp_select = product;
      this.currProd = product;
      if (this.currently_temp_select.personalizado == 1) {
        this.currently_temp_select = JSON.parse(JSON.stringify(product));
        this.currently_temp_select.precioantiguo = product.precio;
        if (this.currently_temp_select.preciopromocion != 0) {
          this.currently_temp_select.precioTachado =
            this.currently_temp_select.precio;
          this.currently_temp_select.precio =
            this.currently_temp_select.preciopromocion;
        }
        this.currently_temp_select.nuevo = 1;
        this.sonproductData = [];
        this.showoption = true;
      } else {
        this.addEmpresaMixin(this.currentlyBusiness);
        this.currently_product_select = this.currently_temp_select;
        this.currently_product_select.check = false;
        this.currently_product_select.nota = "";
        if (this.currently_product_select.preciopromocion != 0) {
          this.currently_product_select.precioTachado =
            this.currently_product_select.precio;
          this.currently_product_select.precio =
            this.currently_product_select.preciopromocion;
        }
        this.addProductMixin(this.currently_product_select);
        let position = this.product_cart.indexOf(product);
        this.$set(this.product_cart, position, product);
        this.verifytomoveornotcart();
        this.resizetoCart();
        this.successToastrMixin("Producto añadido", "");
      }
    },

    /* emits funcion-> get from ModalExtra*/
    closeModalAcept(event) {
      this.addEmpresaMixin(this.currentlyBusiness);
      this.currently_product_select = this.currently_temp_select;
      this.currently_product_select.nota = event[event.length - 1];
      console.log("nota",event[event.length - 1])
      event.pop();
      this.currently_product_select.selected = event;

      this.currently_product_select.precioadd = this.addPrice(
        this.currently_product_select
      );

      this.currently_product_select.precio =
        parseInt(this.currently_product_select.precioantiguo) +
        this.currently_product_select.precioadd;
      console.log("evento",event)
      console.log("nta",event[event.length - 1])
      
      this.currently_product_select.descripcion = this.addDescription(
        this.currently_product_select
      );
      //this.currently_product_select.descripcion = `${this.currently_product_select.opciones[0].titulocorto} (${event})`;

      var id = 0;
      for (let i = 0; i < this.product_cart.length; i++) {
        if (
          this.currently_product_select.codproducto ==
          this.product_cart[i].codproducto
        ) {
          id = i;
        }
      }
      if (this.currently_product_select.preciopromocion != 0) {
        this.currently_product_select.precioTachado =
          this.currently_product_select.precio;
        this.currently_product_select.precio =
          parseInt(this.currently_product_select.preciopromocion) +
          this.currently_product_select.precioadd;
      }
      /* add array mixing */
      if (this.idprod != null) {
        /* change in mixin and products [] */
        this.products[this.idprod] = this.currently_product_select;
        localStorage.setItem("CART", JSON.stringify(this.products));
        this.addProductMixin(this.currently_product_select, true);
        this.idprod = null;
        this.successToastrMixin("Producto Actualizado", "");
      } else {
        this.addProductMixin(this.currently_product_select);
        this.successToastrMixin("Producto añadido", "");
      }
      this.calculateCostProducts();
      this.$set(this.product_cart, id, this.currProd);
      this.showoption = false;

      this.verifytomoveornotcart();
      this.resizetoCart();
      /* if(window.innerHeight<) */
    },
    verifytomoveornotcart() {
      setTimeout(() => {
        let cartsize = document.querySelector("#divcart").clientHeight;
        if (cartsize + 363 - 20 - 300 > window.innerHeight) {
          /* margin 20 */
          this.tocartintoscroll = false;
          this.scrollPosition = false;
          this.toTopCar = false;
        } else {
          this.tocartintoscroll = true;
        }
      }, 500);
    },

    addPrice(prod) {
      console.log("ADD PRICE OPCIONES",prod)
      var options = prod.opciones;
      var costadd = 0;
      for (let i = 0; i < options.length; i++) {
        let details = options[i].detalles;
        for (let j = 0; j < details.length; j++) {
          for (let k = 0; k < prod.selected.length; k++) {
            if (details[j].titulo == prod.selected[k]) {
              costadd += parseFloat(details[j].precio);
            }
          }
        }
      }
      return costadd;
    },
    addDescription(prod) {
      var options = prod.opciones;
      var texto = "";
      for (let i = 0; i < options.length; i++) {
        let details = options[i].detalles;
        texto += options[i]['titulocorto'] + "(";
        var textoDetalle="";
        for (let j = 0; j < details.length; j++) {
          for (let k = 0; k < prod.selected.length; k++) {
            if (details[j].titulo == prod.selected[k]) {
              textoDetalle=textoDetalle + details[j].titulo + ", ";
            }
          }
        }
      texto += textoDetalle.substring(0, textoDetalle.length - 2);
      texto += ") ";
      }
      var arrayString = texto.trim().split(' ');
      var newString = "";
      for (let i = 0; i < arrayString.length; i++) {
        const search = arrayString[i].indexOf("()");
        if (search == -1) {
          newString += arrayString[i] + " ";
        }
      }

      return newString.trim();
    },
    justclose() {
      this.showoption = false;
    },
    /* add + substract select product */

    addProductToMixin(product, fproduct) {
      if (!this.verifyChangeEmpresaMixin()) {
        this.addProductMixin(product);

        if (this.showProductsByCategory) {
          let position = this.productsByCategory.indexOf(fproduct);
          this.$set(this.productsByCategory, position, fproduct);

          let positioncart = this.product_cart.indexOf(fproduct);
          this.$set(this.product_cart, positioncart, fproduct);
          this.calculateCostProducts();
        } else {
          let position = this.product_cart.indexOf(fproduct);
          this.$set(this.product_cart, position, fproduct);
          this.calculateCostProducts();
        }
        this.successToastrMixin("Producto Actualizado", "");
      } else {
        this.anyObject = product;
        this.verifyChange = true;
      }
    },

    subtractProductsToMixin(product, index, fproduct) {
      if (!this.verifyChangeEmpresaMixin()) {
        const position = this.product_cart.indexOf(fproduct);
        if (product) {
          this.products[index].count -= 1;

          if (this.products[index].count < 1) {
            if (fproduct.precioTachado) {
              fproduct.precio = fproduct.precioTachado;
            }
            this.products.splice(index, 1);
            this.setToCartMixin(this.products);
            localStorage.setItem("CART", JSON.stringify(this.products));
            if (this.products.length == 0) {
              this.clearShoppingCartMixin();
            }
            this.verifytomoveornotcart();
          }

          if (this.showProductsByCategory) {
            let position = this.productsByCategory.indexOf(fproduct);
            this.$set(this.productsByCategory, position, fproduct);

            let positioncart = this.product_cart.indexOf(fproduct);
            this.$set(this.product_cart, positioncart, fproduct);
            localStorage.setItem("CART", JSON.stringify(this.products));
            this.calculateCostProducts();
          } else {
            localStorage.setItem("CART", JSON.stringify(this.products));
            this.$set(this.product_cart, position, fproduct);
          }
          this.successToastrMixin("Producto Actualizado", "");
          this.calculateCostProducts();
        }
        this.resizetoCart();
      } else {
        this.anyObject = product;
        this.verifyChange = true;
      }
    },
    /* addValidate->for validate if show or not the button "añadir" */
    addValidate(product) {
      if (product.count) {
        if (product.count > 0 && product.personalizado != 1) {
          return false;
        }
      }
      return true;
    },

    showDetails(product) {
      for (let i = 0; i < this.products.length; i++) {
        if (product.codproducto == this.products[i].codproducto) {
          if (product.personalizado == 1) {
            return false;
          }
          return true;
        }
      }
      return false;
    },
    /* show modal */
    showmodal() {
      this.currentselect = true;
    },
    /* send detail to modalextra */
    retDetails() {
      if (this.currently_temp_select) {
        var details = [];
        for (let i = 0; i < this.currently_temp_select.opciones.length; i++) {
          var addA = [];
          for (
            let j = 0;
            j < this.currently_temp_select.opciones[i].detalles.length;
            j++
          ) {
            addA.push(
              this.currently_temp_select.opciones[i].detalles[j].titulo
            );
          }
          details.push(addA);
        }
        return details;
      }
      return;
    },

    findProductcar(product, prod) {
      if (product.opciones) {
        this.currently_temp_select = JSON.parse(JSON.stringify(prod));
        this.currProd = product;
        this.sonproductData = prod.selected;
        this.idprod = this.products.indexOf(prod);
        this.showoption = true;
      }
    },

    showCart_or_Category() {
      if (!this.datafromsearcher.length > 0) {
        if (this.showProductsByCategory) {
          this.resizetoCart();
          return this.filteredbyCategories;
        }
        this.resizetoCart();
        return this.filteredProducts;
      } else {
        this.resizetoCart();
        return this.datafromsearcher;
      }
    },

    hasPersonalizado(product) {
      if (product.personalizado != 0) {
        this.sendOptions(product);
      }
    },

    addNote(product) {
      var pos = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (product.codproducto == this.products[i].codproducto) {
          pos = i;
        }
      }
      this.products[pos].nota = this.Aditionalnote;
      this.Aditionalnote = "";
    },

    verifyToShow(product) {
      if (this.products.length > 0) {
        if (
          product.codproducto ==
          this.products[this.products.length - 1].codproducto
        ) {
          return true;
        }
      }
      return false;
    },

    checkedOption(prod) {
      this.anyObject = prod;
      if (prod.nota) {
        this.commentModal = prod.nota;
      } else {
        this.commentModal = "";
      }
      var index = this.products.indexOf(prod);
      prod.check = !prod.check;
      this.$set(this.products, index, prod);

      setTimeout(() => {
        this.$refs.commentInput.focus();
        console.log(this.$refs.commentInput);
      }, 700);
    },

    updateNote(e, prod) {
      prod.nota = e.target.value;
      //prod.descripcion = prod.nota
      var pos = this.products.indexOf(prod);
      this.$set(this.products, pos, prod);
      this.addProductMixin(prod, true);
    },
    updateNotemodal(e) {
      //prod.descripcion = prod.nota
      var pos = this.products.indexOf(this.anyObject);
      this.anyObject.nota = e.target.value;
      this.$set(this.products, pos, this.anyObject);
      this.addProductMixin(this.anyObject, true);
    },

    updateScroll() {
      if (this.showCart_or_Category().length > 12 && window.scrollY > 310) {
        if (this.tocartintoscroll) {
          if (window.scrollY > 310) {
            this.toTopCar = true;
          }
          if (window.scrollY > this.sizetoCart) {
            if (this.scrollPosition) {
              this.scrollPosition = false;
              this.toTopCar = false;
            }
          }
          if (window.scrollY < this.sizetoCart) {
            if (!this.scrollPosition) {
              this.scrollPosition = true;
            }
          }
        }
      } else {
        if (this.scrollPosition) {
          this.scrollPosition = false;
          this.toTopCar = false;
        }
      }
    },

    tosubString(description) {
      if (description && description.length > 170) {
        return description.substring(0, 170);
      }
      return description;
    },

    toshowimg(product) {
      this.currShow = {
        title: product.titulo,
        description: product.descripcion,
        src: this.urlImgMixin + product.foto,
      };
    },

    changetoshowall() {
      this.showProductsByCategory = false;
      this.datafromsearcher = [];
      const searcher = document.querySelector("#input__searcher");
      searcher.value = "";
      this.isActive = "all";
    },

    seealldescription(product) {
      if (this.showProductsByCategory) {
        const index = this.productsByCategory.indexOf(product);
        product.descripcionAll = !product.descripcionAll;
        this.productsByCategory[index] = product;
        this.$set(this.productsByCategory, index, product);
      } else {
        const index = this.product_cart.indexOf(product);
        product.descripcionAll = !product.descripcionAll;
        this.product_cart[index] = product;
        this.$set(this.product_cart, index, product);
      }
      if (product.descripcionAll) {
        let divproduct = document.getElementById(product.codproducto);
        divproduct.style.height = "230px";
      } else {
        let divproduct = document.getElementById(product.codproducto);
        divproduct.style.height = "137px";
      }
    },
    scrollMeTo(idDiv) {
      var element = document.querySelector(`[id='${idDiv}']`);
      element.scrollIntoView();
      element.style.border = "2px dotted red";
      setTimeout(() => {
        element.style.border = "none";
      }, 2000);
    },
    resultsFromSearcher(event) {
      this.datafromsearcher = event;
    },
    /* call emit from listproducts component*/
    verifymovecart(event) {
      if (event) {
        this.tocartintoscroll = true;
        this.scrollPosition = true;
        if (window.scrollY > 300) {
          this.toTopCar = true;
        } else {
          this.scrollPosition = false;
        }
        console.log("widnowscroll", window.scrollY);
      } else {
        console.log("wtf");
        this.tocartintoscroll = false;
        this.scrollPosition = false;
        this.toTopCar = true;
      }
      this.resizetoCart();
    },
    /* all resize till move car */
    resizetoCart() {
      setTimeout(() => {
        this.sizetoCart =
          document.querySelector("#productsContent").clientHeight -
          document.querySelector("#divcart").clientHeight +
          300;
        console.log("new size", this.sizetoCart);
      }, 1000);
    },
    /* emit come from searcher */
    startLoading(event) {
      if (event) {
        this.isloading = true;
      } else {
        this.isloading = false;
      }
    },
    aceptmodalcomment() {
      this.anyObject = null;
      this.commentModal = "";
    },
    // mutations
    ...mapMutations("shoppingCart", [
      "calculateCostProducts",
      "clearDistanceAndCostShipping",
    ]),

    goPay() {
      console.log("this.isAuthenticatedMixin", this.isAuthenticatedMixin);
      console.log("this.getDefaultAddressMixin", this.getDefaultAddressMixin());
      if (this.isAuthenticatedMixin && this.getDefaultAddressMixin()) {
        if (this.getCountProducts > 0) {
          this.$router.push({ name: "checkout" });
        } else {
          this.warningToastrMixin("Canasta", "Su canasta está vacío.");
        }
      } else if (this.isAuthenticatedMixin && !this.getDefaultAddressMixin()) {
        this.warningToastrMixin("Dirección", "Registre una dirección.");
        window.$("#modalAddress").modal("show");
      } else {
        window.$("#modal-shopping-cart").modal("hide");
        if (this.getCountProducts < 1) {
          this.warningToastrMixin("Canasta", "Su canasta está vacío.");
        } else {
          window.$("#modalLogin").modal("show");
        }
      }
    },
  },
  computed: mapState("shoppingCart", {
    // state
    products: (state) => state.products,
    // getters
    ...mapGetters("shoppingCart", ["getCostProducts", "getCountProducts"]),
    watchdat() {
      if (this.watchdata) {
        return false;
      }
      return true;
    },
    filteredProducts() {
      if (!this.filter) {
        let list = this.product_cart;
        for (let i = 0; i < list.length; i++) {
          var count = 0;
          for (let j = 0; j < list.length; j++) {
            if (list[i].codproducto == list[j].codproducto) {
              count += 1;
              if (count > 1) {
                list.splice(i, 1);
              }
            }
          }
        }
        return list;
      }
      let list = this.product_cart.filter((p) =>
        p.titulo.toLowerCase().includes(this.filter.toLowerCase())
      );
      for (let i = 0; i < list.length; i++) {
        if (list[i + 1]) {
          if (list[i].codproducto == list[i + 1].codproducto) {
            list.splice(i + 1, 1);
          }
        }
      }
      return list;
    },
    filteredbyCategories() {
      if (!this.filter) {
        return this.productsByCategory;
      }
      return this.productsByCategory.filter((p) =>
        p.titulo.toLowerCase().includes(this.filter.toLowerCase())
      );
    },
    getStyles() {
      if (this.currentlyBusiness.imagen != undefined) {
        return (
          "height: 250px; width: 100%; background: linear-gradient(to bottom, transparent, black), url(https://zcode10.com/uploads/" +
          this.currentlyBusiness.imagen.replace(new RegExp(" ", "g"), "%20") +
          ");  background-size: cover;"
        );
      }
      console.log("image loading...");
      return "height: 250px; width: 100%; background: linear-gradient(to bottom, transparent, black);";
    },
    getStylescenter() {
      if (this.currentlyBusiness.imagen != undefined) {
        return (
          "position: absolute; top: 68px; height: 250px; left: 500px; width: 800px; background: linear-gradient(to bottom, transparent, black), url(https://zcode10.com/uploads/" +
          this.currentlyBusiness.imagen.replace(new RegExp(" ", "g"), "%20") +
          "); background-size: cover;"
        );
      }
      return "";
    },
  }),
};
</script>
<style scoped src="@/css/Carta.css"></style>
<style scoped src="@/css/style-inputs.css"></style>
