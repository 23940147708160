<template>
  <div class="modal fade  come-from-modal right" id="modalProfile" tabindex="-1" role="dialog" aria-labelledby="modalProfile">
    <div class="modal-dialog" role="document" id="modal-profile-size">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" id="closeButtonModalPendingOrders" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="fas fa-times-circle"></i></span></button>
          <h4 class="modal-title" id="modalProfile">Modifica tus datos</h4>
        </div>
        <div class="modal-body">
          <div v-if="list == 1">
            <span v-show="false">{{ initUserdate }}</span>

            <div class="row">
              <div class="col-xs-12">
                <div class="block-form">
                  <div class="container-icon-form">
                    <i class="fas fa-user-friends"></i>
                  </div>
                  <div class="input-form-icon">
                    <div class="row">
                      <div class="col-12 input-effect">
                        <input v-model="form.fullname" :class="{'has-error': formErrors.fullname.error, 'has-content' : hasTextOnInputMixin(form.fullname)}" class="effect-16 input-style" type="text" placeholder="">
                        <label class="effect-label">Nombres y apellidos</label>
                        <span class="focus-border"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12">
                <span class="text-danger" ole="alert">{{ formErrors.fullname.error_msg }}</span>
              </div>

              <div class="col-xs-12">
                <div class="block-form">
                  <div class="container-icon-form">
                    <i class="fas fa-phone-alt"></i>
                  </div>
                  <div class="input-form-icon">
                    <div class="row">
                      <div class="col-12 input-effect">
                        <input v-model="form.telefono" :class="{'has-error': formErrors.telefono.error, 'has-content' : hasTextOnInputMixin(form.telefono)}" class="effect-16 input-style" type="number" placeholder="">
                        <label class="effect-label">Teléfono</label>
                        <span class="focus-border"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12">
                <span class="text-danger" ole="alert">{{ formErrors.telefono.error_msg }}</span>
              </div>

            <!--<div class="col-xs-12">
              <div class="block-form">
                <div class="container-icon-form">
                  <i class="fas fa-at"></i>
                </div>
                <div class="input-form-icon">
                  <div class="row">
                    <div class="col-12 input-effect">
                      <input v-model="form.direccion" :class="{'has-error': formErrors.direccion.error, 'has-content' : hasTextOnInputMixin(form.direccion)}" class="effect-16 input-style" type="email" placeholder="">
                      <label class="effect-label">Email</label>
                      <span class="focus-border"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12">
              <span class="text-danger" ole="alert">{{ formErrors.direccion.error_msg }}</span>
            </div>

            <div class="col-xs-12">
              <div class="block-form">
                <div class="container-icon-form">
                  <i class="far fa-calendar-alt"></i>
                </div>
                <div class="input-form-icon">
                  <div class="row">
                    <div class="col-12 input-effect">
                      <input v-model="form.birthdate" :class="{'has-error': formErrors.birthdate.error, 'has-content' : hasTextOnInputMixin(form.birthdate)}" class="effect-16 input-style" type="date" placeholder="">
                      <label class="effect-label">Fecha de nacimiento</label>
                      <span class="focus-border"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12">
              <span class="text-danger" ole="alert">{{ formErrors.birthdate.error_msg }}</span>
            </div>-->
          </div>

          <span v-show="false">
            <div class="text-center option-validate-code">
              <label class="radio-inline">
                <input v-model="typeVerify" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="whatsapp"> Verificar vía Whatsapp
              </label>
              <label class="radio-inline">
                <input v-model="typeVerify" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="sms"> Verificar vía SMS
              </label>
            </div>
          </span>
          <button v-on:click.prevent="beforeUpdateUser" class="btn btn-danger btn-lg btn-block" style="margin-top: 10px;"><i class="fas fa-check"></i> Continuar</button>
        </div>

        <div v-else class="center-block width-center">
          <div class="text-center">
            <p>Enviamos un código de verificación de 6 digitos a +51 {{ form.telefono }}</p>
          </div>
          <div class="center-block">
            <div class="input-wrapper">
              <PincodeInput
              v-model="validationCode"
              placeholder="0"
              :length="lengthInputPhone"
              />
            </div>
            <span class="text-danger" ole="alert">{{ errorValidationCode_msg }}</span>
            <div style="margin-bottom: 30px;"></div>
          </div>
          <button v-on:click.prevent="validationCodeWhatsapp(true)" class="btn btn-danger btn-lg btn-block"><i class="fas fa-check"></i> Verificar</button>
        </div>

      </div>
    </div>
  </div>
</div>
</template>

<style scoped src="@/css/style-inputs.css"></style>
<style scoped>
.block-form {
  display:flex;
}
.container-icon-form {
  font-size: 30px;
  padding: 20px 20px 15px 0px;
}
.input-form-icon {
  width: 100%;
  display: inline-block;
  vertical-align: top;
}
.option-validate-code {
  padding-top: 5px;
  padding-bottom: 15px;
}
.width-center{
  padding-top: 20px;
  width: 460px;
}
#modal-profile-size {
  width: 50% !important;
}
@media screen and (min-width: 100px){
  #modal-profile-size {
    width: 93% !important;
  }
}
@media screen and (min-width: 550px){
  #modal-profile-size {
    width: 80% !important;
  }
}
@media screen and (min-width: 650px){
  #modal-profile-size {
    width: 500px !important;
  }
}
</style>

<script>
import { mapState } from 'vuex';
import PincodeInput from 'vue-pincode-input';

export default {
  name: 'ModalProfile',

  components: { PincodeInput },

  data() {
    return {
      list: 1,
      typeVerify: 'whatsapp',
      validationCode: '',
      lengthInputPhone: 6,
      errorValidationCode_msg: '',
      form: {
        //'direccion': '',
        'telefono': '',
        'fullname': '',
        //'birthdate': ''
      },
      formErrors: {
        direccion: {
          error: false,
          error_msg: '',
        },
        telefono: {
          error: false,
          error_msg: '',
        },
        fullname: {
          error: false,
          error_msg: '',
        },
        birthdate: {
          error: false,
          error_msg: '',
        },
        policy: {
          error: false,
          error_msg: '',
        },
      },
    }
  },

  created() {

  },

  computed: mapState({
    user: state => state.user.user,
    initUserdate() {
      if (this.user) {
        this.form.fullname = this.user.fullname;
        if (this.user.telefono.length > 9) {
          this.form.telefono = this.user.telefono.substr(2, 11);
        }
        else {
          this.form.telefono = this.user.telefono;
        }
        //this.form.direccion = this.user.direccion;
        //this.form.birthdate = this.user.birthdate;
        return 1;
      }
      else {
        return 0;
      }
    }
  }),

  methods: {
    beforeUpdateUser() {
      this.validateForm();
      if (!this.formErrors.telefono.error && !this.formErrors.fullname.error) {
        if (!this.isModifyPhone()) {
          this.updateUser();
        }
        else {
          this.list = 0;
          this.sendCodeWhatsapp(this.form.telefono);
          console.log('is a new phone number')
        }
      }
    },

    sendCodeWhatsapp(phoneNumber) {
      let phone = {'telefono': '51'+phoneNumber}
      this.loginUserWithCodeWhatsappMixin(phone).then(response => {
        console.log('code whatsapp', response)
        this.successToastrMixin('Código', 'Código enviado vía WhatsApp.');
      }).catch((error) => {
        console.log(error);
        this.errorToastrMixin('Error', 'Código no enviado.');
      });
    },

    validationCodeWhatsapp() {
      let phone_code = {
        'telefono': '51'+this.form.telefono,
        'codigo': this.validationCode
      };
      this.verifyCodeWhatsappMixin(phone_code).then(response => {
        console.log('responde code validated', response);
        this.updateUser();
      }).catch((error) => {
        console.log(error);
        this.errorToastrMixin('Error', 'Error al verificar el código, vuelva a intentar más tarde.');
        this.errorValidationCode_msg = "Código incorrecto."
      });
    },

    updateUser() {
      this.form.telefono = '51'+this.form.telefono;
      this.updateUserMixin(this.user.codusuario, this.form, this.$store.state.user.token).then(response => {
        console.log('response update', response);
        this.successToastrMixin('Usuario', 'Datos actualizados con éxito.');
        localStorage.setItem('token', response.headers.token);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        this.setNewTokenMixin(response.headers.token);
        this.setNewUserMixin(response.data.user);
        this.$store.commit('user/setCurrentUser', response.data.user);
        this.$store.commit('user/setToken', response.headers.token);
        window.$("#modalProfile").modal("hide");
        this.list = 1;
      }).catch(error => {
        console.log(error);
        this.errorToastrMixin('Error', 'Ocurrio un error, vuelva a intentar más tarde');
        this.form.telefono = this.form.telefono.substr(2, 11);
      });
    },


    isModifyPhone() {
      if (this.user.telefono != '51'+this.form.telefono) {
        return true;
      }
      else {
        return false;
      }
    },

    validateForm() {
      /*const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
      if (!emailRegex.test(this.form.direccion)) {
        this.formErrors.direccion.error_msg = 'Ingrese correctamente su email.';
        this.formErrors.direccion.error = true;
      }
      else {
        this.formErrors.direccion.error_msg = '';
        this.formErrors.direccion.error = false;
      }*/
      const acceptedValue = /^[0-9]+$/;
      if (this.form.telefono === '' || this.form.telefono.length < 9 || this.form.telefono.length > 9 || !this.form.telefono.match(acceptedValue)) {
        this.formErrors.telefono.error_msg = 'Ingrese correctamente su número de celular.';
        this.formErrors.telefono.error = true;
      }
      else {
        this.formErrors.telefono.error_msg = '';
        this.formErrors.telefono.error = false;
      }
      if (this.form.fullname === '') {
        this.formErrors.fullname.error_msg = 'Ingrese su nombre completo.';
        this.formErrors.fullname.error = true;
      }
      else {
        this.formErrors.fullname.error_msg = '';
        this.formErrors.fullname.error = false;
      }
      /*if (this.form.birthdate === '') {
        this.formErrors.birthdate.error_msg = 'Ingrese su fecha de nacimiento.';
        this.formErrors.birthdate.error = true;
      }
      else {
        this.formErrors.birthdate.error_msg = '';
        this.formErrors.birthdate.error = false;
      }*/
    },
  }
}
</script>