<template>
  <div class="modal fade  come-from-modal right" id="modalLogin" tabindex="-1" role="dialog" aria-labelledby="modalLogin">
    <div class="modal-dialog" role="document" id="modal-login-size">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" id="closeButtonModalLogin" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="fas fa-times-circle"></i></span></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xs-12 text-center">
              <img :src="require('@/assets/images/MOTO.png')" class="img-list wp-post-image" alt="avatar-z" width="120px" style="margin-top: -20px;">
              <h4 class="py-3-h h4">{{ titleModal }}</h4>
            </div>

            <template v-if="hasPhoneNumberOrEmail === 1 || hasPhoneNumberOrEmail === 0">
              <!-- enviar SMS or codigo whatsapp al celular dado -->
              <div v-show="!validadCodeView" class="col-xs-12">
                <div class="center-block width-center">
                  <span v-show="hasPhoneNumberOrEmail !== 0">
                    <button v-on:click.prevent="loginWithFacebook" id="loginWithFacebook" class="center-block btn btn-block  btn-facebook"><i class="fab fa-facebook-square"></i> Facebook</button>
                    <button v-on:click.prevent="loginWithGoogle" id="loginWithGoogle" class="center-block btn btn-block btn-google">
                      <img class="img-list wp-post-image color-img" :src="require('@/assets/images/google.png')" alt="Perú" width="20px;"> Google
                    </button>
                    <h4 class="text-center">O</h4>
                  </span>
                  <div class="block-phone">
                    <div class="country-icon">
                      <img class="img-list wp-post-image" :src="require('@/assets/svg/peru.svg')" alt="Perú" width="30px;">
                      <span class="text-number">+51</span>
                      <span class="caret"></span>
                    </div>
                    <div class="phone-input">
                      <input v-model="phone" :class="{'has-error': error}" type="number" class="" placeholder="Escribe tu número">
                    </div>
                  </div>
                  <span class="text-danger" ole="alert">{{ error_msg }}</span>
                  <br>

                  <div class="text-xs-center">
                    <div id="recaptcha-container" class="g-recaptcha"></div>
                  </div>

                  <div class="center-block" style="padding-top: 7px;">
                    <h5 class="text-center btn-red-send">Enviar código por:</h5>
                  </div>

                  <div class="container-buttons">
                    <button v-on:click.prevent="sendCodeWhatsapp(phone, true)" class="btn btn-success btn-whatsapp">
                      <i class="fab fa-whatsapp" style="font-size: 22px;"></i>
                      <div class="btn-text">WhatsApp</div>
                    </button>
                    <button v-on:click.prevent="sendSMS" class="btn btn-link btn-sms">
                      <i class="far fa-comment-alt" style="font-size: 20px;"></i>
                      <div class="btn-text">SMS</div>
                    </button>
                  </div>

                  <div v-show="hasPhoneNumberOrEmail !== 0" class="text-center footer-link" style="padding-top: 5px;">
                    <span class="">¿No tienes una cuenta?</span>
                    <a v-on:click.prevent="goRegister" href="" class="btn-red-send" style="font-size: 14px;"> Registrate</a>
                  </div>
                </div>
              </div>
              <!-- End enviar SMS or codigo whatsapp al celular dado -->
              <!-- Validad SMS or codigo whatsapp enviado-->
              <div v-show="validadCodeView" class="col-xs-12">
                <div class="center-block width-center">
                  <div class="text-center">
                    <p>Enviamos un código de verificación de 6 digitos a +51 {{ phone }}</p>
                  </div>
                  <div class="center-block">
                    <div class="input-wrapper">
                      <PincodeInput
                      v-model="validationCode"
                      placeholder="0"
                      :length="lengthInputPhone"
                      />
                    </div>
                    <span class="text-danger" ole="alert">{{ errorValidationCode_msg }}</span>
                    <div style="margin-bottom: 30px;"></div>
                  </div>

                  <button v-if="isSMS" v-on:click.prevent="validationCodeLogin" class="btn btn-danger btn-lg btn-block" style="padding: 16px 16px;"><i class="fas fa-arrow-right"></i> Continuar</button>
                  <button v-else v-on:click.prevent="validationCodeLoginWhatsapp(true)" class="btn btn-danger btn-lg btn-block" style="padding: 16px 16px;"><i class="fas fa-arrow-right"></i> Continuar</button>

                  <div class="text-center resend center-block">
                    <span>¿No has recibido el código?</span><br>
                    <a v-on:click.prevent="resetCaptcha" href=""><strong>Reenviar código</strong></a>
                  </div>
                </div>
              </div>
              <!-- End Validad SMS or codigo whatsapp enviado-->
            </template>

            <template v-else>
              <div class="col-xs-12">
                <div class="center-block width-center">
                  <div class="row">
                   <div class="col-xs-12">
                    <div class="text-center">
                      <h3>Para finalizar</h3>
                      <p>Ingrese su email</p>
                      <br>
                    </div>
                    <div class="row">
                      <div class="col-6 input-effect">
                        <input v-model="formLogin.direccion" :class="{'has-error': formLoginErrors.direccion.error, 'has-content' : hasTextOnInputMixin(formLogin.direccion)}" class="effect-16 input-style" placeholder="" type="email" required>
                        <label class="effect-label">Email</label>
                        <span class="focus-border"></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12">
                    <span class="text-danger" ole="alert">{{ formLoginErrors.direccion.error_msg }}</span>
                  </div>
                </div>
              </div>
              <br>
              <button v-on:click.prevent="addEmailUser" class="btn btn-primary btn-lg btn-block" style="padding: 8px 8px;">Verificar</button>
            </div>
          </template>

        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped src="@/css/style-modals-login.css"></style>
<style scoped src="@/css/style-inputs.css"></style>
<style scoped>
.text-xs-center {
  text-align: center;
}
.g-recaptcha {
  display: inline-block;
}
.container-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.btn-whatsapp {
  margin: 12px 0 !important;
  width: 100% !important;
  height: 45px !important;
  display: flex !important;
  align-items: center !important;
  letter-spacing: 1px !important;
  margin-right: 15px !important;
  background-color: #0cc665;
  border-radius: 8px;
  font-family: Poppins-Regular,Helvetica,Arial,sans-serif;
  color: #fff;
  padding: 8.5px 14px;
}
.btn-text {
  font-family: Poppins-SemiBold,Helvetica,Arial,sans-serif;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.btn-sms {
  text-decoration: none !important;
  margin: 12px 0 !important;
  width: 100% !important;
  height: 45px !important;
  display: flex !important;
  align-items: center !important;
  letter-spacing: 1px !important;
  color: rgba(112,105,103,.8);
  background-color: transparent;
  font-family: Poppins-Regular,Helvetica,Arial,sans-serif;
  border-radius: 8px;
  border: 1px solid rgba(112,105,103,.8);
  padding: 0 10px;
  box-sizing: border-box;
}
</style>
<style>
.input-wrapper {
 display: flex !important;
 justify-content: center !important;
 margin-left: auto !important;
 margin-right: auto !important;
 margin-top: 15px;
}
input.vue-pincode-input {
  height: 60px !important;
  max-width: 80px !important;
  font-size: 18px !important;
}
</style>

<script>
import PincodeInput from 'vue-pincode-input';
import firebase from 'firebase/app';
import loginAndCreateUser from '@/mixins/local/loginAndCreateUser.js';
import { mapState } from 'vuex';

export default {
  name: 'ModalLogin',

  components: { PincodeInput },

  mixins: [loginAndCreateUser],

  data() {
    return {
    }
  },

  mounted() {
    // Start Firebase invisible reCAPTCHA verifier
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'normal',
      'callback': () => {
        this.captchaIsResolve = true;
      },
    });
    window.recaptchaVerifier.render().then((widgetId) => {
      window.recaptchaWidgetId = widgetId;
    });
  },

  computed: mapState({
    isAuthWithFacebook: state => state.user.isAuthWithFacebook,
  }),

  methods: {
    goRegister() {
      window.$("#modalLogin").modal("hide");
      window.$("#modalRegisterUser").modal("show");
    },

    loginWithGoogle() {
      var provider = new firebase.auth.GoogleAuthProvider();
      //provider.addScope('https://www.googleapis.com/auth/user.phonenumbers.read');

      firebase.auth().signInWithPopup(provider).then((result) => {
        console.log('result google', result);
        this.formFacebookOrGoogle.direccion = result.user.email;
        this.formFacebookOrGoogle.telefono = result.user.phoneNumber;
        this.formFacebookOrGoogle.fullname = result.user.displayName;

        //verificar si existe este usuario
        let phone_email = {
          'telefono': this.formFacebookOrGoogle.telefono,
          'direccion': this.formFacebookOrGoogle.direccion
        };

        this.varifyUserFacebookOrGoogle(phone_email, result);

      }).catch((error) => {
        console.log(error);
      });
    }

  }
}
</script>