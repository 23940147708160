<template>
<div style="background: white;">
  <div class="modal fade menu-extras-modal" id="extras-0-1" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
		<div class="modal-dialog" role="document">
      <div class="container_modal">
        <div class="img_modal">
          <img :src="urlImgMixin + prodSelect.foto" alt="product image" class="img_product">
        </div>
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal()"><span aria-hidden="true"><i class="fas fa-times-circle"></i></span></button>

            <h2><a style="font-family: 'Poppins', sans-serif !important;">Extras {{ prodSelect.titulo.length>20 ? '' : prodSelect.titulo }} </a></h2>
          </div>
          <div class="modal-body">
            <div class="menu-selection-container">
              <div class="menu__section">
                <div class="extras-detail-main" v-for="(option, index) in options" :key="option.codopcion">
                  <h3>{{ option.titulo }}<small style="color: #c33332">{{ option.obligatorio ? ' ' : ''}}</small></h3>
                  <div v-if="startValidation && !(count[index] >= option.min) && option.obligatorio" class="cont-view-product">ESTA SELECCIÓN ES OBLIGATORIA</div>
                  <div class="extras-detail-options" style="display: block;">
                    <div class="extras-detail-at">
                      <div v-for="(detail, idexin) in option.detalles" :key="detail.codtopciondetalle">
                        <input type="checkbox" :id="detail.titulo" class="foodbakery_restaurant_category" v-model="datamodel"
                          :value="detail.titulo" :disabled="disabled(index, detail.codtopciondetalle)" @click="blockStatus(index, option, idexin, detail.codtopciondetalle)"
                          :checked="checkoutData(detail.titulo)">
                        <label class="label-titulo" :class="{'label_disabled': disabled(index, detail.codtopciondetalle)}"
                        :for='detail.titulo' style="cursor: pointer" >{{ detail.titulo }} <span class="extendPrice">{{ detail.precio != 0 ? "+ s/. " +detail.precio : '' }}</span></label>
                      </div>
                    </div>
                  </div>
                  <div class="extras-detail-selected"></div>
                </div>
                <div class="extras-detail-main">
                  <h3 style="text-transform: lowercase;">¿Quieres aclarar algo?</h3>
                  <div class="row input-container" style="margin-left: -8px;">
                    <div class="col-12 input-effect">
                      <input
                      style="font-size: 12px;"
                      v-model="nota"
                      type="text"
                      class="effect-16 input-style"
                      :class="{'has-content' : hasTextOnInputMixin(nota)}"
                      placeholder=""
                      maxlength="70"
                      autocomplete="off">
                      <label class="effect-label" style="font-weight: 300;">Notas al producto</label>
                      <span class="focus-border"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="extras-btns-holder" style="display: flex; justify-content: center; height: 42px; width: 345px;">
                <button type="button" :data-dismiss="!validMins() ? 'modal': ''" :aria-label="!validMins() ? 'Close': ''" @click="sendData()"  class="btn__add"
                  style="background: #0cc665 !important; text-transform: none;"><span aria-hidden="true">Agregar a mi pedido</span></button>
              </div>
            </div>
          </div>
        </div>
      </div>
		</div>
	</div>

</div>
</template>
<script>
export default {
  name: 'ModalExtra',
  data(){
    return{
      checkedOptions: [],
      /* details: [], */
      count: [],
      check: [],
      indox: [],
      idein: [],
      reset: [],
      datamodel: [],
      nota:'',
      removecount: true,
      startValidation: false,
    }
  },
  props:{
    options:{
      type: Array,
      default: () => []
    },
    details:{
      type: Array,
      default: () => []
    },
    selected:{
      type: Array,
      default: () => []
    },
    prodSelect: {
      type: Object
    }
  },
  created(){
    this.datamodel = this.selected
  },
  mounted(){
    this.countData()
  },
  methods: {

    blockStatus(index, option, indexin, codDetail){
      if(!this.checkedOptions.includes(option.detalles[indexin].titulo)){
        this.checkedOptions.push(option.detalles[indexin].titulo)
      }else{
        var id = this.checkedOptions.indexOf(option.detalles[indexin].titulo)
        this.checkedOptions.splice(id, 1)
      }
      var num =0 /* click mean 1 element select */
      for(let j =0; j< this.checkedOptions.length; j++){
        if(this.details[index].includes(this.checkedOptions[j])){
          num += 1
        }
      }
      /* save how many is each case, for validate min value */
      this.count[index] = num

      if(option.max < num +1){
        this.check[index] = [true, index]
      }else{
        this.check[index] = [false, index]
      }
      this.indox[index] = index
      this.addIdToiden(codDetail)
    },

    disabled(index, codDetail){
      if(this.checkedOptions.length > 0 && this.check.length >= index+1){

        if(this.check[index]){
          if(this.check[index][0] && this.indox[index] == index && !this.idein.includes(codDetail)){
            return true
          }
        }
        return false
      }
      return false
    },

    addIdToiden(codDetail){
      if(!this.idein.includes(codDetail)){
        this.idein.push(codDetail)
      }else{
        var idin = this.idein.indexOf(codDetail)
        this.idein.splice(idin, 1)
      }
    },/* all abut validation checks */

    sendData(){
      if(!this.validMins()){
        /* add aditionals notes */
        this.checkedOptions.push(this.nota)
        this.$emit('acept', this.checkedOptions)
        this.resetFields()
      }else{
        this.startValidation = true;
        this.errorToastrMixin("Escoje tu Opciones", "debes seleccionar alguno(s)");
      }
    },
    closeModal(){
      this.$emit('close')
    },
    resetFields(){
      this.checkedOptions= []
      /* this.count = [] */
      this.check = [],
      this.indox = [],
      this.idein = [],
      this.reset = [],
      this.datamodel = [],
      this.nota = ''
      //this.removecount = true
    },
    validMins(){
      var n =0
      let nObligatorio=0;
      this.options.map((item)=>{
        if(item.obligatorio){
          nObligatorio++;
        }
      });
      if(this.count.length > 0){
        for(let i =0; i<this.count.length; i++){
          if(this.count[i] && this.options[i]){
            if(this.options[i]['obligatorio'] && this.count[i] >= this.options[i].min){
              n += 1
            }
          }
        }
        if(n>=nObligatorio){
          return false
        }
      }
      return true
    },
    checkoutData(deTitulo){
      for(let i =0; i<this.selected.length; i++){
        if(this.selected[i] === deTitulo){
          return true
        }
      }
      return false
    },
    countData(){
      for(let i=0; i<this.details.length; i++){
        var n = 0
        for(let j=0; j<this.details[i].length; j++){
          for(let k =0; k<this.selected.length; k++){
            if(this.details[i][j] === this.selected[k]){
              this.checkedOptions.push(this.selected[k])

              n += 1
            }
          }
        }
        this.count[i] = n
      }
      for(let i=0; i<this.options.length; i++){
        if(this.options[i].max < this.count[i] +1){
          this.check[i] = [true, i]
        }else{
          this.check[i] = [false, i]
        }
        this.indox[i] = i
        for(let j=0; j<this.options[i].detalles.length; j++){
          for(let k=0; k<this.selected.length; k++){
            if(this.selected[k] === this.options[i].detalles[j].titulo){
              this.idein.push(this.options[i].detalles[j].codtopciondetalle)
            }
          }
        }
      }
    }
  },
  computed: {
     getColor(){
       if(!this.validMins()){
         return "#c33332"
       }
       return "#D57372"
     },

  }
}
</script>

<style scoped src="@/css/style-inputs.css"></style>
<style scoped>
  h3{
    font-family: "Poppins", sans-serif !important;
  }
  .modal-dialog{
    background: white;
    display: flex;
    border-radius: 6px;
  }
  .menu-extras-modal{
    margin-top: 0%;
    margin-left: -30px;
  }
  .container_modal{
    display: flex;
    background: white;
    flex-direction: column;
    width: 360px;
    margin-left: 10px;
    border-radius: 6px;
  }
  label.label-titulo {
    margin-left: 6px;
    font-weight: 400;
  }
  .label_disabled{
    font-weight: 300 !important;
  }
  .extendPrice{
    color: #0cc665;
    font-size: 15px;
  }
  .img_modal{
    display: flex;
    padding: 15px;
    min-width: 420px;
    min-height: 360px;
    align-items: center;
  }
  .modal-content{
    padding: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .btn__add{
    width: 100% !important;
    border-radius: 20px !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    margin-left: -33px;
  }
  .btn__add:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }
  .menu__section{
    max-height: 40rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .cont-view-product{
    display: inline-block;
    font-size: 10px;
    color: #ff2425;
    font-weight: 600;
  }
  .img_modal .img_product{
    width: auto;
    height: auto;
    max-width: 340px;
    max-height: 390px;
  }
  .menu__section::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;
    }

  .menu__section::-webkit-scrollbar
    {
      width: 6px;
      background-color: #F5F5F5;
    }

  .menu__section::-webkit-scrollbar-thumb
    {
      background-color: #000000;
    }
  .menu__section::-webkit-scrollbar {
   /*  display: none;  */ /* Ocultar scroll */
  }
  @media(min-width: 420px){
    .container_modal{
      width: 420px;
      margin-left: 10px;
    }
    .btn__add{
      margin-left: 0px;
    }
    .img_modal .img_product{
      max-width: 390px;
    }
  }
  @media(min-width: 830px){
    .menu-extras-modal{
      margin-left: -430px;
    }
    .container_modal{
      display: flex;
      flex-direction: row;
      border-radius: 0px;
    }
  }
  @media(min-width: 900px){
    .menu-extras-modal{
      margin-left: -390px;
    }
    .container_modal{
      display: flex;
      background: white;
      flex-direction: row;
    }
  }
  
</style>