<template>
  <footer style=" height: 644px; background: #F5F5FA;">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-3">
          <img :src="require('@/assets/images/logo-zpedidos-rojo.png')" alt="logo"><br>
          <br><p class="about_us">Sobre Nosotros</p>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3">
          <h3>Terminos Legales</h3><br>
          <a href="" @click.prevent="gotTermsAndConditions()">Politica de Cookies</a><br><br>
          <a href="" @click.prevent="gotTermsAndConditions()">Terminos y Condiciones</a>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3">
          <h3>Trabaja Con Nosotros</h3><br>
          <a href="" @click.prevent="gotoContact()">Contacto</a><br><br>
          <a href="" @click.prevent="gotoregisterZ()">Quiero ser un Zeta</a><br><br>
          <a href="" @click.prevent="gotoregisterBusiness()">Registra tu restaurante</a>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3 download__container">
          <div class="title_h3">
            <h3>Descargar</h3>
          </div>
          <div class="linksToApp">
            <a class="LinkAppsStore" href="https://apps.apple.com/pe/app/zpedidos/id1530138778"  target="_blank"><img style="margin-bottom:10px" :src="require('@/assets/images/footer/appStore.png')" alt="" height="45px" width="160px"></a><br>
            <a class="LinkGooglePlay" href="https://play.google.com/store/apps/details?id=com.zea.pedido" target="_blank"><img :src="require('@/assets/images/footer/googlePlay.png')" height="45px" width="160px"></a>
          </div>
          <div class="title_h3">
            <h3>Siguenos</h3>
          </div>
          <div class="socialsNetwork">
            <a href="https://www.facebook.com/zpedidos/" target="_blank"><img :src="require('@/assets/images/footer/facebook.jpg')" width="34px"></a>
            <a href="https://www.instagram.com/zpedidos_pe/" target="_blank"><img :src="require('@/assets/images/footer/instagram.jpg')" width="34px"></a>
            <a href="https://www.youtube.com/channel/UCV_3TShAw12Uw_Sqd3ojqOQ" target="_blank"><img :src="require('@/assets/images/footer/youtube.jpg')" width="34px"></a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_bg">
      <marquee width="100%" direction="right" height="100px" scrollamount="10">
        <img class="imgfoot1" :src="require('@/assets/images/footer/moto.png')" alt="">
        <img class="imgfoot2" :src="require('@/assets/images/footer/bike.png')" alt="">
      </marquee>
    </div>
  </footer>
</template>
<script>
export default {
  data(){
    return{

    }
  },
  methods: {
    gotTermsAndConditions() {
      let routeData = this.$router.resolve({ name: 'terminosycondiciones' });
      window.open(routeData.href, '_blank');
    },
    gotoContact() {
      let routeData = this.$router.resolve({ name: 'empleos' });
      window.open(routeData.href, '_blank');
    },
    gotoregisterZ() {
      let routeData = this.$router.resolve({ name: 'repartidores' });
      window.open(routeData.href, '_blank');
    },
    gotoregisterBusiness() {
      let routeData = this.$router.resolve({ name: 'restaurantes' });
      window.open(routeData.href, '_blank');
    },
  }
}
</script>
<style scoped>
  .container{
    padding-top: 130px;
  }
  h3{
    color: #263b5e !important;
  }
  a{
    font-size: 16px !important;
    font-weight: 300;
  }
  .about_us{
    font-size: 16px;
    font-weight: 300;
  }
  .imgfoot2{
    margin-left: 100px;
  }
  @media(min-width: 300px){
    footer{
      height: 900px !important;
    }
  }
  @media(min-width: 1000px){
    footer{
      height: 644px !important;
    }
  }
  @media(min-width: 1200px){
    .title_h3{
      text-align: center;
      margin-top: 3px;
    }
    .linksToApp{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .socialsNetwork{
      display: flex;
      width: 184px;
      justify-content: space-around;
      margin-left: 10px;
      margin-left: 37px;
    }
    .footer_bg{
      position: absolute;
      height: 266px;
      width: 100%;
      bottom: -100px;
    }
  }
</style>
