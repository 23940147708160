import { render, staticRenderFns } from "./ModalPendingOrders.vue?vue&type=template&id=4447770e&scoped=true&"
import script from "./ModalPendingOrders.vue?vue&type=script&lang=js&"
export * from "./ModalPendingOrders.vue?vue&type=script&lang=js&"
import style0 from "./ModalPendingOrders.vue?vue&type=style&index=0&id=4447770e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4447770e",
  null
  
)

export default component.exports