import axios from 'axios'
import configs from '@/utils/configs'

const getDefaultState = () => {
	return {
		search: '',
		businessAndProducts:[],
		loading: false,
		showNbusiness: 8,
		btnShowAllText: '... ver todo'
	}
}

export default {
	namespaced: true,

	state: getDefaultState(),

	mutations: {
		setSearch(state, search) {
			state.search = search;
		},

		setBusinessAndProducts(state, businessAndProducts) {
			state.showNbusiness = 8;
			state.btnShowAllText = '... ver todo';
			state.businessAndProducts = businessAndProducts;
		},

		setShowNbusiness(state, showNbusiness) {
			state.showNbusiness = showNbusiness
		},

		setBtnShowAllText(state, btnShowAllText) {
			state.btnShowAllText = btnShowAllText
		},

		changeLoading(state, isTrueOrFalse) {
			state.loading = isTrueOrFalse
		},

		resetState (state) {
			Object.assign(state, getDefaultState())
		}
	},

	actions: {
		searchProductByLatLng({ commit }, { search, lat_long, token }) {
			const apiURL = configs.apiUrl + `/productos/searchlatlng/${search}`;
			let businessTotal = [];

			axios.post(apiURL, lat_long, {
				headers: {'Authorization': 'bearer ' + token}
			}).then(response => {
				let products = response.data.productos;
				for (let i = 0; i < products.length; i++) {
					products[i].empresa.products = [];
					businessTotal.push(products[i].empresa);
				}
				// Elimiar las empresas repetidas
				let businessMap = businessTotal.map(item => {
					return [item.codempresa, item]
				});
				var businessMapArray = new Map(businessMap);
				let business = [...businessMapArray.values()];
				// Created a new array object
				let businessAndProducts = business;
				for (let i = 0; i < business.length; i++) {
					for (let j = 0; j < products.length; j++) {
						if (business[i].codempresa == products[j].codempresa) {
							businessAndProducts[i].products.push(products[j]);
						}
					}
				}
				commit('setBusinessAndProducts', businessAndProducts);
				console.log('products', products);
				console.log('business', business);
			}).finally(() => {
				console.log('termino la peticion')
				commit('changeLoading', false);
			});
		},

		resetSearchState({ commit }) {
			commit('resetState');
		}
	}
}