<template>
	<!-- Main Section Start -->
	<div class="main-section">
		<div class="page-section nopadding cs-nomargin" style="margin-top: 0px;padding-top: 60px;padding-bottom: 10px;margin-bottom: 0px;">
			<div class="container">
				<!-- letf list 1-->
				<div v-if="list == 1" class="row">
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<div class="row">
							<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<h1>Checkout</h1>
							</div>
							<!-- letf -->
							<div class="section-sidebar col-lg-8 col-md-8 col-sm-12 col-xs-12">
								<div class="order-sort-results">
									<h5>Direción de entrega</h5>
									<div>
										<div class="pull-right">
											<a href="" class="btn btn-danger btn-sm" data-toggle="modal" data-target="#modalAddress"><i class="fas fa-pencil-alt"></i> Cambiar</a>
										</div>
										<div class="mr-7">
											<i class="fas fa-home"></i>
											<span style="">
												{{ defaultAddress.direccion }} - {{ defaultAddress.referencia }}
											</span>
										</div>
									</div>
									<br>
									<div>
										<p><strong>Tiempo de entrega:
											<span class="pull-right">
												{{ settingCityData.minutosmin }} - {{ settingCityData.minutosmax }} minutos
											</span>
										</strong></p>
									</div>
								</div>

								<div class="order-sort-results">
									<!-- List of products -->
									<ListProducts :isCheckout="true" isShoppingCart="false"></ListProducts>
									<!-- List of products End-->
								</div>

								<div class="order-sort-results">
									<!-- Coupons -->
									<Coupons :isCheckout="true" :isShoppingCart="false"></Coupons>
									<!-- Coupons End-->
								</div>

								<div class="order-sort-results">
									<h5>¿Quieres aclarar algo?</h5>
									<form>
										<div class="row input-container">
											<div class="col-12 input-effect">
												<input
												v-model="formOrder.nota"
												type="text"
												class="effect-16 input-style"
												:class="{'has-content' : hasTextOnInputMixin(formOrder.nota)}"
												placeholder="">
												<label class="effect-label">Notas al restaurante</label>
												<span class="focus-border"></span>
											</div>
										</div>
									</form>
								</div>
							</div>
							<!-- letf End -->
							<!-- right -->
							<div class="sticky-sidebar col-lg-4 col-md-4 col-sm-12 col-xs-12">
								<div class="user-order-holder">
									<div class="user-order">
										<!-- summary prdocts -->
										<Summary :isCheckout="true"></Summary>
										<!-- summary prdocts End-->
										<a v-on:click.prevent="confirmOrder" href="" class="btn btn-danger btn-block"><i class="fas fa-shopping-cart"></i> Realizar pedido S/. {{ getTotal.toFixed(2) }}</a>
									</div>
								</div>
							</div>
							<!-- right End -->
						</div>
					</div>
				</div>
				<!-- letf list 1 End-->
				<!-- letf list 0-->
				<div v-else class="row">
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<h1>Detalles del pedido</h1>
					</div>
					<result-order></result-order>
				</div>
				<!-- letf list 0 End-->
			</div>
		</div>
	</div>
	<!-- Main Section End -->
</template>

<style scoped src="@/css/style-inputs.css"></style>
<style scoped>
.mr-7 {
	margin-right: 80px !important;
}
</style>

<script>
import ListProducts from '@/components/ListProducts.vue';
import Summary from '@/components/Summary.vue';
import Coupons from '@/components/Coupons.vue';
import ResultOrder from '@/components/ResultOrder.vue';
import { mapState, mapGetters } from 'vuex';

export default {
	name: 'Checkout',

	components: { ListProducts, Summary, Coupons, ResultOrder },

	data() {
		return {
			user: '',
			fromSetting: {
				'lat': '',
				'lng': ''
			},
			formOrder: {
				'codusuario': '',
				'subtotal': '',
				'coddireccion': {
					'coddireccion': '',
					'direccion': '',
					'lat': '',
					'lng': ''
				},
				'envio': '',
				'descuento': '',
				'detalles': [],
				'lluvia': '',
				'metodopago': '',
				'efectivocliente': '',
				'nota': '',
				'plataforma': 'web'
			},
			settingCityData: '',
			errorPaymentMethod: false,
			errorDistanceAddress: false,
			list: 1, // 1 -> antes de realizar pedido, 0 -> despues de realizar el
		}
	},

	created() {
		window.scrollTo(0,0);
		this.closeModalShoppingCart();
		if (this.isAuthenticatedMixin) {
			if (this.getProductMixin().length < 1) {
				this.$router.push({ name: 'Index' });
			}
			else {
				this.setEmpresaMixin(this.getEmpresaLocalMixin());
				this.settingCity();
				// Form order
				this.user = this.getCurrentUserMixin;
				// subtotal
				this.formOrder.codusuario = this.user.codusuario;
			}
		}
	},

	computed: mapState({
		defaultAddress: state => state.defaultAddress.defaultAddress,
		products: state => state.shoppingCart.products,
		costShipping: state => state.shoppingCart.costShipping,
		discountAmount: state => state.shoppingCart.discountAmount,
		paymentMethod: state => state.shoppingCart.paymentMethod,
		customerCash: state => state.shoppingCart.customerCash,
		...mapGetters('shoppingCart', ['getTotal','getCostProducts']),
	}),

	methods: {
		settingCity() {
			let addressDefault = this.getDefaultAddressMixin();
			this.fromSetting.lat = addressDefault.lat;
			this.fromSetting.lng = addressDefault.lng;
			this.settingCityMixin(this.fromSetting).then(response => {
				this.settingCityData = response.data.configuracion;
				this.$store.commit('shoppingCart/setDeliverygratis', response.data.configuracion.deliverygratis);
				this.formOrder.lluvia = this.settingCityData.lluvia;
			});
			// Costos de envios
			this.getShippingCityMixin().then(response0 => {
				this.setRangosMixin(JSON.parse(response0.data[0].rangos));
				let distance = this.calcularDistanciaMixin(addressDefault);
				distance.then(response => {
					this.$store.commit('shoppingCart/setDistance', response);
					let businessLocal = this.getEmpresaLocalMixin();
					if (businessLocal.codtiporeserva == 7 || businessLocal.codtiporeserva == 8 || this.$store.state.shoppingCart.deliverygratis == 1) {
						this.$store.commit('shoppingCart/setCostShipping', 0.00);
					}
					else {
						this.$store.commit('shoppingCart/setCostShipping', this.calculateCostoEnvioMixin(response));
					}
				});
			});
		},

		closeModalShoppingCart() {
			window.$("#modal-shopping-cart").modal("hide");
		},

		confirmOrder() {
			this.validatePaymentMethods();
			this.validateDistanceAddress();
			if (!this.errorPaymentMethod && !this.errorDistanceAddress) {
				this.$swal.fire({
					title: '¿Realizar pedido?',
					text: "¿Estás seguro que deseas realizar el pedido?",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Si, realizar!'
				}).then((result) => {
					if (result.isConfirmed) {
						this.createOrder();
					}
				});
			}
		},

		createOrder() {
			this.setAddress();
			this.methodPayment();
			this.setProductDetails();
			this.formOrder.descuento = this.discountAmount;
			this.formOrder.subtotal = this.getCostProducts;
			console.log('this.formOrder', this.formOrder)
			this.createOrderMixin(this.formOrder).then(response => {
				this.$swal.fire(
					'Confirmado!',
					'Tu pedido ha sido aceptado.',
					'success'
					);
				this.successToastrMixin('Pedido', 'Tu pedido ha sido realizado con éxito');
				console.log('this.resultOrder ', response );
				let resultOrder = response.data.pedido[0];
				this.$store.commit('order/setOrder', resultOrder);
				this.list = 0;
				this.clearShoppingCartMixin();
				this.clearBussinesLocalMixin();
				this.$store.commit('shoppingCart/clearDistanceAndCostShipping');
				this.getUserPendingOrders(this.getCurrentUserMixin.codusuario);
				this.$store.dispatch('shoppingCart/resetShoppingCartState');
			}).catch(error => {
				console.log(error);
				this.errorToastrMixin('Error', error.response.data.message);
			});
		},

		getUserPendingOrders(codusuario) {
			this.getUserPendingOrdersMixin(codusuario).then(response => {
				this.$store.commit('order/setOrders', response.data);
			});
		},

		methodPayment() {
			this.formOrder.metodopago = this.paymentMethod;
			if (this.paymentMethod === 1) {
				this.formOrder.efectivocliente = this.customerCash;
			}
		},

		setAddress(){
			this.formOrder.coddireccion.coddireccion = this.defaultAddress.coddireccion;
			this.formOrder.coddireccion.direccion = this.defaultAddress.direccion + ' - ' + this.defaultAddress.referencia;
			this.formOrder.coddireccion.lat = this.defaultAddress.lat;
			this.formOrder.coddireccion.lng = this.defaultAddress.lng;
			this.formOrder.envio = this.costShipping;
		},

		setProductDetails(){
			for (var i = 0; i < this.products.length; i++) {
				this.formOrder.detalles[i] = {
					'codproducto': this.products[i].codproducto,
					'descripcion': this.products[i].descripcion,
					'cantidad': this.products[i].count,
					'precio': this.products[i].precio,
					'nota': this.products[i].nota
				};
			}
		},

		validatePaymentMethods() {
			if (this.paymentMethod == '') {
				this.errorPaymentMethod = true;
				this.errorToastrMixin('Error', 'Seleccione un método de pago.');
			}
			else {
				this.errorPaymentMethod = false;
			}
		},

		validateDistanceAddress() {
			if (this.costShipping >= 9) {
				this.errorDistanceAddress = true;
				this.errorToastrMixin('Error', 'Su dirección se encuentra fuera del rango de cobertura');
			}
			else {
				this.errorDistanceAddress = false;
			}
		}

	},

}
</script>
