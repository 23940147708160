const configs = {
  apiUrl: 'https://zcode10.com/pedidorest2/public/api/v1',

  staticToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2MjU3MzA2NzAsInJvbGVzIjpbIlJPTEVfdHJhYmFqYWRvciJdLCJ1c2VybmFtZSI6IjUxNDcxMjE1NTc3In0.d9oY7JAVFT3bMlxnKmXsfRW4sLmQ14ZWX9B-GA1g1_RPcv8wZLa-jQVsRCXq5PgEc0uILsRgM6hyoWaq44gk8ka0J5Gy8ZopJI3tNSsL9mmlt59FJUZ_m0qY1qAH18ai57bytGssMIAZG65q1YLfgpiQr9z0uGFzHjl6SDGcQK5Y4NGaKh36OoiCZtAXmhYtj7cn2TwuQv05nFTbtcG0iMRhgg2ShiYF5lPQeyanXeyAcqhuNL3qxR96tRI5xo7h4B09lqUwBWZwWP7KBEz9go0T6r63cNl_IFEDWVlPPj2ixTkMmIpRCOOJdiWmj8rtEKaHLCH0PB-5SSuqoiGo8iOAKdSIZyaqpR7WWirqOdUa5OEpM2sZbgdb2IDESnLEeL1s3ZMdPCjwtUBMyAAV6gXsGbxjRjuW3JprVSu8ABChnvHN6hGB_SuZ7sTptnDsz_yQDm-DWNnJpCq_6fDiEhOyMEzHJazMSdwoOfaqlW59J01gtjpw8sbVBk978f50tvGuQAr9ZZ-s3c2HoQiDC5KgudxJ5v_CpitiIl4gaD2Ub4lulUTYpjtYp6MHJ5OE4QhNH4vMaEOfjHF7ehmSHxOxcCyeas9-BCNp_sfE_-BMwW-2Isc21pQKpLyCSuTuosyN73cMu_aDUWcnQYM_HBUJ5WzTdCNjLNtJuDX3xQA',

  urlImg: 'https://zcode10.com/uploads/',
  urlImgOffers: 'https://zcode10.com/ofertas/',
  urlNewZetas: 'https://zcode10.com/pedidorest2/public/api/v1/nuevoszetas',

}

export default configs