<template>
  <div class="row">
    <div class="listing-main-search">
      <div class="block-search">
        <div class="search-icon" :class="$route.name == 'searchBusiness' ? 'search-icon-size' : ''">
          <img alt=""  :src="require('@/assets/images/buscador.png')" width="25px">
        </div>
        <div id="showIconClear" class="search-input">
          <input :value="search" @input="searchBusiness" type="text" class=" " placeholder="Buscar" :disabled="loading">
          <span @click="clearInputSearch" class="showIconClear" :class="$route.name == 'Home' ? 'showIconClearHome' : ''"><i class="fas fa-times showIconClear"></i></span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped src="@/css/style-search.css"></style>

<script>
import { debounce } from "debounce";
import { mapState } from 'vuex';

export default {
  name: 'Search',

  computed: mapState({
    search: state => state.search.search,
    loading: state => state.search.loading,
  }),

  methods: {
    searchBusiness: debounce(function (e) {
      let search = e.target.value;
      if (this.search != search) {
        if (search == '' || search == null) {
          this.$store.commit('search/setSearch', search);
          if (this.$route.name != 'Home') {
            this.$router.push({ name: 'Home' });
          }
        }
        else if (search.length < 3) {
          this.warningToastrMixin('Buscador', 'Digite una palabra.');
        }
        else {
          this.$store.commit('search/setSearch', search);
          this.$store.commit('search/changeLoading', true);
          let defaultAddress = this.getDefaultAddressMixin();
          let lat_long = {
            'lat': defaultAddress.lat,
            'lng': defaultAddress.lng
          };
          this.$store.dispatch('search/searchProductByLatLng', {
            'search': search,
            'lat_long': lat_long,
            'token': this.$store.state.user.token
          });
          this.$router.replace({ name: 'searchBusiness', query: {search: search} });
        }
      }
    }, 700),

    clearInputSearch() {
      this.$store.commit('search/setSearch', '');
    }

  }
}
</script>

