<template>
  <div class="modal fade come-from-modal right" id="modal-shopping-cart" tabindex="-1" role="dialog" aria-labelledby="modal-shopping-cart">
    <div class="modal-dialog" role="document">
      <div class="modal-content scroll-style">
        <div class="modal-header">
          <button id="closeButtonModalShoppingCart" type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="fas fa-times-circle"></i></span></button>
          <h4 class="modal-title" id="modal-shopping-cart">Detalle del pedido</h4>
        </div>
        <div class="modal-body">
          <div class="sticky-sidebar">
            <div class="user-order-holder">
              <div class="user-order">

                <shopping-cart :showDetailCart="false"></shopping-cart>

              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <button v-on:click.prevent="clearBasket" type="button" class="btn btn-default btn-lg btn-clear btn-block"><i class="far fa-trash-alt"></i> Vaciar canasta</button>
          <button v-on:click.prevent="goPay" class="btn btn-danger btn-lg btn-block"><i class="fas fa-shopping-cart"></i> Ir a pagar S/. {{ getCostProducts.toFixed(2) }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped src="@/css/style-modal-shopping-cart.css">
</style>

<script>
import ShoppingCart from '@/components/ShoppingCart.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ModalShoppingCart',

  components: { ShoppingCart },

  created() {
    this.$store.commit('shoppingCart/setProducts', this.getProductMixin());
    this.calculateCostProducts();
  },

  computed: {
    // getters
    ...mapGetters('shoppingCart', ['getCostProducts', 'getCountProducts'])

  },

  methods: {
    // mutations
    ...mapMutations('shoppingCart', [
      'calculateCostProducts'
      ]),

    clearBasket() {
     this.$swal.fire({
       title: '¿Estás seguro?',
       text: "¡No podréis revertir esto!",
       icon: 'warning',
       showCancelButton: true,
       confirmButtonColor: '#3085d6',
       cancelButtonColor: '#d33',
       confirmButtonText: 'Si, aceptar!'
     }).then((result) => {
      if (result.isConfirmed) {
        this.clearBussinesLocalMixin();
        this.clearShoppingCartMixin();
        this.$store.commit('shoppingCart/clearDistanceAndCostShipping');
        this.$swal.fire(
          'Vacío!',
          'Su canasta ha sido vaciado.',
          'success'
          )
      }
    });
   },

   goPay() {
    console.log('this.isAuthenticatedMixin', this.isAuthenticatedMixin)
    console.log('this.getDefaultAddressMixin', this.getDefaultAddressMixin())
    if (this.isAuthenticatedMixin && this.getDefaultAddressMixin()) {
      if (this.getCountProducts > 0) {
        this.$router.push({ name: 'checkout' });
      }
      else {
        window.$("#modal-shopping-cart").modal("hide");
        this.warningToastrMixin('Canasta', 'Su canasta está vacío.');
      }
    }
    else if (this.isAuthenticatedMixin && !this.getDefaultAddressMixin()){
      this.warningToastrMixin('Dirección', 'Registre una dirección.');
      window.$("#modalAddress").modal("show");
    }
    else {
      window.$("#modal-shopping-cart").modal("hide");
      if (this.getCountProducts < 1) {
        this.warningToastrMixin('Canasta', 'Su canasta está vacío.');
      }
      else {
        window.$('#modalLogin').modal('show');
      }
    }
  }
}
}
</script>


