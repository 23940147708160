<template>
  <div>
    <h5>Cúpon</h5>
    <form>
      <div class="pull-right">
        <a v-on:click.prevent="searchCoupon" href="" class="btn btn-danger" :class="{ 'btn-sm' : isCheckout, 'btn-xs' : isShoppingCart, 'btn disabled' : isDisabledBtn }"><i class="fas fa-pencil-alt"></i>
          <span v-if="isCheckout"> Aplicar</span>
          <span v-else>Aplicar</span>
        </a>
      </div>
      <div class="row input-container" :class="{ 'mr-7-checkout' : isCheckout, 'mr-7' : isShoppingCart }">
        <div class="col-xs-12">
          <div class="row">
            <div class="col-12 input-effect">
              <input
              v-model="coupon"
              type="text"
              class="effect-16 input-style"
              :class="{'has-error' : error, 'has-content' : hasTextOnInputMixin(coupon)}"
              placeholder="">
              <label class="effect-label">Código de descuento</label>
              <span class="focus-border"></span>
            </div>
          </div>
        </div>
        <div class="col-xs-12">
          <span class="text-danger" ole="alert">{{ error_msg }}</span>
        </div>
      </div>
    </form>
  </div>
</template>

<style scoped src="@/css/style-inputs.css"></style>
<style scoped>
/* shopping cart*/
.mr-7 {
  margin-right: 72px !important;
}
/* shopping cart End*/
/* checkout*/
.mr-7-checkout {
  margin-right: 80px !important;
}
/* checkout End*/
</style>

<script>
export default {
  name: 'Coupons',

  props: ['isCheckout', 'isShoppingCart'],

  data() {
    return {
      coupon: '',
      couponData: '',
      form: {
        'codusuario': '',
        'cupon': ''
      },
      isDisabledBtn: false,
      error: false,
      error_msg: ''
    }
  },

  created() {
    if (this.isAuthenticatedMixin) {
      this.form.codusuario = this.getCurrentUserMixin.codusuario;
    }
  },

  methods: {
    searchCoupon() {
      this.validateCoupon();
      if (!this.error) {
        this.form.cupon = this.coupon;
        this.searchCouponMixin(this.form).then(response => {
          console.log('response coupon', response);
          if (response.status == 200) {
            this.couponData = response.data.cupon;
            this.$store.commit('shoppingCart/setDiscountAmount', parseFloat(this.couponData.descuento));
            this.successToastrMixin('Cupón', 'Descuento de cupón obtenida');
            this.coupon = '';
            this.isDisabledBtn = true;
          }
          else {
            this.errorToastrMixin('Error', 'Ocurrio un error, vuelva a intentar más tarde');
          }
        }).catch(error => {
          console.log(error);
          this.errorToastrMixin('Error', 'Ocurrio un error, vuelva a intentar más tarde');
        });
      }
    },

    validateCoupon() {
      if (this.coupon == '') {
        this.error = true;
        this.error_msg = 'Ingrese el cupón';
      }
      else {
        this.error = false;
        this.error_msg = '';
      }
    }
  }

}
</script>
