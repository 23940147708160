import firebase from 'firebase/app';

var loginAndCreateUser = {
	data() {
		return {
			//list 3 when user don not have phone number
			hasPhoneNumberOrEmail: 1,//0 -> no phone, 2 -> no email, 1 -> default
			// Create user
			form: {
				'direccion': '',
				'telefono': '',
				'fullname': '',
				'birthdate': ''
			},
			formFacebookOrGoogle: {
				'direccion': '',
				'telefono': '',
				'fullname': ''
			},
			policy: false,
			// Validation
			formErrors: {
				direccion: {
					error: false,
					error_msg: '',
				},
				telefono: {
					error: false,
					error_msg: '',
				},
				fullname: {
					error: false,
					error_msg: '',
				},
				birthdate: {
					error: false,
					error_msg: '',
				},
				policy: {
					error: false,
					error_msg: '',
				},
			},
			// code
			isSMS: false,
			// Login
			titleModal: 'Iniciar sesíon',
			// list 1
			phone: '',
			lengthInputPhone: 6,
			error: false,
			error_msg: '',
			//list 2
			validadCodeView: false, // vista para validad el codigo enviado por SMS
			validationCode: '',
			errorValidationCode: false,
			captchaIsResolve: false,
			captchaIsResolveRegister: false,
			errorValidationCode_msg: '',
			// Registrar phone
			formLogin: {
				telefono: '',
				token: '',
				direccion: ''
			},
			formLoginErrors: {
				direccion: {
					error: false,
					error_msg: '',
				}
			}
		}
	},

	methods: {
		createUser(form) {
			console.log('from', form)
			this.clearLocalStorage();
			if (this.hasPhoneNumberOrEmail === 1) {
				this.validateForm();
			}
			console.log('foprm error', this.formErrors)
			if (!this.formErrors.direccion.error && !this.formErrors.telefono.error && !this.formErrors.fullname.error && !this.formErrors.policy.error) {

				if (this.hasPhoneNumberOrEmail === 1 || this.hasPhoneNumberOrEmail === 0) {
					form.telefono = '51'+form.telefono;
				}

				this.createUserMixin(form).then(response => {
					this.successToastrMixin('Registro', 'Cuenta creada con éxito.');
					localStorage.setItem('token', response.headers.token);
					localStorage.setItem('user', JSON.stringify(response.data.user));
					this.setNewTokenMixin(response.headers.token);
					this.setNewUserMixin(response.data.user);
					this.$store.commit('user/setCurrentUser', response.data.user);
					this.$store.commit('user/setToken', response.headers.token);
					console.log(response);
					this.clearForm();
					window.$("#modalRegisterUser").modal("hide");
					window.$("#modalLogin").modal("hide");
					if (this.getDefaultAddressMixin()) {
						this.createAddress();
						if (this.getProductMixin()) {
							this.$router.push({ name: 'checkout' });
						}
					}
					else {
						this.$store.commit('defaultAddress/changeIsNullAddress', true);
						window.$("#modalAddress").modal("show");
					}
				})
				.catch(error => {
					console.log(error);
					this.errorToastrMixin('Error', 'Ocurrio un error, vuelva a intentar más tarde.');
				});
			}
			else{
				this.errorToastrMixin('Error', 'Llene los datos correctamente.');
			}
		},

		getUserAddress(isLogin) {
			var addressDefault = {};
			let addressUser = [];
			this.getAddressUserMixin().then(response => {
				addressUser = response.data;
				this.$store.commit('defaultAddress/setAddressUser', addressUser);
				for(let i = 0; i < addressUser.direcciones.length; i++){
					if (addressUser.direcciones[i].estado === 1){
						addressDefault = addressUser.direcciones[i];
						// costShipping
						if (this.getEmpresaLocalMixin()) {
							// Costos de envios
							this.getShippingCityMixin().then(response0 => {
								this.setRangosMixin(JSON.parse(response0.data[0].rangos));
								let distance = this.calcularDistanciaMixin(addressDefault);
								distance.then(response => {
									this.$store.commit('shoppingCart/setDistance', response);
									this.$store.commit('shoppingCart/setCostShipping', this.calculateCostoEnvioMixin(response));
								});
							});
						}
						this.setDefaultAddressMixin(addressDefault);
						i = addressUser.direcciones.length;
					}
				}
				if (isLogin) {
					if (this.$route.name != 'Home') {
						this.$router.push({ name: 'Home' });
					}
				}
			});
		},

		createAddress() {
			let defaultAddress = this.getDefaultAddressMixin();
			let form = {
				'direccion': defaultAddress.direccion,
				'lat': defaultAddress.lat,
				'lng': defaultAddress.lng,
				'referencia': defaultAddress.referencia
			};
			this.createAddressMixin(form).then(response => {
				console.log('response createAddress', response);
				this.getUserAddress(false);
			})
			.catch(error => {
				console.log(error);
			});
		},

		// Login with phone number
		signIn(phoneNumber) {
			console.log('renviando codigo', phoneNumber)
			this.clearLocalStorage();
			const appVerifier = window.recaptchaVerifier;
			let phoneNumberString = '+51' + phoneNumber;
			console.log('appVerifier', appVerifier)
			console.log('phoneNumberString', phoneNumberString)
			firebase.auth().signInWithPhoneNumber(phoneNumberString, appVerifier)
			.then((confirmationResult) => {
				console.log('confirmationResult', confirmationResult)
				this.successToastrMixin('Código', 'SMS enviado.');
				window.confirmationResult = confirmationResult;
				this.titleModal = 'Ingrese el codigó';
				this.validadCodeView = true;
			})
			.catch((error) => {
				console.log(error);
				this.errorToastrMixin('Error', 'SMS no enviado.');
			});
		},

		// Login with Facebook
		loginWithFacebook() {
			this.clearLocalStorage();

			var provider = new firebase.auth.FacebookAuthProvider();

			firebase.auth().signInWithPopup(provider).then((result) => {
				console.log('resulft facebook', result);

				this.formFacebookOrGoogle.direccion = result.user.email;
				this.formFacebookOrGoogle.telefono = result.user.phoneNumber;
				this.formFacebookOrGoogle.fullname = result.user.displayName;

				//verificar si existe este usuario
				let phone_email = {
					'telefono': this.formFacebookOrGoogle.telefono,
					'direccion': this.formFacebookOrGoogle.direccion
				};

				this.varifyUserFacebookOrGoogle(phone_email, result);

			})
			.catch(error => {
				console.log(error);
				this.errorToastrMixin('Error', 'Ocurrio un error, vuelva a intentar más tarde.');
			});
		},

		// Verificar si el usuario de facebook o google existe
		varifyUserFacebookOrGoogle(phone_email, result) {
			this.varifyUserFacebookOrGoogleMixin(phone_email).then(response => {
				console.log('response facebook', response);
				if (response.data.data.codusuario) {
					this.successToastrMixin('Login', 'Inicio session con exito.');
					localStorage.setItem('token', response.headers.token);
					localStorage.setItem('user', JSON.stringify(response.data.data));
					this.setNewTokenMixin(response.headers.token);
					this.setNewUserMixin(response.data.data);
					this.$store.commit('user/setCurrentUser', response.data.data);
					this.$store.commit('user/setToken', response.headers.token);
					if (this.getDefaultAddressMixin()) {
						this.createAddress();
						if (this.getProductMixin()) {
							this.$router.push({ name: 'checkout' });
						}
					}
					else {
						this.getUserAddress(true);
					}
					window.$('#modalLogin').modal('hide');
				}
			}).catch(error => {
				console.log(error);
				console.log('status', error.response.status)
				if (error.response.status == '400') {
					if (result.user.phoneNumber === null) {
						/*let phoneP = false;
						let emailP = true;
						if (phoneP){*/
							console.log('hasPhoneNumberOrEmail = 0')
							this.titleModal = 'Validar número de celular';
							this.hasPhoneNumberOrEmail = 0;
						}
						else if (result.user.email === null){
						//else if (emailP){
							console.log('hasPhoneNumberOrEmail = 2')
							this.hasPhoneNumberOrEmail = 2;
						}
						else {
							console.log('validated phone number')
							this.titleModal = 'Validar número de celular';
							this.hasPhoneNumberOrEmail = 0;
							this.phone = result.user.phoneNumber;
							/*this.loginUserWithPhoneAndToken(result.user.phoneNumber);
							window.$('#modalLogin').modal('hide');*/
						}
					}
				});
		},

		// Validacion del codigo de celular enviado
		validationCodeLogin() {
			console.log('hasPhoneNumberOrEmail', this.hasPhoneNumberOrEmail)
			console.log(this.validationCode)
			if (this.validationCode === '') {
				this.errorValidationCode = true;
				this.errorValidationCode_msg = "Increse el código."
				this.errorToastrMixin('Error', 'Increse el código.');
			}
			else{
				window.confirmationResult.confirm(this.validationCode).then((result) => {
					console.log('tendro del confirmationResult')
					if (this.hasPhoneNumberOrEmail === 1) {
						const user = result.user;
						console.log('user', user);
						this.validationCode = '';
						this.errorValidationCode = false;
						this.errorValidationCode_msg = "";

						this.loginUserWithPhoneAndToken('51'+this.phone);

						window.$('#modalLogin').modal('hide');
					}
					else if (this.hasPhoneNumberOrEmail === 0){
						this.formErrors.direccion.error = false;
						this.formErrors.telefono.error = false;
						this.formErrors.fullname.error = false;
						//this.formErrors.birthdate.error = false;
						this.formErrors.policy.error = false;
						this.formFacebookOrGoogle.telefono = this.phone;
						this.createUser(this.formFacebookOrGoogle);
					}

				})
				.catch((error) => {
					console.log(error);
					this.errorToastrMixin('Error', 'Error al verificar el código, vuelva a intentar más tarde.');
					this.errorValidationCode = true;
					this.errorValidationCode_msg = "Código incorrecto."
				});
			}
		},

		// Validacion del codigo enviado a whatsapp
		validationCodeLoginWhatsapp(isLogin) {
			console.log(this.validationCode)
			console.log('hasPhoneNumberOrEmail', this.hasPhoneNumberOrEmail);
			console.log(isLogin)
			let phone_code = {
				'telefono': '51'+this.phone,
				'codigo': this.validationCode
			}
			console.log(phone_code)
			if (this.validationCode === '') {
				this.errorValidationCode = true;
				this.errorValidationCode_msg = "Increse el código."
				this.errorToastrMixin('Error', 'Increse el código.');
			}
			else if (!isLogin && this.hasPhoneNumberOrEmail != 0) {
				console.log('creacion de cuenta')
				this.createUser(this.form);
			}
			else {
				this.verifyCodeWhatsappMixin(phone_code).then(response => {
					console.log('responde code validated', response);
					if (this.hasPhoneNumberOrEmail === 0) {
						console.log('validando el numero de cel con codigo de wasap')
						this.formErrors.direccion.error = false;
						this.formErrors.telefono.error = false;
						this.formErrors.fullname.error = false;
						//this.formErrors.birthdate.error = false;
						this.formErrors.policy.error = false;
						this.formFacebookOrGoogle.telefono = this.phone;
						this.createUser(this.formFacebookOrGoogle);
					}
					else {
						if (response.data.data == null) {
							this.$store.commit('user/setPhone', this.phone);
							this.$store.commit('user/setIsValidatedPhone', true);
							window.$('#modalLogin').modal('hide');
							window.$('#modalRegisterUser').modal('show');
						}
						else {
							this.successToastrMixin('Login', 'Inicio session con exito.');
							localStorage.setItem('token', response.headers.token);
							localStorage.setItem('user', JSON.stringify(response.data.data));
							this.setNewTokenMixin(response.headers.token);
							this.setNewUserMixin(response.data.data);
							this.$store.commit('user/setCurrentUser', response.data.data);
							this.$store.commit('user/setToken', response.headers.token);
							if (this.getDefaultAddressMixin()) {
								this.createAddress();
								if (this.getProductMixin()) {
									this.$router.push({ name: 'checkout' });
								}
							}
							else {
								this.getUserAddress(true);
							}
							window.$('#modalLogin').modal('hide');
						}
					}
				}).catch((error) => {
					console.log(error);
					this.errorToastrMixin('Error', 'Error al verificar el código, vuelva a intentar más tarde.');
					this.errorValidationCode = true;
					this.errorValidationCode_msg = "Código incorrecto."
				});
			}
		},

		loginUserWithPhoneAndToken(phoneNumber) {
			// Token estatico
			const token = 'xya125';
			let form = {
				'telefono': phoneNumber,
				'token': token
			};
			this.loginUserWithPhoneAndTokenMixin(form).then(response => {
				console.log('loginUserWithPhoneAndToken', response);
				this.successToastrMixin('Login', 'Inicio session con exito.');
				localStorage.setItem('token', response.headers.token);
				localStorage.setItem('user', JSON.stringify(response.data.user));
				this.setNewTokenMixin(response.headers.token);
				this.setNewUserMixin(response.data.user);
				this.$store.commit('user/setCurrentUser', response.data.user);
				this.$store.commit('user/setToken', response.headers.token);
				if (this.getDefaultAddressMixin()) {
					this.createAddress();
					if (this.getProductMixin()) {
						this.$router.push({ name: 'checkout' });
					}
				}
				else {
					this.getUserAddress(true);
				}
			})
			.catch(error => {
				console.log(error);
				this.errorToastrMixin('Error', 'Ocurrio un error, vuelva a intentar más tarde.');
			});
		},

		sendSMS() {
			this.isSMS = true;
			this.validatePhone(this.phone);
			if (!this.captchaIsResolve) {
				this.errorToastrMixin('Error', 'Resuelva el captcha.');
			}
			else if (!this.error) {
				this.signIn(this.phone);
			}
		},

		sendCodeWhatsapp(phoneNumber, isLogin) {
			this.isSMS = false;
			console.log('this.isSMS', this.isSMS)
			this.validatePhone(phoneNumber);
			let phone = {'telefono': '51'+phoneNumber}
			if (!this.error) {
				console.log('from phone', phone)
				this.loginUserWithCodeWhatsappMixin(phone).then(response => {
					console.log('code whatsapp', response)
					this.successToastrMixin('Código', 'Código enviado vía WhatsApp.');
					if (isLogin) {
						this.titleModal = 'Ingrese el codigó';
					}
					else {
						this.titleModalRegister = 'Ingrese el codigó';
					}
					this.validadCodeView = true;
				}).catch((error) => {
					console.log(error);
					this.errorToastrMixin('Error', 'Código no enviado.');
				});
			}
		},

		// Restablecer el captcha para volver a enviar codigo
		resetCaptcha() {
			window.recaptchaVerifier.render().then(function(widgetId) {
				window.grecaptcha.reset(widgetId);
			});
			this.titleModal = 'Iniciar sesíon';
			this.validadCodeView = false;
		},

		addEmailUser() {
			this.validateEmail(this.formLogin.direccion);
			console.log('this.formLoginErrors.direccion.error', this.formLoginErrors.direccion.error)
			if (!this.formLoginErrors.direccion.error) {
				this.formFacebookOrGoogle.direccion = this.formLogin.direccion;
				this.createUser(this.formFacebookOrGoogle);
			}
		},

		validateEmail(email){
			const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
			if (!emailRegex.test(email)) {
				this.formLoginErrors.direccion.error_msg = 'Ingrese correctamente su email.';
				this.formLoginErrors.direccion.error = true;
			}
			else {
				this.formLoginErrors.direccion.error_msg = '';
				this.formLoginErrors.direccion.error = false;
			}
		},

		validatePhone(phoneNumber) {
			const acceptedValue = /^[0-9]+$/;
			if (phoneNumber === '' || phoneNumber.length < 9 || phoneNumber.length > 9 || !phoneNumber.match(acceptedValue)) {
				this.error_msg = 'Ingrese correctamente su número de celular.';
				this.error = true;
			}
			else {
				this.error_msg = '';
				this.error = false;
			}
		},

		validateForm() {
			const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
			if (!emailRegex.test(this.form.direccion)) {
				this.formErrors.direccion.error_msg = 'Ingrese correctamente su email.';
				this.formErrors.direccion.error = true;
			}
			else {
				this.formErrors.direccion.error_msg = '';
				this.formErrors.direccion.error = false;
			}
			const acceptedValue = /^[0-9]+$/;
			if (this.form.telefono === '' || this.form.telefono.length < 9 || this.form.telefono.length > 9 || !this.form.telefono.match(acceptedValue)) {
				this.formErrors.telefono.error_msg = 'Ingrese correctamente su número de celular.';
				this.formErrors.telefono.error = true;
			}
			else {
				this.formErrors.telefono.error_msg = '';
				this.formErrors.telefono.error = false;
			}
			if (this.form.fullname === '') {
				this.formErrors.fullname.error_msg = 'Ingrese su nombre completo.';
				this.formErrors.fullname.error = true;
			}
			else {
				this.formErrors.fullname.error_msg = '';
				this.formErrors.fullname.error = false;
			}
			/*if (this.form.birthdate === '') {
				this.formErrors.birthdate.error_msg = 'Ingrese su fecha de nacimiento.';
				this.formErrors.birthdate.error = true;
			}
			else {
				this.formErrors.birthdate.error_msg = '';
				this.formErrors.birthdate.error = false;
			}*/
			if (!this.policy) {
				this.formErrors.policy.error_msg = 'Acepte los términos  y condiciones.';
				this.formErrors.policy.error = true;
			}
			else {
				this.formErrors.policy.error_msg = '';
				this.formErrors.policy.error = false;
			}
		},

		clearForm() {
			this.form.direccion = '';
			this.form.telefono = '';
			this.form.fullname = '';
			this.form.birthdate = '';
			this.policy = false;
		},

		// Limpiar local storage
		clearLocalStorage() {
			localStorage.removeItem('token');
			localStorage.removeItem('user');
		}
	}
};

export default loginAndCreateUser;