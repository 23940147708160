<template>
  <div>
    <div id="dev-menu-orders-list">

      <!-- List of products -->
      <ListProducts :isCheckout="false" :isShoppingCart="true" @movecartlist="sendEmitmove($event)"></ListProducts>
      <!-- List of products End-->

      <div v-if="showDetailCart">
        <div class="price-area dev-menu-price-con">
          <h5>Direción de entrega</h5>
          <div>
            <div class="pull-right">
              <a href="" class="btn btn-danger btn-xs" data-toggle="modal" data-target="#myModalChangeAddress"><i class="fas fa-pencil-alt"></i>Cambiar</a>
            </div>
            <div class="mr-7">
              <i class="fas fa-home"></i>
              <span style="">
                {{ defaultAddress.direccion }} - {{ defaultAddress.referencia }}
              </span>
            </div>
          </div>
        </div>

        <div class="price-area dev-menu-price-con">
          <!-- Coupons -->
          <Coupons :isCheckout="false" :isShoppingCart="true"></Coupons>
          <!-- Coupons End-->
        </div>

        <!-- summary prdocts -->
        <Summary :isCheckout="false"></Summary>
        <!-- summary prdocts End-->

        <router-link :to="{name: 'checkout'}" class="btn btn-danger btn-block">Ir a pagar S/. {{ getTotal.toFixed(2) }}</router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>
.mr-7 {
  margin-right: 72px !important;
}
</style>

<script>
import ListProducts from '@/components/ListProducts.vue';
import Summary from '@/components/Summary.vue';
import Coupons from '@/components/Coupons.vue';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'ShoppingCart',

  components: { ListProducts, Summary, Coupons },

  props: ['showDetailCart'],

  data() {
    return {
      addressUser: [],
      fromSetting: {
        'lat': '',
        'lng': ''
      },
      settingCityData: '',
    }
  },

  created() {
    if (this.isAuthenticatedMixin) {
      if (this.getEmpresaLocalMixin()) {
        this.setEmpresaMixin(this.getEmpresaLocalMixin());
      }
      this.getUserAddress();
    }
  },

  computed: mapState('defaultAddress', {
      // state
      defaultAddress: state => state.defaultAddress,
      // getters
      ...mapGetters('shoppingCart', ['getTotal'])

    }),

  methods: {
    sendEmitmove(event){
      this.$emit('movecartlistshop', event)
    },

    getUserAddress() {
      var addressDefault = {};
      this.getAddressUserMixin().then(response => {
        this.addressUser = response.data;
        for(let i = 0; i < this.addressUser.direcciones.length; i++){
          if (this.addressUser.direcciones[i].estado === 1){
            addressDefault = this.addressUser.direcciones[i];
            this.fromSetting.lat = addressDefault.lat;
            this.fromSetting.lng = addressDefault.lng;
            this.setDefaultAddressMixin(addressDefault);
            this.settingCity();
            console.log('addressDefault', addressDefault);
            i = this.addressUser.direcciones.length;
          }
        }
      });
    },

    settingCity() {
      console.log('this.fromSetting', this.fromSetting)
      this.settingCityMixin(this.fromSetting).then(response => {
        console.log('setting', response);
        this.settingCityData = response.data.configuracion;
        this.$store.commit('shoppingCart/setDeliverygratis', response.data.configuracion.deliverygratis);
        console.log('deliverygratis', this.$store.state.shoppingCart.deliverygratis)
        this.$store.commit('shoppingCart/setRainCharge', parseInt(this.settingCityData.lluvia));
      });
    },

  },

}
</script>
