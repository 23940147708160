<template>
  <div v-if="shownow" class="carousel-inner" role="listbox">
    <div class="item active">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-4" v-for="i in many" :key="i">
          <div class="container">
            <div class="background">
              <div class="right">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{

    }
  },
  props: ['shownow', 'many']
}
</script>

<style scoped>
  .container{
    justify-content: space-between;
  }
  .container {
    background: white;
    border: 1px solid #e5e6e9;
    border-radius: 15px;
    width: 285px;
    height: 160px;
    padding: 0px;
    margin: 0 auto;
  }
  .container {
    animation-duration: 5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: preloadAnimation;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    display: flex;
		min-height: 100px;
  }
  @keyframes preloadAnimation{
    0%{
        background-position: -769px 0
    }
    100%{
        background-position: 769px 0
    }
  }
</style>