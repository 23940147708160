<template>
  <div>
    <div class="price-area dev-menu-price-con">
      <h5>Resumen</h5>
      <ul>
        <li>Costo de productos <span class="price">S/. <em class="dev-menu-subtotal">{{ getCostProducts.toFixed(2) }}</em></span></li>
        <li>Costo de envio<span class="price">S/. <em class="dev-menu-vtax">{{ costShipping.toFixed(2) }}</em></span></li>
        <li v-show="rainCharge > 0">Cargo por lluvia<span class="price">S/. <em class="dev-menu-vtax">{{ rainCharge.toFixed(2) }}</em></span></li>
        <li>Monto de descuento<span class="price">S/. <em class="dev-menu-vtax">{{ discountAmount.toFixed(2) }}</em></span></li>
      </ul>
      <p class="total-price">Total del pedido <span class="price">S/. <em class="dev-menu-grtotal"> {{ getTotal.toFixed(2) }}</em></span></p>
    </div>
    <div id="dev-no-menu-orders-list">
      <span class="success-message">El costo de envió es en base a la distancia {{ distance }} km.</span>
    </div>
    <div class="pay-option dev-order-pay-options">
      <form class="row">
        <div v-for="payment in paymentMethods" :key="payment.codmetodopago" class="col-sm-6">
          <div class="align-payment">
            <img v-if="payment.codmetodopago != 1" :src="urlImgMixin + payment.imagen" class="img-list wp-post-image" alt="avatar-z" width="50px">
            <img v-else="" :src="require('@/assets/images/dinero.jpg')" class="img-list wp-post-image" alt="avatar-z" width="50px">
            <div class="form-inline">
              <input v-model="paymentRadio" v-on:change.prevent="chickPaymentRadio" :id="payment.codmetodopago" :value="payment.codmetodopago" type="radio">
              <label :for="payment.codmetodopago" style="padding-left: 5px;"> {{ payment.nombre }}</label>
            </div>
          </div>
        </div>
      </form>
    </div>
    <form v-show="paymentRadio == 1 && isCheckout">
      <div class="row input-container">
        <div class="col-12 input-effect">
          <input
          :value="customerCash" @input="setCustomerCash"
          type="number"
          class="effect-16 input-style"
          :class="{'has-content' : hasTextOnInputMixin(customerCash)}"
          placeholder=""
          step="0.1" min="0">
          <label class="effect-label">Monto</label>
          <span class="focus-border"></span>
        </div>
      </div>
    </form>
  </div>
</template>

<style scoped src="@/css/style-inputs.css"></style>
<style scoped>
.align-payment {
  margin: auto;
  width: 90%;
  margin-left: 15px;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'Summary',

  props: ['isCheckout'],

  data() {
    return {
      paymentMethods: [],
      paymentRadio: '',
    }
  },

  created() {
    if (this.isAuthenticatedMixin) {
      this.getPaymentMethod();
      this.paymentRadio = this.paymentMethod;
    }
  },

  computed: mapState('shoppingCart', {
    // state
    distance: state => state.distance,
    costShipping: state => state.costShipping,
    rainCharge: state => state.rainCharge,
    discountAmount: state => state.discountAmount,
    paymentMethod: state => state.paymentMethod,
    customerCash: state => state.customerCash,
    // getters
    ...mapGetters('shoppingCart', ['getCostProducts', 'getTotal'])

  }),

  methods: {
    getPaymentMethod() {
      this.getPaymentMethodMixin().then(response => {
        this.paymentMethods = response.data;
      });
    },

    chickPaymentRadio() {
      this.$store.commit('shoppingCart/setPaymentMethod', this.paymentRadio);
    },

    setCustomerCash(e) {
      this.$store.commit('shoppingCart/setCustomerCash', e.target.value);
    }

  }
}
</script>