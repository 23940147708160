<template>
  <!-- Main Section Start -->
  <div class="main-section">
    <div class="page-section nopadding cs-nomargin" style="margin-top: 0px;padding-top: 60px;padding-bottom: 10px;margin-bottom: 0px;">
      <div class="container">
        <div v-if="historicalOrder" class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <h1>Detalles del pedido</h1>
          </div>

          <result-order></result-order>

        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>

<script>
import ResultOrder from '@/components/ResultOrder.vue';
import { mapState } from 'vuex';

export default {
  name: 'HistoricalOrder',

  props: ['resultOrder'],

  components: { ResultOrder },

  created() {
    if (!this.historicalOrder) {
      this.$router.push({ name: 'Index' });
    }
    this.closePendingOrders();
    console.log('historicalOrder', this.historicalOrder)
  },

  computed: mapState({
    historicalOrder: state => state.order.historicalOrder,
  }),

  methods: {
    closePendingOrders() {
      window.$("#myModalHistoricalOrders").modal("hide");
    },
  }

}
</script>

