
import axios from 'axios'
import configs from '@/utils/configs'

var formsAPI = {
  methods: {
    sendFormNewZtasMixin(data){
      const url = configs.apiUrl + '/nuevoszetas';
			return axios.post(url, data, {
				headers: {'Authorization': 'bearer ' + this.$store.state.user.token}
			});
    },

    sendFormBusinessMixin(data){
      const url = configs.apiUrl + '/nuevasempresas';
			return axios.post(url, data, {
				headers: {'Authorization': 'bearer ' + this.$store.state.user.token}
			});
    },
  }
};
export default formsAPI;