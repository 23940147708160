<template>
  <div class="modal fade  come-from-modal right" id="modalRegisterUser" tabindex="-1" role="dialog" aria-labelledby="modalRegisterUser">
    <div class="modal-dialog" role="document" id="modal-login-size">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" id="closeButtonmodalRegister" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="fas fa-times-circle"></i></span></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xs-12 text-center">
              <img :src="require('@/assets/images/MOTO.png')" class="img-list wp-post-image" alt="avatar-z" width="120px" style="margin-top: -20px;">
              <h4 class="py-3-h h4">{{ titleModalRegister }}</h4>
            </div>

            <!-- registro -->
            <div v-show="!validadCodeView">
              <div class="col-xs-12">
                <div v-if="!isValidatedPhone" class="center-block width-center">
                  <button v-on:click.prevent="goLoginOrRegisterWithFacebook" class="center-block btn btn-block btn-facebook"><i class="fab fa-facebook-square"></i> Facebook</button>
                  <button v-on:click.prevent="goLoginOrRegisterWithGoogle" class="center-block btn btn-block btn-google">
                    <img class="img-list wp-post-image color-img" :src="require('@/assets/images/google.png')" alt="Perú" width="20px;"> Google
                  </button>
                  <h4 class="text-center">O</h4>
                </div>
              </div>

              <div class="col-xs-12">
                <div class="center-block width-center">
                  <div class="row">
                    <div class="col-xs-12">
                      <div class="row">
                        <div class="col-9 input-effect">
                          <input v-model="form.fullname" :class="{'has-error': formErrors.fullname.error, 'has-content' : hasTextOnInputMixin(form.fullname)}" class="effect-16 input-style" type="text" placeholder="">
                          <label class="effect-label">Nombres y apellidos</label>
                          <span class="focus-border"></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-12">
                      <span class="text-danger" ole="alert">{{ formErrors.fullname.error_msg }}</span>
                    </div>
                    <div class="col-xs-12">
                      <div class="row">
                        <div class="col-9 input-effect">
                          <input v-model="form.direccion" :class="{'has-error': formErrors.direccion.error, 'has-content' : hasTextOnInputMixin(form.direccion)}" class="effect-16 input-style" placeholder="" type="email" required>
                          <label class="effect-label">Email</label>
                          <span class="focus-border"></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-12">
                      <span class="text-danger" ole="alert">{{ formErrors.direccion.error_msg }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xs-12">
                <span v-show="false">{{ setPhoneOfLogin }}</span>
                <div class="center-block width-center">
                  <span v-if="!isValidatedPhone">
                    <div class="block-phone">
                      <div class="country-icon">
                        <img class="img-list wp-post-image" :src="require('@/assets/svg/peru.svg')" alt="Perú" width="30px;">
                        <span class="text-number">+51</span>
                        <span class="caret"></span>
                      </div>
                      <div class="phone-input">
                        <input v-model="form.telefono" :class="{'has-error': formErrors.telefono.error}" type="number" class=" " placeholder="Escribe tu número">
                      </div>
                    </div>
                    <span v-show="formErrors.telefono.error" style="margin-bottom: 10px">
                      <span class="text-danger" ole="alert">{{ formErrors.telefono.error_msg }}</span>
                    </span>
                  </span>

                  <div class="terms-and-conditions">
                    <input v-model="policy" type="checkbox" id="terms-conditions">
                    <label for="terms-conditions">
                      <span class="accept-span"> Acepta los</span>
                      <a v-on:click.prevent="gotTermsAndConditions" target="_blank" href="">
                        Términos &amp; condiciones del tema y la política de privacidad.
                      </a>
                    </label>
                  </div>
                  <span v-show="formErrors.policy.error">
                    <span class="text-danger" ole="alert">{{ formErrors.policy.error_msg }}</span>
                    <br>
                  </span>

                  <span v-show="false">
                    <div v-if="!isValidatedPhone" class="text-center" style="margin-top: 15px">
                      <label class="radio-inline">
                        <input v-model="typeVerify" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="whatsapp"> Verificar vía Whatsapp
                      </label>
                      <label class="radio-inline">
                        <input v-model="typeVerify" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="sms"> Verificar vía SMS
                      </label>
                    </div>
                  </span>

                  <div style="margin-top: 5px;">
                    <button v-if="!isValidatedPhone" v-on:click.prevent="sendSMSorCodeWhatsapp" class="btn btn-primary btn-lg btn-block" style="padding: 8px 8px;">Registrarme</button>
                    <button v-else v-on:click.prevent="createUser(form)" class="btn btn-primary btn-lg btn-block" style="padding: 8px 8px;">Registrarme</button>
                  </div>
                  <div class="text-center footer-link">
                    <span class="">¿Tienes una cuenta?</span>
                    <a v-on:click.prevent="goLogin" href="" class="btn-red-send" style="font-size: 14px;"> Inicia Sesíon</a>
                  </div>
                </div>
              </div>
            </div>
            <!-- End registro -->
            <!-- Validad SMS or codigo whatsapp enviado-->
            <div v-show="validadCodeView" class="col-xs-12">
              <div class="center-block width-center">
                <div class="text-center">
                  <p>Enviamos un código de verificación de 6 digitos a +51 {{ phone }}</p>
                </div>
                <div class="center-block">
                  <div class="input-wrapper">
                    <PincodeInput
                    v-model="validationCode"
                    placeholder="0"
                    :length="lengthInputPhone"
                    />
                  </div>
                  <span class="text-danger" ole="alert">{{ errorValidationCode_msg }}</span>
                  <div style="margin-bottom: 30px;"></div>
                </div>

                <button v-if="isSMS" v-on:click.prevent="validationCodeLogin" class="btn btn-danger btn-lg btn-block" style="padding: 8px 8px;"><i class="fas fa-arrow-right"></i> Continuar</button>
                <button v-else v-on:click.prevent="validationCodeLoginWhatsapp(false)" class="btn btn-danger btn-lg btn-block" style="padding: 8px 8px;"><i class="fas fa-arrow-right"></i> Continuar</button>

                <div class="text-center resend center-block">
                  <span>¿No has recibido el código?</span><br>
                  <a v-on:click.prevent="resetCaptcha" href=""><strong>Reenviar código</strong></a>
                </div>
              </div>
            </div>
            <!-- End Validad SMS or codigo whatsapp enviado-->

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped src="@/css/style-modals-login.css"></style>
<style scoped src="@/css/style-inputs.css"></style>
<style scoped>
.accept-span {
  color: #777;
  padding-left: 7px;
}
.terms-and-conditions {
  display:flex;
  padding-top: 15px;
}
</style>

<script>
import PincodeInput from 'vue-pincode-input';
import loginAndCreateUser from '@/mixins/local/loginAndCreateUser.js';
import { mapState } from 'vuex';
import $ from 'jquery';

export default {
  name: 'ModalRegisterUser',

  components: { PincodeInput },

  mixins: [loginAndCreateUser],

  data() {
    return {
      typeVerify: 'whatsapp',
      titleModalRegister: 'Registrate en Zpedidos'
    }
  },

  computed: mapState({
    phoneLogin: state => state.user.phoneLogin,
    isValidatedPhone: state => state.user.isValidatedPhone,
    setPhoneOfLogin() {
      if (this.phoneLogin) {
        this.form.telefono = this.phoneLogin;
        return this.phoneLogin;
      }
      else {
        return 1;
      }
    }
  }),

  methods: {
    goLogin() {
      window.$("#modalRegisterUser").modal("hide");
      window.$("#modalLogin").modal("show");
    },

    goLoginOrRegisterWithFacebook() {
      this.goLogin();
      $(document).ready(function(){
        document.getElementById('loginWithFacebook').click();
      });
    },

    goLoginOrRegisterWithGoogle() {
      this.goLogin();
      $(document).ready(function(){
        document.getElementById('loginWithGoogle').click();
      });
    },

    gotTermsAndConditions() {
      let routeData = this.$router.resolve({ name: 'terminosycondiciones' });
      window.open(routeData.href, '_blank');
    },

    sendSMSorCodeWhatsapp() {
      this.validateForm();
      if (!this.formErrors.direccion.error && !this.formErrors.telefono.error && !this.formErrors.fullname.error && !this.formErrors.policy.error) {
        if (this.typeVerify == 'whatsapp') {
          console.log('whatsapp')
          this.sendCodeWhatsapp(this.form.telefono, false);
        }
        else {
          console.log('sms')
          //this.sendSMS();
        }
      }
    }

  }
}
</script>