import Vue from 'vue'
import Vuex from 'vuex'
import shoppingCart from './modules/shoppingCart'
import defaultAddress from './modules/defaultAddress'
import order from './modules/Order'
import user from './modules/User'
import search from './modules/search'
import zcourier from './modules/ZCourier'
import searchBusiness from './modules/searchBusiness'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {

	},

	getters: {

	},

	mutations: {

	},

	modules: {
		shoppingCart,
		defaultAddress,
		order,
		user,
		search,
		zcourier,
		searchBusiness
	}

})
