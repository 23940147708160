import axios from 'axios'
import configs from '@/utils/configs'

var apiUserMixin = {

	methods: {
		createUserMixin(form) {
			const apiURL = configs.apiUrl + '/register2';
			return axios.post(apiURL, form);
		},

		loginUserWithPhoneAndTokenMixin(phone_token) {
			const apiURL = configs.apiUrl + '/usuario/loginphone';
			return axios.post(apiURL, phone_token);
		},

		loginUserWithCodeWhatsappMixin(phone) {
			const apiURL = configs.apiUrl + '/usuario/codigowhatsapp';
			return axios.post(apiURL, phone, {
				headers: {'Authorization': 'bearer ' + this.getTokenStaticMixin}
			});
		},

		verifyCodeWhatsappMixin(phone_code) {
			const apiURL = configs.apiUrl + '/usuario/verificarwhatsapp';
			return axios.post(apiURL, phone_code, {
				headers: {'Authorization': 'bearer ' + this.getTokenStaticMixin}
			});
		},

		varifyUserFacebookOrGoogleMixin(phone_email) {
			const apiURL = configs.apiUrl + '/usuario/verificarfacebook';
			return axios.post(apiURL, phone_email, {
				headers: {'Authorization': 'bearer ' + this.getTokenStaticMixin}
			});
		},

		updateUserMixin(code_user, form, token) {
			const apiURL = configs.apiUrl + '/usuario/' + code_user;
			return axios.put(apiURL, form, {
				headers: {'Authorization': 'bearer ' + token}
			});
		}
	}
};

export default apiUserMixin;