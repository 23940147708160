<template>
	<div>
		<!-- Browse By Cuisine Section Start -->
		<div class="page-section nopadding cs-nomargin" style="margin-top: 0px;padding-top: 60px;padding-bottom: 0px;margin-bottom: 0px;">
			<div class="container">
				<div class="row">
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<!--<div class="element-title align-left">
							<h2>Nuestras tiendas</h2>
							<p>Consigue lo que necesitas</p>
						</div>-->
						<div class="block-categorys" >
							<div  class="col-lg-2 col-md-3 col-sm-4 col-xs-6">
								<div class="block-items">
									<div class="block-img">
										<router-link :to="{ name: 'categoria', params: { nombre: generateSlug('Restaurantes'), id: 7} }">
											<img alt="" class="imagenanimation"  :src="require('@/assets/images/restaurantes.jpg')" >
										</router-link>
									</div>
									<h4 class="show-more">Restaurantes</h4>
								</div>
							</div>

							<div  class="col-lg-2 col-md-3 col-sm-4 col-xs-6">
								<div class="block-items">
									<div class="block-img">
										<a href="" v-on:click.prevent="goZCourrier">
											<img alt="" class="imagenanimation"  :src="require('@/assets/images/zcourrier.png')" >
										</a>
									</div>
									<h4 class="show-more">ZCourrier</h4>
								</div>
							</div>

							<span v-for="(category, t) in categories" :key="t">
								<div  v-if="t < 3" class="col-lg-2 col-md-3 col-sm-4 col-xs-6">
									<div class="block-items">
										<div class="block-img">
											<router-link :to="{ name: 'categoria', params: { nombre: generateSlug(category.nombrecat), id: category.codcategoria} }">
												<img v-if="category.imagencat" class="imagenanimation" :src="urlImgMixin+ category.imagencat">
												<img v-else alt="" class="imagenanimation"  src="urlImgMixin + tiendas%20cat.png">
											</router-link>
										</div>
										<h4><span>{{ category.nombrecat }}</span></h4>
									</div>
								</div>
							</span>
							<div  class="col-lg-2 col-md-3 col-sm-4 col-xs-6">
								<div class="block-items">
									<div class="block-img">
										<a href="" data-toggle="modal" data-target="#showMoreCategories">
											<img alt="" class="imagenanimation"  :src="require('@/assets/images/more-stores.jpg')" >
										</a>
									</div>
									<h4 class="show-more">Ver más</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Browse By Cuisine Section End -->
		<!-- Modal -->
		<div class="modal" id="showMoreCategories" tabindex="-1" role="dialog">
			<div class="modal-dialog" id="modalTamanio" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<button type="button" id="closeButtonModalMoreCategories" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="fas fa-times-circle"></i></span></button>
						<h2><a>Ver más</a></h2>
					</div>
					<div class="modal-body">
						<div class="categories-holder" style="padding-left: -10px; border: none;">
							<span v-for="(category, t) in categories" :key="t">
								<a href="" v-if="t >= 3" class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
									<div class="block-items">
										<div class="block-img">
											<img v-if="category.imagencat" v-on:click.prevent="showCategories(category)"   class="imagenanimation" :src="urlImgMixin+ category.imagencat">
											<img v-else alt="" v-on:click.prevent="showCategories(category)"  class="imagenanimation" src="urlImgMixin + tiendas%20cat.png" >
										</div>
										<h6><span>{{ category.nombrecat }}</span></h6>
									</div>
								</a>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Modal End -->
	</div>
</template>

<style scoped>
.block-categorys{
	/*display: flex;*/
	justify-content: center;
	align-items: center;
	text-align:center;
	min-width:250px
}
.block-categorys h4{
	color:#fff !important;
	font-weight: 500 !important;
	font-size: 14px !important;
}
.block-items{
	display: flex;
	flex-direction: column;
	align-items: center;
}
.block-img {
	height: 150px !important;
	width: 150px  !important;
	background: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	margin-bottom:5px;
}
.block-img img{
	max-width: 120px ;
	width: auto;
	height: auto;
	max-height: 120px ;
	border-radius: 5px;
}
.marginleft {
	margin-left: -45px;
}
.show-more {
	white-space:nowrap;
	padding-left: 7px;
}
#modalTamanio{
	width: 80% !important;
}
.imagenanimation {
	animation: zoom infinite 1s;
}
@keyframes zoom {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(.95);
	}
	100% {
		transform: scale(1);
	}
}
</style>

<script>
import $ from 'jquery';
import { mapGetters } from 'vuex';

export default {
	name: 'Category',

	data() {
		return {
			categories: [],
		}
	},

	created() {
		if (this.isAuthenticatedMixin && this.getDefaultAddressMixin()){
			this.getCategories();
		}
		else if (this.getDefaultAddressMixin()) {
			this.getCategories();
		}
	},

	computed: {
		...mapGetters('user', ['isAuthenticated']),
	},

	methods: {
		getCategories() {
			let defaultAddress = this.getDefaultAddressMixin();
			let lat_long = {
				'lat': defaultAddress.lat,
				'lng': defaultAddress.lng
			};
			console.log('lat_long', lat_long)
			this.getCategoriesMixin(lat_long).then(response => {
				console.log('category', response.data);
				this.categories = response.data.categorias;
				const newCategories=[];
				this.categories.map((item)=>{
					if(item.bloquev2==1){
						newCategories.push(item);
					}
				});
				this.categories =newCategories;
			});
		},

		showCategories(category){
			$(document).ready(()=>{
				document.getElementById('closeButtonModalMoreCategories').click();
				this.$router.push({  name: 'categoria', params: { nombre: this.generateSlug(category.nombrecat), id: category.codcategoria} });
			});
		},

		goZCourrier() {
			if (!this.isAuthenticated) {
				window.$("#modalLogin").modal("show");
			}
			else {
				this.$router.push({ name: 'zcourier' });
			}
		}
	}
}
</script>