import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Axios
import axios from 'axios'
import VueAxios from 'vue-axios'

// Toastr
import VueToastr2 from 'vue-toastr-2'
import 'vue-toastr-2/dist/vue-toastr-2.min.css'

// Fontawesome
import '@fortawesome/fontawesome-free/css/all.css';

//vueSpinners
import { VueSpinners } from '@saeris/vue-spinners'

// Firebase
import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication

//lightbox
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

// Vue Snip
import VueSnip from 'vue-snip'

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyB-ekEv__WeA8URrV284lo_7ZMcFfR1j7U",
  authDomain: "pedidos-238817.firebaseapp.com",
  databaseURL: "https://pedidos-238817.firebaseio.com",
  projectId: "pedidos-238817",
  storageBucket: "pedidos-238817.appspot.com",
  messagingSenderId: "689644915310",
  appId: "1:689644915310:web:62c66244b5875606cef47f",
  measurementId: "G-9J6VMV33WV",
};

// vue-content-placeholders
import VueContentPlaceholders from 'vue-content-placeholders'

// mixins
import shoppingCartMixin from './mixins/shoppingCart.js'
import distanceGoogle from './mixins/distanceGoogle.js'
import toastrMixin from './mixins/toastr.js'
import authMixin from './mixins/auth.js'
import businessLocalMixin from './mixins/businessLocal.js'
import defaultAddressMixin from './mixins/defaultAddress.js'
import generateSlug from './mixins/generateSlug.js'
import configMixin from './mixins/config.js'
import inputStyleMixin from './mixins/inputStyle.js'
// api
import apiAddresMixin from './mixins/apiRestMixin/apiAddress.js'
import apiBusinessMixin from './mixins/apiRestMixin/apiBusiness.js'
import apiProductMixin from './mixins/apiRestMixin/apiProduct.js'
import apiUserMixin from './mixins/apiRestMixin/apiUser.js'
import apiShoppingCartMixin from './mixins/apiRestMixin/apiShoppingCart.js'
import apiOrderMixin from './mixins/apiRestMixin/apiOrder.js'
import apiOthersMixin from './mixins/apiRestMixin/apiOthers.js'
import formsAPI from './mixins/forms.js'

window.toastr = require('toastr')

Vue.config.productionTip = false

// VueSweetalert2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

//glider.js
import VueGlide from 'vue-glide-js'
import 'vue-glide-js/dist/vue-glide.css'

Vue.use(VueAxios, axios)
Vue.use(VueToastr2)
Vue.use(VueContentPlaceholders)
Vue.use(VueSpinners)
Vue.use(VueSweetalert2)
Vue.use(CoolLightBox)
Vue.use(VueGlide)
Vue.use(VueSnip)

// mixins
Vue.mixin(authMixin)
Vue.mixin(shoppingCartMixin)
Vue.mixin(toastrMixin)
Vue.mixin(distanceGoogle)
Vue.mixin(businessLocalMixin)
Vue.mixin(defaultAddressMixin)
Vue.mixin(generateSlug)
Vue.mixin(configMixin)
Vue.mixin(inputStyleMixin)

// api
Vue.mixin(apiAddresMixin)
Vue.mixin(apiBusinessMixin)
Vue.mixin(apiProductMixin)
Vue.mixin(apiUserMixin)
Vue.mixin(apiShoppingCartMixin)
Vue.mixin(apiOrderMixin)
Vue.mixin(apiOthersMixin)
Vue.mixin(formsAPI)

firebase.initializeApp(firebaseConfig);

new Vue({
 router,
 store,
 render: h => h(App)
}).$mount('#app')

