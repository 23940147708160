var cartMixin = [];

var shoppingCartMixin = {

	created() {
		getCartStorage();
	},

	// public methods
	methods: {
		addProductMixin(product, add=false) {
			isPersonalized(product) ?
			addProductPersonalized(product, add) : addProductOrdinary(product,add);
			this.$store.commit('shoppingCart/setProducts', cartMixin);
			setStorageCart();
		},

		getProductMixin() {
			return cartMixin;
		},

		clearShoppingCartMixin() {
			cartMixin = [];
			localStorage.removeItem('CART');
			//setStorageCart();
			this.$store.commit('shoppingCart/setProducts', cartMixin);
		},

		isCartZeroMixin() {
			return cartMixin.length == 0;
		},

		setToCartMixin(products){
			cartMixin = products;
			this.$store.commit('shoppingCart/setProducts', cartMixin);
			console.log("cartMixin",cartMixin)
		}

	}
};

// private function
function addProductPersonalized(product, add) {
	if (hasTempCode(product)) {
		const index = searchCodTemp(product);
		if(add){
			cartMixin[index] = product
		}else{
			cartMixin[index] = product
			cartMixin[index].count++;
		}
		return;
	}
	createTempCode(product);
	cartMixin.push(product);
}

function addProductOrdinary(product,add) {
	const index = searchProduct(product);
	if (index != undefined) {
		if(add){
			cartMixin[index] = product
		}else{
			cartMixin[index].count++;
			product.count = cartMixin[index].count;
		}
		return;
	}
	product.count = 1;
	cartMixin.push(product);
}

function hasTempCode(product) {
	return product.codTemp;
}

function isPersonalized(product) {
	return product.nuevo == 1;
}

function createTempCode(product) {
	const count = countProduct(product);
	product.codTemp = count + 1;
	product.count = 1;
}

// other methods
function searchCodTemp(product) {
	let exist = undefined;
	for (let i = 0; i < cartMixin.length; i++) {
		if (cartMixin[i].codproducto == product.codproducto &&
			cartMixin[i].codTemp == product.codTemp) {
			exist = i;
		break;
	}
}
return exist;
}

function countProduct(product) {
	let count = 0;
	for (let i = 0; i < cartMixin.length; i++) {
		if (cartMixin[i].codproducto == product.codproducto) {
			count++;
		}
	}
	return count;
}

function searchProduct(product) {
	let exist = undefined;
	for (let i = 0; i < cartMixin.length; i++) {
		if (cartMixin[i].codproducto == product.codproducto) {
			exist = i;
			break;
		}
	}
	return exist;
}

function getCartStorage() {
	let cart = JSON.parse(localStorage.getItem('CART'));
	if (cart) {
		cartMixin = cart;
	}
}

function setStorageCart() {
	localStorage.setItem('CART', JSON.stringify(cartMixin));
}

export default shoppingCartMixin;