<template>
	<div class="row">
		<div v-for="item in 8" :key="item" class="col-lg-3 col-md-4 col-sm-6 col-xs-12" style="margin-bottom: 25px;">
			<span v-show="false">{{ item }}</span>
			<content-placeholders :rounded="true">
				<content-placeholders-img />
				<content-placeholders-text :lines="5" />
				<content-placeholders-heading :img="true" />
				<content-placeholders-text :lines="2" />
			</content-placeholders>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ContentPlaceHoldersSearch',

}
</script>

