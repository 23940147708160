import { render, staticRenderFns } from "./RepartidoresRegistro.vue?vue&type=template&id=105c8c82&scoped=true&"
import script from "./RepartidoresRegistro.vue?vue&type=script&lang=js&"
export * from "./RepartidoresRegistro.vue?vue&type=script&lang=js&"
import style0 from "@/css/style-inputs.css?vue&type=style&index=0&id=105c8c82&scoped=true&lang=css&"
import style1 from "./RepartidoresRegistro.vue?vue&type=style&index=1&id=105c8c82&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "105c8c82",
  null
  
)

export default component.exports