<template>
 <div class="modal" id="modalAddress" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document" id="modal-address-size">

    <template>
      <div class="modal-content" v-show="listModal === 1">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="fas fa-times-circle"></i></span></button>
          <h2>Dirección</h2>
        </div>
        <div class="modal-body">
          <div class="menu-selection-container">
            <div class="extras-detail-main">
              <span v-if="$route.name != 'checkout'">
                <span v-if="$route.name != 'zcourier'">
                  <p v-if="isAuthenticatedMixin">Hola {{ user.fullname }},</p>
                  <p>¿A dónde quieres pedir hoy?</p>
                </span>
              </span>
              <div class="menu-selection-container">
                <h4 class="py1">Direcciones guardadas</h4>
                <HashLoader
                class="center-block"
                :loading="loading"
                :size="120"
                :color="'#0cc665'"
                ></HashLoader>
                <span v-show="!loading">
                  <div v-for="address in addressUser.direcciones" :key="address.coddireccion" class="list-group" style="margin-bottom: 10px;">
                    <a v-if="$route.name != 'zcourier'" v-on:click.prevent="updateStateAddress(address.coddireccion, address.estado)" href="" class="list-group-item container-address-list">
                      <i class="far fa-flag icon-flag"></i>
                      <div class="text-address">{{ address.direccion }} - {{ address.referencia }}</div>
                      <div class="color-green pull-right">
                        <i v-if="address.estado === 1" class="fas fa-check-circle icon-check"></i>
                      </div>
                    </a>
                    <a v-else v-on:click.prevent="selectAddressZCurrier(address)" href="" class="list-group-item container-address-list">
                      <i class="far fa-flag icon-flag"></i>
                      <div class="text-address">{{ address.direccion }} - {{ address.referencia }}</div>
                    </a>
                  </div>
                </span>
                <div v-if="isNullAddress">
                  <p>No tienes direcciones guardadas</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="menu-selection-container">
            <div class="extras-btns-holder">
              <div class="text-center">
                <a v-on:click.prevent="changeModal(0)" class="btn btn-danger btn-lg btn-block"><i class="fas fa-plus-circle"></i> Añadir nueva dirección</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template>
     <div class="modal-content" v-show="listModal === 0">
      <div class="modal-header" style="padding-right: 0px;">
        <h2 class="modal-center text-center">
          <a v-on:click.prevent="changeModal(1)" href=""><i class="fas fa-chevron-left"></i></a>
          Perú
          <button type="button" class="close pull" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="fas fa-times-circle"></i></span></button>
        </h2>
      </div>
      <div class="modal-body">
        <div class="menu-selection-container" id="list" style="overflow: auto; height: 300px; padding-left: 30px; padding-right: 30px; margin-top: 5px; margin-bottom: 10px">
          <h5 class="py-3">Escoge una ciudad</h5>
          <div>
            <ul v-for="city in cities" :key="city.codciudad">
              <li v-on:click.prevent="changeModal(2, city)" class="py-3 showIconA">
                <a>
                  {{ city.nombre }}
                  <i class="fas fa-chevron-right pull-right showIcon"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </template>

  <template >
    <div class="modal-content" v-show="listModal === 2">
      <div class="modal-header" style="padding-left: 0px;">
        <h4 class="modal-center">
          <a v-on:click.prevent="changeModal(0)" href=""><i class="fas fa-chevron-left" style="margin-left: 25px;"></i></a>
          <span class="text-center" style="margin-left: 50px;">Información de la dirección</span>
          <button type="button" id="closeButtonModalCreateAddress" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="fas fa-times-circle"></i></span></button>
        </h4>
      </div>
      <div class="modal-body">
        <form class="row input-container">
          <div class="col-xs-12">
            <div class="row">
              <div class="col-12 input-effect" id="showIconClear">
                <input
                v-model="form.direccion"
                v-on:keyup="searchAddress('autocompleteModalAddress')"
                id="autocompleteModalAddress"
                type="text"
                class="effect-16 input-style"
                :class="{'has-error': formErrors.direccion.error, 'has-content' : hasTextOnInputMixin(form.direccion)}"
                placeholder="">
                <label class="effect-label">Dirección</label>
                <span class="focus-border"></span>
                <span @click="clearInputAddress" class="showIconClear"><i class="fas fa-times showIconClear"></i></span>
              </div>
            </div>
          </div>
          <div class="col-xs-12">
            <span class="text-danger" ole="alert">{{ formErrors.direccion.error_msg }}</span>
          </div>
          <div class="col-xs-12">
            <div class="row">
              <div class="col-12 input-effect">
                <input v-model="form.referencia" type="text" class="effect-16 input-style" :class="{'has-error': formErrors.referencia.error, 'has-content' : hasTextOnInputMixin(form.referencia)}" placeholder="">
                <label class="effect-label">Referencia ejem, cerca a la plaza</label>
                <span class="focus-border"></span>
              </div>
            </div>
          </div>
          <div class="col-xs-12">
            <span class="text-danger" ole="alert">{{ formErrors.referencia.error_msg }}</span>
          </div>
        </form>
        <div id="mapModalAddress"></div>
      </div>
      <div class="modal-footer">
        <div class="menu-selection-container">
          <div class="extras-btns-holder">
            <div class="text-center">
              <a v-if="$route.name != 'zcourier'" v-on:click.prevent="createAddress" class="btn btn-danger btn-lg btn-block"><i class="fas fa-plus"></i> Guardar dirección</a>
              <a v-else v-on:click.prevent="createAddressZCurrier" class="btn btn-danger btn-lg btn-block"><i class="fas fa-plus"></i> Guardar dirección</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

</div>
</div>
</template>

<style scoped src="@/css/style-inputs.css"></style>
<style scoped src="@/css/style-modal-address.css"></style>
<style scoped>
#modal-address-size {
  width: 50% !important;
}
@media screen and (min-width: 100px){
  #modal-address-size {
    width: 93% !important;
  }
}
@media screen and (min-width: 550px){
  #modal-address-size {
    width: 80% !important;
  }
}
@media screen and (min-width: 650px){
  #modal-address-size {
    width: 500px !important;
  }
}
</style>

<script>
import googleMapsMixin from '@/mixins/local/GoogleMaps.js';
import { mapState } from 'vuex';

export default {
  name: 'ModalAddress',

  mixins: [googleMapsMixin],

  data() {
    return {
      cities: [],
      loading: false
    }
  },

  created() {
    this.idMap = 'mapModalAddress';
    if (this.isAuthenticatedMixin) {
      this.getUserAddress();
      let defaultAddress = this.getDefaultAddressMixin();
      setTimeout(() => {
        this.showLocationOnTheMap(parseFloat(defaultAddress.lat), parseFloat(defaultAddress.lng));
      }, 500);
    }
    this.getCities();
  },

  computed: mapState({
    user: state => state.user.user,
    isNullAddress: state => state.defaultAddress.isNullAddress,
    listModal: state => state.defaultAddress.listModal,
    // ZCurrier
    isAddressA: state => state.zcourier.isAddressA,
    addressB: state => state.zcourier.addressB,
  }),

  methods: {
    openModalLogin() {
      window.$("#modalAddress").modal("hide");
      window.$("#modalLogin").modal("show");
    },

    getCities() {
      this.getCitiesMixin().then(response => {
        this.cities = response.data;
      });
    },

    // CostShipping
    calculateCostoEnvioZCurrier(addressB) {
      this.getShippingCityMixin().then(response0 => {
        this.setRangosMixin(JSON.parse(response0.data[0].rangos));
        let distance = this.calcularDistanciaMixin(addressB);
        distance.then(response => {
          let cost = this.calculateCostoEnvioMixin(response);
          this.$store.commit('zcourier/setDistance', response);
          this.$store.commit('zcourier/setCostShipping', cost);
        });
      });
    },

    selectAddressZCurrier(address) {
      if (this.isAddressA) {
        this.$store.commit('zcourier/setAddressA', address);
        this.setEmpresaMixin(address);
        if (this.addressB) {
          console.log('en calcular addres')
          this.calculateCostoEnvioZCurrier(this.addressB);
        }
      }
      else {
        this.$store.commit('zcourier/setAddressB', address);
        this.calculateCostoEnvioZCurrier(address);
      }
      this.successToastrMixin('Dirección', 'Dirección seleccionada.');
      window.$("#modalAddress").modal("hide");
    },

    createAddressZCurrier() {
      this.validateForm();
      if (!this.formErrors.direccion.error && !this.formErrors.referencia.error && !this.formErrors.lat.error && !this.formErrors.lng.error) {
        if (this.$route.name == 'zcourier') {
          this.form.zcourier = 1;
        }
        this.createAddressMixin(this.form).then(response => {
          console.log(response);
          this.successToastrMixin('Dirección', 'Dirección creada con exito');
          if (this.isAddressA) {
            this.$store.commit('zcourier/setAddressA', response.data.direccion);
            this.setEmpresaMixin(response.data.direccion);
            if (this.addressB) {
              console.log('en calcular addres')
              this.calculateCostoEnvioZCurrier(this.addressB);
            }
          }
          else {
            this.$store.commit('zcourier/setAddressB', response.data.direccion);
            this.calculateCostoEnvioZCurrier(response.data.direccion);
          }
          this.clearFrom();
          window.$("#modalAddress").modal("hide");
        })
        .catch(error => {
          console.log(error);
          this.errorToastrMixin('Error', 'Ocurrio un error, vuelva a intentar más tarde');
        });
      }
    },

    updateStateAddress(codeAddress, state = 10) {
      if (state != 1) {
        this.loading = true;
        let newKeyAddresss = 0;
        this.updateStateAddressMixin(codeAddress).then(response => {
          let addressUser = response.data;
          this.$store.commit('defaultAddress/setAddressUser', addressUser);
          if (this.$route.name != 'checkout') {
            this.clearShoppingCartMixin();
            this.clearBussinesLocalMixin();
            this.$store.commit('shoppingCart/clearDistanceAndCostShipping');
          }
          for(let i = 0; i < addressUser.direcciones.length; i++){
            if (addressUser.direcciones[i].estado === 1){
              newKeyAddresss = addressUser.direcciones[i].coddireccion;
              this.setDefaultAddressMixin(addressUser.direcciones[i]);
              // CostShipping
              if (this.getEmpresaLocalMixin() && this.$route.name == 'checkout') {
                // Costos de envios
                this.getShippingCityMixin().then(response0 => {
                  this.setRangosMixin(JSON.parse(response0.data[0].rangos));
                  console.log('shippingCityMixin', response0.data[0]);
                  let distance = this.calcularDistanciaMixin(this.addressUser.direcciones[i]);
                  distance.then(response => {
                    let cost = this.calculateCostoEnvioMixin(response);
                    let businessLocal = this.getEmpresaLocalMixin();
                    if (businessLocal.codtiporeserva == 7 || businessLocal.codtiporeserva == 8 || this.$store.state.shoppingCart.deliverygratis == 1) {
                      this.$store.commit('shoppingCart/setCostShipping', 0.00);
                    }
                    else {
                      this.$store.commit('shoppingCart/setCostShipping', cost);
                    }
                    this.$store.commit('shoppingCart/setDistance', response);
                    i = this.addressUser.direcciones.length;
                  });
                });
              }
            }
          }
          this.successToastrMixin('Dirección', 'Designada como dirección predeterminada.');
          if (this.$route.name != 'checkout') {
            if (this.$route.name != 'Home') {
              this.$router.push({ name: 'Home' });
            }
          }
          this.$store.commit('defaultAddress/setKeyAddress', newKeyAddresss);
        }).catch(error => {
          console.log(error);
          this.errorToastrMixin('Error', 'Ocurrio un error, vuelva a intentar más tarde.');
        }).finally(() => {
          this.loading = false;
          //this.$router.go(this.$router.currentRoute)
          //this.$router.go();
        });
      }
      else {
        this.warningToastrMixin('Alerta', 'Esta direccion ya se encuentra como dirección predeterminada.');
      }
    },

    changeModal(n, city = null) {
      this.$store.commit('defaultAddress/setListModal', n);
      if (this.listModal === 2) {
        this.formErrors.direccion.error_msg = '';
        this.formErrors.direccion.error = false;
        this.formErrors.referencia.error_msg = '';
        this.formErrors.referencia.error = false;
        setTimeout(() => {
          this.showLocationOnTheMap(parseFloat(city.lat), parseFloat(city.lng));
        }, 500);
      }
    }

  }
}
</script>


