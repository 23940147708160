<template>
  <div class="modal fade  come-from-modal right" id="myModalPendingOrders" tabindex="-1" role="dialog" aria-labelledby="myModalPendingOrders">
    <div class="modal-dialog" role="document" id="modal-PendOrder-size">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" id="closeButtonModalPendingOrders" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="fas fa-times-circle"></i></span></button>
          <h4 class="modal-title" id="myModalPendingOrders">Pedidos pendientes</h4>
        </div>
        <div class="modal-body">
          <h5>Pedidos</h5>
          <div v-for="pendingOrder in pendingOrders" :key="pendingOrder.codpedido">
            <div class="pull-left">
              <i class="fas fa-check-circle icon-check"></i>
            </div>
            <div class="pull-right text-center" style="margin-left: 5px;">
              <span>{{ formatDate1(pendingOrder.pedido.fecha) }}</span><br>
              <span>{{ formatDate2(pendingOrder.pedido.fecha) }}</span><br>
              <button v-on:click.prevent="showPendingOrders(pendingOrder)" class="btn btn-success btn-sm">Ver</button>
            </div>
            <div class="margin-center">
              <span>Pedido #{{ pendingOrder.nroorden }}</span><br>
              <span>{{ pendingOrder.pedido.coddireccion.direccion }}</span>,
              <span> {{ pendingOrder.pedido.coddireccion.referencia }}</span><br>
              <span> {{ pendingOrder.nombre }}</span>
              <div>
                <p class="font-s-18">
                  <span class="label label-success mr-3">S/. {{ pendingOrder.pedido.subtotal }}</span>
                  <span class="label label-success mr-3">Pendiente</span>
                  <a v-on:click.prevent="goHelp" href="" class="btn btn-xs btn-link label label-success btn-label">Ayuda</a>
                </p>
              </div>
            </div>
            <hr>
          </div>
          <div v-if="pendingOrders.length < 1">
            <p>No tiene pedidos pendientes</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#modal-PendOrder-size {
  width: 50% !important;
}
.icon-check {
  font-size: 45px;
  padding-top: 18px;
  color: #00B74A;
}
.label-success {
  background-color: #00B74A !important;
}
.margin-center {
  margin-left: 60px;
  margin-right: 95px;
}
.mr-3 {
  margin-right: 7px;
}
.font-s-18 {
  font-size: 18px;
}
.btn-label {
  border: 0px !important;
}
@media screen and (min-width: 100px){
  #modal-PendOrder-size {
    width: 93% !important;
  }
}
@media screen and (min-width: 550px){
  #modal-PendOrder-size {
    width: 80% !important;
  }
}
@media screen and (min-width: 650px){
  #modal-PendOrder-size {
    width: 500px !important;
  }
}
</style>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
moment.suppressDeprecationWarnings = true;
moment.locale('es');

export default {
  name: 'ModalPendingOrders',

  created() {
    if (this.isAuthenticatedMixin) {
      this.getUserPendingOrders(this.getCurrentUserMixin.codusuario);
    }
  },

  computed: mapState({
    pendingOrders: state => state.order.ordersVuex,
  }),

  methods: {
    goHelp() {
      window.$('#ModalHelp').modal('show');
    },

    getUserPendingOrders(codusuario) {
      this.getUserPendingOrdersMixin(codusuario).then(response => {
        console.log('pendingOrders', response.data);
        this.$store.commit('order/setOrders', response.data);
      });
    },

    showPendingOrders(pendingOrder) {
      this.$store.commit('order/setLoading', true);
      console.log('pendingOrder.nombre', pendingOrder.nombre)
      if (pendingOrder.nombre == 'ZCourier') {
        this.$store.commit('order/setIsZCurrier', true);
      }
      else {
        this.$store.commit('order/setIsZCurrier', false);
      }
      if (this.$route.name == 'pendingOrders') {
        console.log('pendingOrders')
        this.$store.commit('order/setOrder', pendingOrder);
        this.$store.commit('order/setHistoricalOrder', '');
        window.$("#myModalPendingOrders").modal("hide");
      }
      else {
        this.$store.commit('order/setOrder', pendingOrder);
        this.$store.commit('order/setHistoricalOrder', '');
        this.$router.push({ name: 'pendingOrders' });
      }
      setTimeout(() => {
        this.$store.commit('order/setLoading', false);
      }, 350);
    },

    formatDate1(date) {
      return moment(date).format('DD MMM YYYY');
    },

    formatDate2(date) {
      return moment(date).format('h:mm A');
    }

  }
}
</script>