import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Index from '../views/Index.vue'
import categoria from '../views/Listing.vue'
import Carta from '../views/Carta.vue'
import Checkout from '../views/Checkout.vue'
import PendingOrders from '../views/PendingOrders.vue'
import HistoricalOrder from '../views/HistoricalOrder.vue'
import Error404 from '../views/Error404.vue'
import ZCourier from '../views/ZCourier.vue'
import SearchResult from '../views/SearchResult.vue'
import Repartidores from '../views/Forms/RepartidoresRegistro.vue'
import Restaurantes from '../views/Forms/RestaurantesRegistro.vue'
import Empleos from '../views/Forms/EmpleosRegistro.vue'
import TerminosYCondiciones from '../views/TerminosYCondiciones.vue'
import TopBusinessAll from '../views/TopBusinessAll.vue'


Vue.use(VueRouter)

// Si esta autentificado y tiene una direccion registrada te deja ingresar a la siguiente esa ruta
const authUser = (to, from, next) => {
  if (JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('defaultAddress'))) {
    return next();
  }
  else {
    return next('/home')
  }
}

// Tiene que tener una direccion para ser invitado
const guest = (to, from, next) => {
  if (JSON.parse(localStorage.getItem('defaultAddress'))) {
    return next();
  }
  else {
    return next('/')
  }
}

// Si esta autentificado te devuelve a al home
const isAuth = (to, from, next) => {
  if (JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('defaultAddress'))) {
    return next('/home');
  }
  else{
    return next();
  }
}

const routes = [
  {
    path: '/',
    name: 'Index',
    beforeEnter: isAuth,
    component: Index
  },
  {
    path: '/home',
    name: 'Home',
    beforeEnter: guest,
    component: Home
  },
  {
    path:'/categoria/:nombre/:id',
    name: 'categoria',
    beforeEnter: guest,
    component: categoria
  },
  {
    path:'/:idCat/:idCart/:empresa',
    name: 'carta',
    beforeEnter: guest,
    component: Carta
  },
  {
    path:'/checkout/restaurantes',
    name: 'checkout',
    beforeEnter: authUser,
    component: Checkout
  },
  {
    path:'/zcourier',
    name: 'zcourier',
    beforeEnter: authUser,
    component: ZCourier
  },
  {
    path:'/pedido-pendiente',
    name: 'pendingOrders',
    beforeEnter: authUser,
    component: PendingOrders,
    props: true
  },
  {
    path:'/mi-pedido',
    name: 'historicalOrder',
    beforeEnter: authUser,
    component: HistoricalOrder,
    props: true
  },
  {
    path:'/empresa/',
    name: 'searchBusiness',
    beforeEnter: guest,
    component: SearchResult,
  },
  {
    path:'/repartidores',
    name: 'repartidores',
    component: Repartidores,
  },
  {
    path:'/restaurantes',
    name: 'restaurantes',
    component: Restaurantes,
  },
  {
    path:'/empleos',
    name: 'empleos',
    component: Empleos,
  },
  {
    path:'/terminos-y-condiciones',
    name: 'terminosycondiciones',
    component: TerminosYCondiciones,
  },
  {
    path:'/top-empresas',
    name: 'topempresas',
    component: TopBusinessAll,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '*',
    component: Error404,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
