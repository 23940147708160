<template>
  <input placeholder="Buscar Producto"
    id="input__searcher"
    name="searcher"
    type="text"
    class="searcher_products"
    :value="search" 
    @input="makesearch($event)"
    autocomplete="off"
  />
</template>
<script>
import { debounce } from "debounce";
  export default {
    data(){
      return{
        search: '',
        resultProducts: [],
      }
    },
    props:{
      currentlyB:{
        type: Object,
        default: () => {}
      },
    },
    methods:{

      makesearch(event){
        this.sendEmitSearch()
        this.searchBusiness(event)
      },

      searchBusiness: debounce(function(event){
        console.log(this.currentlyB)
        let inputData = event.target.value
        console.log(inputData)
        if(inputData.length>=3){
          this.getProductsFromApi(inputData)
        }else{
          if(inputData.length!=0){
            this.sendEmitSearchfinish()
            this.warningToastrMixin('Buscador', 'Digite una palabra.');
          }
          if(inputData.length == 0){
            this.resultProducts = []
            this.sendEmitSearchfinish()
            this.sendEmitsresult()
          }
        }
      }, 1000),

      getProductsFromApi(string){
        this.getProductSearcherMixin(this.currentlyB, string).then( response => {
          this.resultProducts = response.data.productos
          console.log(response.data.productos)
          this.sendEmitSearchfinish()
          this.successToastrMixin('Buscador', `${this.resultProducts.length} ${this.resultProducts.length == 1 ? 'resultado' : 'resultados'} `)
          this.sendEmitsresult()
        })
      },

      sendEmitsresult(){
        this.$emit('results', this.resultProducts)
      },

      sendEmitSearch(){
        this.$emit('searchInit', true)
      },

      sendEmitSearchfinish(){
        this.$emit('searchInit', false)
      }
    }
  }
</script>
<style scoped>
.searcher_products {
  height: 45px;
  width: 500px;
  border-radius: 15px;
}
.searcher_products:focus {
  border: 1px solid #669df6;
}
</style>