<template>
  <div>
    <!-- <section-forms :title="title"></section-forms> -->
    <div class="form__container">
      <div class="form__container--div">
        <div class="formData">
          <h4 class="h4__title" for="">¡Unete a ZPedidos!</h4>
          <br />
          <div class="col-12 input-effect">
            <input
              v-model="form.encargado"
              class="input__field effect-16 input-style"
              :class="{
                'has-error': formError.encargado.error,
                'has-content': hasTextOnInputMixin(form.encargado),
              }"
              type="text"
              placeholder=""
              required
            />
            <label class="effect-label">Nombre encargado/dueño</label>
            <span class="focus-border"></span>
          </div>
          <div class="col-xs-12" v-if="formError.encargado.error">
            <span class="text-danger" ole="alert"
              >Ingrese su nombre Correctamente</span
            >
          </div>
          <div class="col-12 input-effect">
            <input
              v-model="form.restaurante"
              class="input__field effect-16 input-style"
              :class="{
                'has-error': formError.restaurante.error,
                'has-content': hasTextOnInputMixin(form.restaurante),
              }"
              type="text"
              placeholder=""
              required
            />
            <label class="effect-label">Nombre de restaurante</label>
            <span class="focus-border"></span>
          </div>
          <div class="col-xs-12" v-if="formError.restaurante.error">
            <span class="text-danger" ole="alert"
              >Ingrese datos del restaurante</span
            >
          </div>
          <div class="col-12 input-effect">
            <input
              v-model="form.direccion"
              class="input__field effect-16 input-style"
              :class="{
                'has-error': formError.direccion.error,
                'has-content': hasTextOnInputMixin(form.direccion),
              }"
              type="text"
              placeholder=""
              required
            />
            <label class="effect-label">Direccion del restaurante</label>
            <span class="focus-border"></span>
          </div>
          <div class="col-xs-12" v-if="formError.direccion.error">
            <span class="text-danger" ole="alert"
              >Ingrese la dirección del restaurante</span
            >
          </div>
          <div class="col-12 input-effect">
            <input
              v-model="form.correo"
              class="input__field effect-16 input-style"
              :class="{
                'has-error': formError.correo.error,
                'has-content': hasTextOnInputMixin(form.correo),
              }"
              type="email"
              placeholder=""
              required
            />
            <label class="effect-label">Correo Electronico</label>
            <span class="focus-border"></span>
          </div>
          <div class="col-xs-12" v-if="formError.correo.error">
            <span class="text-danger" ole="alert"
              >Ingrese correo del restaurante</span
            >
          </div>
          <div class="col-12 input-effect">
            <input
              v-model="form.telefono"
              class="input__field effect-16 input-style"
              :class="{
                'has-error': formError.telefono.error,
                'has-content': hasTextOnInputMixin(form.telefono),
              }"
              type="number"
              placeholder=""
              required
            />
            <label class="effect-label">Número de telefono </label>
            <span class="focus-border"></span>
          </div>
          <div class="col-xs-12" v-if="formError.telefono.error">
            <span class="text-danger" ole="alert"
              >Ingrese su telefono Correctamente</span
            >
          </div>
          <select
            v-model="form.codciudad"
            class="input__field effect-16"
            :class="{ select_cities: sendFormD }"
            name="Ubication"
            id="Ubication"
            @click="applyStyle()"
          >
            <option
              :value="city.codciudad"
              v-for="city in Cities"
              :key="city.codciudad"
              >{{ city.nombre }}</option
            >
          </select>
          <div class="conditions__container">
            <input
              v-model="form.terminosycondiciones"
              class="checkbox__conditions"
              type="checkbox"
              required
              :checked="isAcept"
            />
            <span class="span__text--terminos" @click="aceptContitions()"
              >He leído y acepto los
              <router-link :to="{ name: 'terminosycondiciones' }"
                ><span style="color: black;"
                  >Términos y condiciones</span
                ></router-link
              ></span
            ><br />
          </div>
          <div class="col-xs-12" v-if="formError.terminosycondiciones.error">
            <span class="text-danger" ole="alert"
              >Acepte los terminos y condiciones</span
            >
          </div>
          <div class="container__send">
            <button
              class="btn btn-success btn__send--data"
              type="submit"
              @click="sendData()"
            >
              Enviar
            </button>
          </div>
        </div>
      </div>
      <div class="container2">
        <img
          class="img__business"
          :src="require('@/assets/images/imgsForms/restaurant.jpg')"
          alt="people at restaurant"
        />
      </div>
      <div class="hiddendiv"></div>
    </div>
    <div class="container">
      <h2 class="h2__benefits">BENEFICIOS DE TRABAJAR CON ZPEDIDOS</h2>
      <div class="col-lg-3 col-md-6 col-sm-12">
        <img :src="require('@/assets/images/imgsForms/growth.png')" alt="grow img" />
        <p>
          Mejora tus ventas hasta en un 30%, disminuyendo tus costos fijos
          gracias a las ventas adicionales que les proporcionamos
        </p>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">
        <img :src="require('@/assets/images/imgsForms/cart.png')" alt="cart img" />
        <p>
          Haz que tu marca crezca gracias a que Zpedidos siempre esta presente
          en internet!, tambien tu empresa esta en la vista de nuestros miles de
          usuarios
        </p>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">
        <img :src="require('@/assets/images/imgsForms/zcurrier.png')" alt="zcurrier img" />
        <p>
          Entregamos tus pedidos de forma rápida y sencilla: en 30-40 minutos,
          todos los días del año
        </p>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">
        <img :src="require('@/assets/images/imgsForms/zcurrier1.png')" alt="anotherzcurrier img" />
        <p>
          Si no cuentas con repartidores, nuestro equipo del plan “Zcourrier” se
          encargará de trasportar tus alimentos de forma rápida y eficiente
        </p>
      </div>
    </div>
  </div>
</template>

<script>
/* import SectionForms from '@/components/SectionForms.vue' */
export default {
  data() {
    return {
      title: "Crece con nosotros",
      form: {
        encargado: "",
        restaurante: "",
        direccion: "",
        correo: "",
        telefono: "",
        terminosycondiciones: false,
        codciudad: "1",
      },
      isAcept: false,
      Cities: [],
      formError: {
        encargado: {
          error: false,
        },
        restaurante: {
          error: false,
        },
        direccion: {
          error: false,
        },
        correo: {
          error: false,
        },
        telefono: {
          error: false,
        },
        terminosycondiciones: {
          error: false,
        },
      },
      sendFormD: false,
    };
  },
  /* components: {SectionForms}, */

  created() {
    this.getCities();
  },
  mounted() {
    this.goMainSectionMixin();
  },

  methods: {
    applyStyle() {
      this.sendFormD = true;
    },
    sendData() {
      if (this.validateForm()) {
        this.$swal
          .fire({
            title: "¿Enviar Formulario?",
            text: "¡asegurate que los datos esten correctos!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, Enviar!",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.datasend();
            }
          });
      }
    },

    datasend() {
      this.sendFormBusinessMixin(this.form).then((res) => {
        console.log(res);
        if (res.status == 201) {
          this.$swal.fire(
            "Enviado!",
            "Su solicitud ha sido enviado.",
            "success"
          );
          this.resetFields();
          this.successToastrMixin(
            "Solicitud Exitosa",
            "pronto se comunicaran con usted"
          );
        } else {
          console.log(res);
          this.errorToastrMixin("fallo el envio", "intentelo de nuevo");
        }
      });
    },

    getCities() {
      this.getCitiesMixin().then((res) => {
        this.Cities = res.data;
      });
    },

    resetFields() {
      this.form = {
        encargado: "",
        restaurante: "",
        direccion: "",
        correo: "",
        telefono: "",
        codciudad: "1",
        terminosycondiciones: false,
      };
    },

    aceptContitions() {
      this.isAcept = !this.isAcept;
      this.form.terminosycondiciones = !this.form.terminosycondiciones;
    },

    validateForm() {
      this.form.encargado == ""
        ? (this.formError.encargado.error = true)
        : (this.formError.encargado.error = false);
      this.form.restaurante == ""
        ? (this.formError.restaurante.error = true)
        : (this.formError.restaurante.error = false);
      this.form.direccion == ""
        ? (this.formError.direccion.error = true)
        : (this.formError.direccion.error = false);
      this.form.correo == "" ||
      (!this.form.correo.includes("@") && !this.form.correo.includes("."))
        ? (this.formError.correo.error = true)
        : (this.formError.correo.error = false);
      this.form.telefono == ""
        ? (this.formError.telefono.error = true)
        : (this.formError.telefono.error = false);
      this.form.terminosycondiciones
        ? (this.formError.terminosycondiciones.error = false)
        : (this.formError.terminosycondiciones.error = true);
      if (
        !this.formError.encargado.error &&
        !this.formError.restaurante.error &&
        !this.formError.direccion.error &&
        !this.formError.correo.error &&
        !this.formError.telefono.error &&
        !this.formError.terminosycondiciones.error
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped src="@/css/style-inputs.css"></style>
<style scoped>
.form__container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 650px;
  z-index: 1;
}
.form__container--div {
  display: flex;
  width: 100%;
  background: white;
}
.formData {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.input__field {
  /* width: 450px; */
  width: 96%;
  height: 60px;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 2%;
}
.h4__title {
  color: #4c5267 !important;
  margin: 10px;
  text-align: center;
  font-weight: 500 !important;
}
.conditions__container {
  display: flex;
}
.checkbox__conditions {
  margin-left: 2%;
}
.span__text--terminos {
  color: #4c5267 !important;
  margin-left: 5px;
}
.container__send {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
.btn__send--data {
  width: 150px;
  justify-content: center;
  align-items: center;
}
.container2 {
  display: none;
}
.h2__benefits {
  color: #4c5267 !important;
  text-align: center;
}
.hiddendiv {
  position: absolute;
  right: 0;
  height: 10rem;
  width: 56rem;
  background: #ebedf3;
  z-index: -1;
}
.select_cities {
  border-bottom: 1px solid;
  border-bottom-color: #3399ff;
}
@media (min-width: 700px) {
  .form__container {
    position: relative;
    flex-direction: row;
    align-items: flex-start;
    margin-left: 0%;
    min-height: 800px;
    z-index: 1;
  }
  .form__container--div {
    width: 50%;
    margin-left: 2%;
    margin-top: 2%;
    border-radius: 10px;
  }
  .container2 {
    display: block;
    width: 50%;
    height: 60rem;
    margin-left: 1%;
    margin-top: 2%;
  }
  .img__business {
    height: 700px;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .form__container {
    position: relative;
    flex-direction: row;
    align-items: flex-start;
    margin-left: 10%;
    min-height: 800px;
    z-index: 1;
  }
  .form__container--div {
    width: 40%;
    margin-left: 2%;
    margin-top: 2%;
    border-radius: 10px;
  }
  .container2 {
    display: flex;
    width: 40%;
    height: 60rem;
    margin-left: 1%;
    margin-top: 2%;
  }
  .img__business {
    height: 700px;
    width: 100%;
  }
}
</style>
