<template>
  <div class="modal fade  come-from-modal right" id="myModalHistoricalOrders" tabindex="-1" role="dialog" aria-labelledby="myModalHistoricalOrders">
    <div class="modal-dialog" id="modal-HisOrd-size" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" id="closeButtonModalHistoricalOrders" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="fas fa-times-circle"></i></span></button>
          <h4 class="modal-title" id="myModalHistoricalOrders">Mis pedidos</h4>
        </div>
        <div class="modal-body">
          <h5>Pedidos</h5>
          <div v-for="historicalOrder in historicalOrders" :key="historicalOrder.codpedido">
            <div class="pull-left">
              <i class="fas fa-shopping-cart icon-sho-cart"></i>
            </div>
            <div class="pull-right text-center" style="margin-left: 5px;">
              <span>{{ formatDate1(historicalOrder.pedido.fecha) }}</span><br>
              <span>{{ formatDate2(historicalOrder.pedido.fecha) }}</span><br>
              <button v-on:click.prevent="showHistoricalOrders(historicalOrder)" class="btn btn-success btn-sm">Ver</button>
            </div>
            <div class="margin-center">
              <span>Pedido #{{ historicalOrder.nroorden }}</span><br>
              <span>{{ historicalOrder.pedido.coddireccion.direccion }}</span>,
              <span> {{ historicalOrder.pedido.coddireccion.referencia }}</span>
              <div v-if="historicalOrder.pedido.estado == 3 && historicalOrder.pedido.eliminado == 0">
                <p class="font-s-18">
                  <span class="label label-danger mr-3">Finalizado</span>
                  <a v-on:click.prevent="goQualifyOrder(historicalOrder.nroorden, historicalOrder.codpedido)" href="" class="btn btn-xs btn-danger label btn-label mr-3">Calificar</a>
                </p>
              </div>
              <div v-if="historicalOrder.pedido.estado == 2 && historicalOrder.pedido.eliminado == 0">
                <p class="font-s-18">
                  <span class="label label-success color-green mr-3">En camino</span>
                </p>
              </div>
              <div v-if="historicalOrder.pedido.estado == 1 && historicalOrder.pedido.eliminado == 0">
                <p class="font-s-18">
                  <span class="label label-primary mr-3">Preparando</span>
                </p>
              </div>
              <div v-if="historicalOrder.pedido.eliminado == 1">
                <p class="font-s-18">
                  <span class="label label-default mr-3">Cancelado</span>
                </p>
              </div>
            </div>
            <hr>
          </div>
          <div v-if="historicalOrders.length < 1">
            <p>No tiene pedidos</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#modal-HisOrd-size {
  width: 50% !important;
}
.icon-sho-cart {
  font-size: 45px;
  padding-top: 12px;
}
.margin-center {
  margin-left: 60px;
  margin-right: 95px;
}
.mr-3 {
  margin-right: 7px;
}
.font-s-18 {
  font-size: 18px;
}
.color-green {
  background-color: #43ae7b !important;
}
.btn-label {
  border: 0px !important;
}
@media screen and (min-width: 100px){
  #modal-HisOrd-size {
    width: 93% !important;
  }
}
@media screen and (min-width: 550px){
  #modal-HisOrd-size {
    width: 80% !important;
  }
}
@media screen and (min-width: 650px){
  #modal-HisOrd-size {
    width: 500px !important;
  }
}
</style>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
moment.suppressDeprecationWarnings = true;
moment.locale('es');

export default {
  name: 'ModalHistoricalOrders',

  created() {
    if (this.isAuthenticatedMixin) {
      this.getLast5Orders(this.getCurrentUserMixin.codusuario);
    }
  },

  computed: mapState({
    historicalOrders: state => state.order.historicalOrders,
  }),

  methods: {
    getLast5Orders(codusuario) {
      this.getLast5OrdersMixin(codusuario).then(response => {
        console.log('getLast5OrdersMixin', response.data)
        this.$store.commit('order/setHistoricalOrders', response.data);
      });
    },

    goQualifyOrder(orderN, codpedido) {
      let codAndToken = {
        'codpedido': codpedido,
        'token': this.$store.state.user.token
      };
      this.$store.commit('order/setOrderNumber', orderN);
      this.$store.commit('order/setCodpedido', codpedido);
      window.$('#ModalStarRating').modal('show');
      this.$store.dispatch('order/getQualifyOrder', codAndToken);
    },

    showHistoricalOrders(order) {
      this.$store.commit('order/setLoading', true);
      if (this.$route.name == 'historicalOrder') {
        this.$store.commit('order/setHistoricalOrder', order);
        this.$store.commit('order/setOrder', '');
        window.$("#myModalHistoricalOrders").modal("hide");
      }
      else {
        this.$store.commit('order/setHistoricalOrder', order);
        this.$store.commit('order/setOrder', '');
        this.$router.push({ name: 'historicalOrder' });
      }
      setTimeout(() => {
        this.$store.commit('order/setLoading', false);
      }, 350);
    },

    formatDate1(date) {
      return moment(date).format('DD MMM YYYY');
    },

    formatDate2(date) {
      return moment(date).format('h:mm A');
    },

  }
}
</script>