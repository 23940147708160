var codEmpresaLocal = 0;
var codEmpresaTemporary = 0;
var empresaLocal = null;

var businessLocalMixin = {
	created() {
		initEmpresaStorage();
	},
	// public methods
	methods: {
		setcodEmpresaTemporaryMixin(newCodEmpresaTemporary) {
			codEmpresaTemporary = newCodEmpresaTemporary;
		},

		verifyChangeEmpresaMixin() {
			console.log(
				"codEmpresaLocal:" +
				codEmpresaLocal +
				"codEmpresaTemporal:" +
				codEmpresaTemporary
				);
			let cartStorage = JSON.parse(localStorage.getItem('CART'));
			console.log("cartstorage",cartStorage)
			if(cartStorage && cartStorage.length<=0){
				console.log("return false",cartStorage)
				return false;
			}
			console.log('hey')
			if(localStorage.getItem('EMPRESA_STORAGE') && JSON.parse(localStorage.getItem('EMPRESA_STORAGE')).codempresa != codEmpresaTemporary){
				codEmpresaLocal = JSON.parse(localStorage.getItem('EMPRESA_STORAGE')).codempresa;
				empresaLocal = JSON.parse(localStorage.getItem('EMPRESA_STORAGE'));
				/* this.setToCartMixin(JSON.parse(localStorage.getItem('CART'))) */
				return true;
			}
			if (Number(codEmpresaLocal) === 0)
				return false;
			return Number(codEmpresaLocal) != Number(codEmpresaTemporary);
		},

		addEmpresaMixin(empresa) {
			setEmpresaStorage(empresa);
			setCodEmpresaLocal(empresa);
			this.setEmpresaLocalMixin(empresa);
		},

		setEmpresaLocalMixin(empresa) {
			empresaLocal = empresa;
		},

		getEmpresaLocalMixin() {
			return empresaLocal;
		},

		clearBussinesLocalMixin() {
			// duda
			localStorage.removeItem('EMPRESA_STORAGE');
			codEmpresaLocal = 0;
			empresaLocal = null;
		}

	}
}
// private function
function setEmpresaStorage(empresa) {
	localStorage.setItem('EMPRESA_STORAGE', JSON.stringify(empresa));
}

function setCodEmpresaLocal(empresa) {
	codEmpresaLocal = empresa.codempresa;
}

function initEmpresaStorage() {
	// duda
	let value = localStorage.getItem('EMPRESA_STORAGE');
	if (!value) return;
	const empresa = JSON.parse(value);
	codEmpresaLocal = empresa.codempresa;
	empresaLocal = empresa;
}

export default businessLocalMixin;