import axios from 'axios'
import configs from '@/utils/configs'

var apiBusinesstMixin = {

	computed: {

	},

	methods: {
		getCategoryBusinessMixin(id_cat, lat_long) {
			const url = configs.apiUrl + `/empresa/categoria/${id_cat}`;
			return axios.post(url, lat_long, {
				headers: { 'Authorization': 'bearer ' + this.$store.state.user.token }
			});
		},

		getoffersMixin(lat_long) {
			return axios.post(configs.apiUrl + `/ofertas`, lat_long, {
				headers: { 'Authorization': 'bearer ' + this.$store.state.user.token }
			});
		},

		getDataCartsBusinessMixin(id_business) {
			return axios({
				url: configs.apiUrl + `/carta/empresa/${id_business}`,
				method: 'GET',
				headers: { 'Authorization': 'bearer ' + this.$store.state.user.token },
			});
		},

		getCategoriesMixin(lat_long) {
			const apiURL = configs.apiUrl + '/categoria';
			return axios.post(apiURL, lat_long, {
				headers: { 'Authorization': 'bearer ' + this.$store.state.user.token }
			});
		},

		getTopBusinessMixin(direction) {
			const apiURL = configs.apiUrl + '/empresa/top/empresa';
			return axios.post(apiURL, direction, {
				headers: { 'Authorization': 'bearer ' + this.$store.state.user.token }
			});
		}
	}

};

export default apiBusinesstMixin;