const getDefaultState = () => {
	return {
		user: '',
		token: '',
		showNameUserAndDropdown: false,
		phoneLogin: '',
		isValidatedPhone: false,
	}
}

export default {
	namespaced: true,

	state: getDefaultState(),

	getters: {
		isAuthenticated(state){
			return !! state.user;
		},
	},

	mutations: {
		setCurrentUser(state, user) {
			if (user) {
				state.showNameUserAndDropdown = true;
			}
			else {
				state.showNameUserAndDropdown = false;
			}
			state.user = user;
		},

		setPhone(state, phoneLogin) {
			state.phoneLogin = phoneLogin;
		},

		setToken(state, token) {
			state.token = token;
		},

		setIsValidatedPhone(state, isValidatedPhone) {
			state.isValidatedPhone = isValidatedPhone;
		},

		resetState (state) {
			Object.assign(state, getDefaultState())
		}
	},

	actions: {
		resetUserState({ commit }) {
			commit('resetState');
		}
	}
}