<template>
  <div class="main-section">
    <div class="page-section nopadding cs-nomargin" style="margin-top: 0px;padding-top: 60px;padding-bottom: 10px;margin-bottom: 0px;">
      <div class="container pb-7">
        <div class="row">
          <div class="listing-main-search">
            <div class="block-search">
              <div class="search-icon search-icon-size">
                <img alt=""  :src="require('@/assets/images/buscador.png')" width="25px">
              </div>
              <div id="showIconClear" class="search-input">
                <input @input="filterBusiness" type="text" class=" " placeholder="Buscar">
              </div>
            </div>
          </div>
        </div>
      </div>

      <TopBusiness :showAllBussines="true"></TopBusiness>

    </div>
  </div>
</template>

<style scoped src="@/css/style-search.css"></style>
<style scoped>
.pb-7 {
  padding-bottom: 20px;
}
</style>

<script>
import TopBusiness from '@/components/TopBusiness';
import { mapState } from 'vuex';

export default {
  name: 'TopBusinessAll',

  components: { TopBusiness },

  computed: mapState({
    business: state => state.searchBusiness.business,
  }),

  methods: {
    filterBusiness(e) {
      console.log('value', e.target.value)
      let search = e.target.value;
      let newArrayFiltered = this.business.filter((el) =>
        el.nombre.toLowerCase().indexOf(search.toLowerCase()) > -1
        );
      this.$store.commit('searchBusiness/setBusinessFiltered', newArrayFiltered);
    }
  }

}
</script>

