const getDefaultState = () => {
	return {
		addressA: '',
		addressB: '',
		costShipping: 0.00,
		distance: 0.00,
		isAddressA: false
	}
}

export default {
	namespaced: true,

	state: getDefaultState(),

	mutations: {
		setAddressA(state, addressA) {
			state.addressA = addressA;
		},

		setAddressB(state, addressB) {
			state.addressB = addressB;
		},

		setDistance(state, distance) {
			state.distance = distance;
		},

		setCostShipping(state, costShipping) {
			state.costShipping = costShipping;
		},

		setIsAddressA(state, isAddressA) {
			state.isAddressA = isAddressA;
		},

		resetState (state) {
			Object.assign(state, getDefaultState())
		}
	},

	actions: {
		resetZCurrierState({ commit }) {
			commit('resetState');
		}
	}
}