<template>
  <div class="row">
    <HashLoader
    class="center-block"
    :loading="loading"
    :size="160"
    :color="'#0cc665'"
    ></HashLoader>
    <div v-show="!loading">
      <!-- letf -->
      <div class="section-sidebar col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="order-sort-results">
          <div class="container-progress">
            <div class="progress-container">
              <div class="progress" :class="percentBar()" id="progress"></div>
              <div class="circle" :class="isActive(1)">
                <i v-show="getOrder.pedido.estado >= 1 && getOrder.pedido.eliminado == 0" class="fas fa-check"></i>
              </div>
              <div class="circle" :class="isActive(2)">
                <i v-show="getOrder.pedido.estado >= 2 && getOrder.pedido.eliminado == 0" class="fas fa-check"></i>
              </div>
              <div class="circle" :class="isActive(3)">
                <i v-show="getOrder.pedido.estado >= 3 && getOrder.pedido.eliminado == 0" class="fas fa-check"></i>
              </div>
            </div>
            <div class="text-state-container">
              <div class="text-state">Preparando</div>
              <div class="text-state en-camino">En camino</div>
              <div class="text-state">Llego</div>
            </div>
            <!--<button class="btn" id="prev" disabled>Prev</button>
              <button class="btn" id="next">Next</button> -->
            </div>
          </div>

          <div class="order-sort-results">
            <div class="text-center">
              <i v-if="getOrder.pedido.eliminado == 1" class="far fa-times-circle icon-cross"></i>
              <i v-else class="far fa-check-circle icon-check"></i>
              <p style="font-size: 16px;">
                <strong>
                  Tu pedido de {{ getOrder.nombre }}
                  <span v-if="getOrder.pedido.eliminado == 1"> está cancelado</span>
                  <span v-else-if="getOrder.pedido.estado == 0 && getOrder.pedido.eliminado == 0"> ha sido aceptado</span>
                  <span v-else-if="getOrder.pedido.estado == 1 && getOrder.pedido.eliminado == 0"> está preparándose</span>
                  <span v-else-if="getOrder.pedido.estado == 2 && getOrder.pedido.eliminado == 0"> está en camino</span>
                  <span v-else-if="getOrder.pedido.estado == 3 && getOrder.pedido.eliminado == 0"> esta finalizado</span>
                </strong>
              </p>
            </div>
          </div>

          <div class="order-sort-results">
            <h5>Tu pedido</h5>
            <div id="mapResulOrder"></div>
            <br>
          </div>
          <div class="order-sort-results">
            <h5>Tus productos</h5>
            <div v-for="(product, j) in getOrder.detalle" :key="j" class="categories-order">
              <div class="pull-right">
                <span class="category-price">S/. {{ parseFloat(product.precio).toFixed(2) }}</span>
              </div>
              <div class="row">
                <div class="col-xs-2">
                  <img :src="`https://zcode10.com/uploads/${ product.codproducto.foto }`" class="img-list wp-post-image" alt="avatar-z" width="70px">
                </div>
                <div class="col-xs-8">
                  <span><strong>
                    {{ product.codproducto.titulo }}
                  </strong></span>
                  <br v-if="product.descripcion">
                  <span v-if="product.descripcion">{{ product.descripcion }}</span>
                  <br v-if="product.nota">
                  <span v-if="product.nota">Nota: {{ product.nota }}</span>
                  <br>
                  <span v-if="!isZCurrier">Cantidad {{ product.cantidad }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- letf End -->
        <!-- right -->
        <div class="sticky-sidebar col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div class="order-sort-results">
            <h5>Entrega estimada</h5>
            <p>
              {{ formatDate(getOrder.pedido.fecha, getOrder.tiempo) }}
            </p>
          </div>
          <div class="order-sort-results">
            <h5>Dirección de entrega</h5>
            <p>{{ getOrder.pedido.coddireccion.direccion }} - {{ getOrder.pedido.coddireccion.referencia }}</p>
          </div>
          <div v-if="!isZCurrier" class="order-sort-results">
            <h5>Nota</h5>
            <p>
              {{ getOrder.nota }}
            </p>
            <p v-if="!getOrder.nota">
              No dejó una nota adicional
            </p>
          </div>
          <div class="order-sort-results">
            <h5>Metodo de pago</h5>
            <p>
              <img v-if="getOrder.pedido.codmetodopago.codmetodopago != 1" :src="`https://zcode10.com/uploads/${ getOrder.pedido.codmetodopago.imagen }`" class="img-list wp-post-image" alt="avatar-z" width="50px">
              <img v-else="" :src="require('@/assets/images/dinero.jpg')" class="img-list wp-post-image" alt="avatar-z" width="30px">
              <span style="padding-left: 10px;">                  {{ getOrder.pedido.codmetodopago.nombre }}</span>
            </p>
            <div>
              <div class="categories-order pull-right" style="border: none;">
                <span class="category-price">S/. {{ parseFloat(getOrder.pedido.subtotal).toFixed(2) }}</span>
              </div>
              <div class="mr-7">
                <span>Tu cobro</span>
              </div>
            </div>
          </div>
          <div class="user-order-holder">
            <div class="user-order">
              <div class="price-area dev-menu-price-con">
                <h5>Resumen</h5>
                <ul>
                  <li>Costo de productos <span class="price">S/. <em class="dev-menu-subtotal">{{ costProducts.toFixed(2) }}</em></span></li>
                  <li>Costo de envio<span class="price">S/. <em class="dev-menu-vtax">{{ parseFloat(getOrder.pedido.envio).toFixed(2) }}</em></span></li>
                  <li>Cargo por lluvia<span class="price">S/. <em class="dev-menu-vtax">{{ getOrder.pedido.lluvia == 0 ? (0).toFixed(2) : (1).toFixed(2) }}</em></span></li>
                  <li>Monto de descuento<span class="price">S/. <em class="dev-menu-vtax">{{ parseFloat(getOrder.pedido.descuento).toFixed(2) }}</em></span></li>
                </ul>
                <p class="total-price">Total del pedido <span class="price">S/. <em class="dev-menu-grtotal"> {{ parseFloat(Number(getOrder.pedido.subtotal) + Number(getOrder.pedido.envio) - Number(getOrder.pedido.lluvia) - Number(getOrder.pedido.descuento)).toFixed(2) }}</em></span></p>
              </div>
            </div>
          </div>
        </div>
        <!-- right End -->
      </div>
    </div>
  </template>

  <style scoped src="@/css/style-progress-bar.css"></style>
  <style scoped>
  .mr-7 {
    margin-right: 80px !important;
  }
  .icon-check {
    font-size: 40px;
    color: #00B74A;
  }
  .icon-cross {
    font-size: 40px;
    color: #FE2526;
  }
  #mapResulOrder {
    width: 100%;
    height: 400px;
  }
  .percent-35{
    width: 35% !important;
  }
  .percent-70{
    width: 70% !important;
  }
  </style>

  <script>
  import { mapState, mapGetters } from 'vuex';
  import moment from 'moment';
  moment.suppressDeprecationWarnings = true;
  moment.locale('es');

  export default {
    name: 'ResultOrder',

    data() {
      return {
        icon: [],
      }
    },

    created() {
      if (this.isAuthenticatedMixin) {
        this.icon = [
        { url: require('@/assets/images/empresa.png'), size: { width: 40, height: 40 } },
        { url: require('@/assets/images/usuario.png'), size: { width: 40, height: 40 } },
        ];
        setTimeout(() => {
          this.googleMapsBusinessAndUser();
        }, 500);
      }
    },

    computed: mapState({
      isZCurrier: state => state.order.isZCurrier,
      loading: state => state.order.loading,

      ...mapGetters('order', ['getOrder']),

      costProducts() {
        /*let rainCharge = 0;
        if (this.getOrder.pedido.lluvia == 1) {
          rainCharge = 1;
        }*/
        return parseFloat(this.getOrder.pedido.subtotal);
      }
    }),

    methods: {
      formatDate(date, time) {
        let formatTime = moment(date).format('dddd');
        let addTime1 = moment(moment(date).add('00:'+time.substring(0, 2)+':00')).format('h:mm A');
        let addTime2 = moment(moment(date).add('00:'+time.substring(time.length - 2)+':00')).format('h:mm A');
        return formatTime + ' ' + addTime1 + ' - ' + addTime2;
      },

      googleMapsBusinessAndUser() {
        var limites = new window.google.maps.LatLngBounds();
        const addressUser = {
          lat: parseFloat(this.getOrder.pedido.coddireccion.lat),
          lng: parseFloat(this.getOrder.pedido.coddireccion.lng)
        };
      //const myLatlng = { lat: -13.65653, lng: -73.390013 };
      let map = new window.google.maps.Map(document.getElementById("mapResulOrder"), {
        center: addressUser,
        zoom: 20,
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      });
      const markerEmpresa = new window.google.maps.Marker({
        position: this.getOrder.direccionA?JSON.parse(this.getOrder.direccionA):JSON.parse(this.getOrder.direccionemp),
        map: map,
        icon: this.icon[0],
        title: "Empresa",
      });
      const markerUserDirection = new window.google.maps.Marker({
        position: addressUser,
        map: map,
        icon: this.icon[1],
        title: "Dirección usuario",
      });
      limites.extend(markerEmpresa.getPosition());
      limites.extend(markerUserDirection.getPosition());
      map.fitBounds(limites);
    },

    percentBar() {
      if (this.getOrder.pedido.estado == 2 && this.getOrder.pedido.eliminado == 0) {
        return 'percent-35';
      }
      else if (this.getOrder.pedido.estado == 3 && this.getOrder.pedido.eliminado == 0){
        return 'percent-70';
      }
    },

    isActive(state) {
      if (this.getOrder.pedido.estado >= state && this.getOrder.pedido.eliminado == 0) {
        return 'active';
      }
    }
  }
}
</script>