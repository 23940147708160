<template>
  <div class="main-section">
    <div
      class="page-section nopadding cs-nomargin"
      style="
        position: relative;
        margin-top: 0px;
        padding-top: 10px;
        padding-bottom: 0px;
        margin-bottom: -60px;
        background: #fe2526;
      "
    >
      <!-- buscador de productos -->
      <div class="container">
        <div class="row">
          <div
            class="section-fullwidth col-lg-12 col-md-12 col-sm-12 col-xs-12"
          >
            <br /><br />
            <Search></Search>
          </div>
        </div>
      </div>
      <!-- buscador de productos -->

      <!-- Container Start -->
      <div class="container">
        <div class="row">
          <div
            class="section-fullwidth col-lg-12 col-md-12 col-sm-12 col-xs-12"
          >
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <categoriesoptional v-show="cardOption" />
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <img
          data-v-2fc35b3b=""
          :src="require('@/assets/svg/curve.svg')"
          alt="Glovo"
          class="landing__jumbotron__curve"
          style=""
        />
      </div>
    </div>

    <div
      class="page-section nopadding cs-nomargin"
      style="padding-bottom: 0px; margin-bottom: 0px; background: #fff"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <TopBusiness />
          </div>
        </div>
      </div>
    </div>
    <ad></ad>
  </div>
</template>

<script>
// @ is an alias to /src
import Search from "@/components/Search.vue";

import categoriesoptional from "@/components/CategoriesOptional.vue";
import TopBusiness from "@/components/TopBusiness.vue";
import Ad from "@/components/Ad.vue";

export default {
  name: "Home",

  components: { Search, categoriesoptional, Ad, TopBusiness },

  data() {
    return {
      cardOption: true,
      categories: [],
      city: null,
      isloading: false,
    };
  },

  created() {
    this.closeModalAddDeliveryAddress();
    if (this.getDefaultAddressMixin()) {
      this.settingCity();
    }
  },
  mounted(){
    this.goMainSectionMixin();
  },

  methods: {
    generateSlug: function (slug) {
      slug = slug
        .replace(/[`~!@#$%^&*()_\-+=[\]{};:'"\\|/,.<>?\s]/g, " ")
        .toLowerCase();
      slug = slug.replace(/^\s+|\s+$/gm, "");
      slug = slug.replace(/\s+/g, "-");

      return slug;
    },

    closeModalAddDeliveryAddress() {
      window.$("#modalAddDeliveryAddress").modal("hide");
    },

    settingCity() {
      let addressDefault = this.getDefaultAddressMixin();
      let fromSetting = {
        lat: addressDefault.lat,
        lng: addressDefault.lng,
      };
      this.settingCityMixin(fromSetting).then((response) => {
        this.$store.commit(
          "shoppingCart/setDeliverygratis",
          response.data.configuracion.deliverygratis
        );
        console.log(
          "setting from home",
          this.$store.state.shoppingCart.deliverygratis
        );
      });
    },
  },
};
</script>