import { mapState } from 'vuex';

var googleMapsMixin = {
	data() {
		return {
			showAfterSearch: false,
			geocoders: null,
			idMap: '',
			// Form
			form: {
				'direccion': '',
				'lat': '',
				'lng': '',
				'referencia': ''
			},
			// Validation
			formErrors: {
				direccion: {
					error: false,
					error_msg: '',
				},
				lat: {
					error: false,
					error_msg: '',
				},
				lng: {
					error: false,
					error_msg: '',
				},
				referencia: {
					error: false,
					error_msg: '',
				},
			},
		}
	},

	created() {
		this.geocoders = new window.google.maps.Geocoder();
	},

	computed: mapState({
		addressUser: state => state.defaultAddress.addressUser,
	}),

	methods: {
		showLocationOnTheMap(latitude, longitude) {
			this.form.lat = latitude;
			this.form.lng = longitude;
			let map = new window.google.maps.Map(document.getElementById(this.idMap), {
				center: { lat: latitude, lng: longitude },
				zoom: 15,
				mapTypeId: window.google.maps.MapTypeId.ROADMAP,
			});

			// Posicionar maker
			let marker = new window.google.maps.Marker({
				position: new window.google.maps.LatLng(latitude, longitude),
				map: map,
			});

			// Mover maker
			map.addListener("click", (event) => {
				this.form.lat = event.latLng.lat();
				this.form.lng = event.latLng.lng();
				marker.setPosition(event.latLng);
				map.panTo(event.latLng);
				this.geocodeByLatLng(event.latLng);
				this.placeMarker(event.latLng);
			});
		},

		placeMarker(location) {
			this.geocoders.geocode(
				{ location: location },
				(results = window.google.maps.GeocoderResult,
					status= window.google.maps.GeocoderStatus) => {
					if (status === "OK") {
						if (results[0]) {
							this.form.direccion = results[0].formatted_address;
						}
					}
				}
				);
		},

		geocodeByLatLng(latlng) {
			const Request = { location: latlng, region: "pe" };
			this.geocoders.geocode(Request, (results) => {
				if (results) {
					this.address = results[0].formatted_address;
				}
			});
		},

		searchAddress(idAutocomplete){
			var options = {
				types: ["geocode"],
				componentRestrictions: {country: "pe"}
			};
			var autocomplete = new window.google.maps.places.Autocomplete(
				document.getElementById(idAutocomplete), options,
				{
					bounds: new window.google.maps.LatLngBounds(
						new window.google.maps.LatLng(-13.656497, 73.389926)
						),
				}
				);

			autocomplete.addListener("place_changed", () => {
				this.showAfterSearch = true;
				var place = autocomplete.getPlace();
				this.form.direccion = place.formatted_address;
				this.showLocationOnTheMap(
					place.geometry.location.lat(),
					place.geometry.location.lng()
					);
			});
		},

		getUserAddress(){
			this.getAddressUserMixin().then(response => {
				this.$store.commit('defaultAddress/setAddressUser', response.data);
				if (this.addressUser.direcciones.length < 1) {
					this.$store.commit('defaultAddress/changeIsNullAddress', true);
				}
			});
		},

		createAddress() {
			this.validateForm();
			if (!this.formErrors.direccion.error && !this.formErrors.referencia.error && !this.formErrors.lat.error && !this.formErrors.lng.error) {
				this.createAddressMixin(this.form).then(response => {
					console.log(response);
					this.$store.commit('defaultAddress/changeIsNullAddress', false);
					this.successToastrMixin('Dirección', 'Dirección creada con exito');
					this.clearFrom();
					this.getUserAddress();
					this.$store.commit('defaultAddress/setListModal', 1);
					this.setDefaultAddressMixin(response.data.direccion);
					// CostShipping
					if (this.getEmpresaLocalMixin() && this.$route.name == 'checkout') {
						// Costos de envios
						this.getShippingCityMixin().then(response0 => {
							this.setRangosMixin(JSON.parse(response0.data[0].rangos));
							let distance = this.calcularDistanciaMixin(response.data.direccion);
							distance.then(response => {
								let cost = this.calculateCostoEnvioMixin(response);
								this.$store.commit('shoppingCart/setDistance', response);
								let businessLocal = this.getEmpresaLocalMixin();
								if (businessLocal.codtiporeserva == 7 || businessLocal.codtiporeserva == 8 || this.$store.state.shoppingCart.deliverygratis == 1) {
									this.$store.commit('shoppingCart/setCostShipping', 0.00);
								}
								else {
									this.$store.commit('shoppingCart/setCostShipping', cost);
								}
							});
						});
					}
					window.$("#modalAddress").modal("hide");
					if (this.$route.name == 'Index') {
						this.$router.push({ name: 'Home' });
					}
					this.$store.commit('defaultAddress/setKeyAddress', response.data.direccion.coddireccion);
				})
				.catch(error => {
					console.log(error);
					if (error.response.status == 400) {
						this.formErrors.direccion.error_msg = error.response.data.message;
						this.errorToastrMixin('Error', error.response.data.message);
					}
					else {
						this.errorToastrMixin('Error', 'Ocurrio un error, vuelva a intentar más tarde');
					}
				});
			}
		},

		validateForm() {
			if (this.form.direccion === '') {
				this.formErrors.direccion.error_msg = 'Ingrese su dirección.';
				this.formErrors.direccion.error = true;
			}
			else {
				this.formErrors.direccion.error_msg = '';
				this.formErrors.direccion.error = false;
			}
			if (this.form.referencia === '') {
				this.formErrors.referencia.error_msg = 'Ingrese una referencia.';
				this.formErrors.referencia.error = true;
			}
			else {
				this.formErrors.referencia.error_msg = '';
				this.formErrors.referencia.error = false;
			}
			// lat and lng
			if (this.form.lat === '') {
				this.errorToastrMixin('Error', 'Busque correctamente su dirección.');
				this.formErrors.lat.error_msg = 'Busque correctamente su dirección.';
				this.formErrors.lat.error = true;
			}
			else {
				this.formErrors.lat.error_msg = '';
				this.formErrors.lat.error = false;
			}
			if (this.form.lng === '') {
				this.errorToastrMixin('Error', 'Busque correctamente su dirección.');
				this.formErrors.lng.error_msg = 'Busque correctamente su dirección.';
				this.formErrors.lng.error = true;
			}
			else {
				this.formErrors.lng.error_msg = '';
				this.formErrors.lng.error = false;
			}
		},

		clearInputAddress() {
			this.form.direccion = ''
		},

		clearFrom() {
			this.form.direccion = '';
			this.form.referencia = '';
		}
	}
};

export default googleMapsMixin;